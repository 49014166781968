import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Stack,
  Typography,
  InputAdornment,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AxiosAll from 'src/services/axiosAll';
import { useSelector } from 'react-redux';
import palette from 'src/theme/palette';
import { useNavigate } from 'react-router';

export default function KanbanBlockPopup({ open, onClose, task, column, reblock, callback = () => {} }) {
  const [query, setQuery] = useState('');
  const [selecttask, setSelectTask] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);
  const { userDetails, userList } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const navigate = useNavigate();

  const warningMainColor = palette.light.warning.main;
  const grey900Color = palette.light.grey[900];
  console.log(userDetails);

  const handleClose = () => {
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSave = async () => {
    if (!comment) {
      setCommentError(true);
      return;
    }
    try {
      const data = {
        dependentTaskId: selecttask?.id,
        comment: `Blocked Task :  ${comment}`,
      };
      const response = await AxiosAll('post', `/tasks/${task?.id}/block`, data, token);
      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage('Task has been successfully blocked!');
        setSnackbarOpen(true);
        console.log('Task has been successfully blocked!', response.data);
        // window.location.reload();
        if (reblock) {
          navigate(`/tasks/details/${task?.id}`);
          // navigate(`/kanban`);
        }
        callback();
      }
      onClose();
    } catch (error) {
      console.error('Error block task:', error);
    }
  };

  const handleUnblock = async () => {
    try {
      const response = await AxiosAll('post', `/tasks/${task?.id}/unblock`, {}, token);
      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage('Task has been successfully unblocked!');
        setSnackbarOpen(true);
        console.log('Task has been successfully unblocked!', response.data);
        // window.location.reload();
        if (reblock) {
          navigate(`/tasks/details/${task?.id}`);
          // navigate(`/kanban`);
        }
        callback();
      }
      onClose();
    } catch (error) {
      console.error('Error Unblock task:', error);
    }
  };

  const handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await AxiosAll('get', `/tasks/dropdown?query=${query}`, {}, token);
      console.log(response);
      setSearchResults(response.data.data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // useEffect(() => {
  //   handleSearch();
  // }, [query]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     if (query) {
  //       handleSearch();
  //     }
  //   }, 2000);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [query]);

  useEffect(() => {
    if (query === '') {
      setSearchResults([]);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  console.log(searchResults, selecttask);
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogContent>
          {task?.is_blocked ? (
            <>
              <Typography variant="h6" gutterBottom>
                Task
              </Typography>
              <Typography variant="body1" color="text.secondary" marginY={2}>
                Are you sure you want to unblock this task?
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleUnblock}>
                  Unblock
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                Task
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                label="Search"
                variant="outlined"
                value={query}
                onChange={handleSearchChange}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton onClick={handleSearch}>
                //         <SearchIcon />
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
              {(searchResults?.length > 0 || selecttask?.id) && (
                <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Search Results:
                  </Typography>
                  {selecttask?.id ? (
                    <Card variant="outlined" key={selecttask.id}>
                      <CardContent>
                        <Typography variant="h6" component="h2">
                          {selecttask.ticket}
                        </Typography>
                        <Typography color="textSecondary">{selecttask.title}</Typography>
                      </CardContent>
                    </Card>
                  ) : null}
                  {searchResults.map((result) => (
                    <Card
                      key={result.id}
                      sx={{ p: 2 }}
                      style={{
                        border: '1px solid #ccc',
                      }}
                    >
                      <Typography style={{ fontSize: '15px' }}>{result.title}</Typography>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mt: 1 }}
                      >
                        <Stack direction="row" spacing={2} alignItems="center">
                          {result.ticket ? (
                            <Typography
                              paragraph
                              variant="paragraph"
                              color="text.secondary"
                              style={{
                                fontSize: '12px',
                                backgroundColor: palette.light.background.neutral,
                                width: 'fit-content',
                                padding: '5px 10px',
                                marginBottom: '0',
                              }}
                            >
                              {result.ticket}
                            </Typography>
                          ) : null}
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: '12px',
                              width: 'fit-content',
                              padding: '5px 10px',
                              border: '1px solid #ccc',
                            }}
                            color="textSecondary"
                          >
                            {result.task_status.name}
                          </Typography>
                        </Stack>
                        <Button
                          style={{ backgroundColor: 'rgba(32, 101, 209, 0.08)', marginTop: '5px' }}
                          onClick={() => {
                            setSelectTask(result);
                            setSearchResults([]);
                          }}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Card>
                  ))}
                </div>
              )}

              <TextField
                fullWidth
                margin="normal"
                label="Add Comment"
                variant="outlined"
                value={comment}
                onChange={handleCommentChange}
                error={commentError}
                helperText={commentError ? 'This feild is required' : ''}
              />
              <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Block
                </Button>
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

KanbanBlockPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  column: PropTypes.object,
};
