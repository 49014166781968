/* eslint-disable no-debugger */
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
// hooks
// components
//
import { MentionsInput, Mention, t } from 'react-mentions';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Markdown from 'src/components/Markdown';
import { MembersAvatar } from 'src/components/labelsTag/membersAvatar';
import { PopUpAvatarAssigned } from 'src/components/labelsTag/popUpAvatarAssigned';
import { PopUpCommentLabel } from 'src/components/labelsTag/popUpCommentLabel';
import { PopUpDiffLabel } from 'src/components/labelsTag/popUpDiffLabel';
import { PopUpLabelTag } from 'src/components/labelsTag/popUpLabelTag';
import { PopUpNormalLabel } from 'src/components/labelsTag/popUpNormalLabel';
import AxiosAll from 'src/services/axiosAll';
import palette from 'src/theme/palette';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';
import KanbanBlockPopup from './kanban-blockDetail';
import KanbanWatchPopup from './kanban-watchDetail';

// @mui
// _mock
// eslint-disable-next-line import/no-unresolved
// ----------------------------------------------------------------------

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  width: 100,
  flexShrink: 0,
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightSemiBold,
}));

// ----------------------------------------------------------------------

export default function KanbanDetails({ task, openDetails, onCloseDetails, column }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const navigate = useNavigate();
  const urlImage = `https://internal-pms-dev.s3.ap-south-1.amazonaws.com/`;
  const dataUrl = task?.task_users[0]?.user?.profile_picture;
  const [addComment, setAddCoomment] = useState();
  const [taskComments, setTaskComments] = useState([]);
  const [LastUpdated, setLastUpdated] = useState('');
  const [showTaskCommnet, setShowComment] = useState(true);
  const [userData, setUserData] = useState([]);
  const [openBlockPopup, setOpenBlockPopup] = useState(false);
  const [openWatchPopup, setOpenWatchPopup] = useState(false);
  const [dependentTask, setDependentTask] = useState(null);

  const userUrl = `${urlImage}${dataUrl}`;
  const creatorUrl = `${urlImage}${dataUrl}`;
  const warningMainColor = palette.light.warning.main;
  const grey900Color = palette.light.grey[900];

  // const DescriptionBox = styled('div')(({ theme }) => ({
  //   padding: '4px 8px',
  //   backgroundColor: theme.palette.light.grey[200],
  //   borderRadius: 6,
  // }));

  const getTaskComments = async () => {
    const res = await AxiosAll('get', `/task-comments/${task?.id}`, {}, token);
    if (res?.status === 200) {
      setTaskComments(res?.data?.data);
      setLastUpdated(res?.data?.latestTaskActivity);
      setAddCoomment();
    } else {
      console.log('error', res);
      setShowComment(false);
    }
  };
  // console.log('task1', task);

  // const dateFormate = taskComments;

  // console.log(dateFormate);

  const getUser = async () => {
    const res = await AxiosAll('get', `/common/users`, {}, token);
    if (res?.status === 200) {
      const mentionableNames = res.data.map((person) => ({
        id: person.id,
        display: person.full_name,
      }));
      const newData = res?.data;
      setUserData(mentionableNames);
    }
  };

  const fetchDependentTask = async () => {
    if (task?.is_blocked && task?.dependent_task?.id) {
      const res = await AxiosAll('get', `/tasks/${task.dependent_task.id}`, {}, token);
      if (res?.status === 200) {
        setDependentTask(res.data);
      }
    }
  };

  useEffect(() => {
    if (openDetails) {
      getTaskComments();
      getUser();
    }
  }, [openDetails]);

  const AddTaskComments = async () => {
    const comment = {
      task_id: task?.id,
      comment: addComment,
    };
    const res = await AxiosAll('post', `/task-comments`, comment, token);
    if (res?.data?.statusCode === 201) {
      setAddCoomment('');
      getTaskComments();
    } else {
      console.log('error', res);
    }
  };
  const onChange = (e) => {
    setAddCoomment(e.target.value);
  };

  const handleModalClose = () => {
    setAddCoomment('');
    setShowComment(true);
    setTaskComments([]);
    onCloseDetails();
  };

  const renderSuggestion = (suggestion, search, highlightedDisplay, index, focused) => (
    <li
      key={suggestion.id}
      className={`mention ${focused ? 'focused' : ''}`}
      style={{ borderBottom: '1px solid #000' }}
    >
      <span>{highlightedDisplay}</span>
    </li>
  );
  // console.log('column', column);

  const handleBlockPopupOpen = () => {
    setOpenBlockPopup(true);
  };

  const handleBlockPopupClose = () => {
    setOpenBlockPopup(false);
  };

  const handleWatchOpen = () => {
    if (user?.role_id === 1 || user?.role_id === 3 || user?.roles[0]?.id === 4) {
      setOpenWatchPopup(true);
    } else {
      setOpenWatchPopup(false);
    }
  };
  const handleWatchingOpen = () => {
    if (user?.role_id === 1 || user?.role_id === 3 || user?.roles[0]?.id === 4) {
      setOpenWatchPopup(true);
    } else {
      setOpenWatchPopup(false);
    }
  };

  const handleWatchClose = () => {
    setOpenWatchPopup(false);
  };

  const watchTaskDetail = (
    <Iconify
      onClick={(e) => {
        e.stopPropagation();
        handleWatchOpen();
      }}
      sx={{
        // position: 'absolute',
        // top: 20,
        // right: 10,
        fontSize: 40,
        marginTop: 0,
        cursor: 'pointer',
        padding: 1,
        backgroundColor: 'light',
      }}
      icon="carbon:view-filled"
    />
  );

  const WatchButton = (
    <Iconify
      onClick={(e) => {
        e.stopPropagation();
        handleWatchingOpen();
      }}
      sx={{
        // position: 'absolute',
        // top: 20,
        // right: 10,
        fontSize: 40,
        marginTop: 0,
        cursor: 'pointer',
        padding: 1,
        backgroundColor: 'light',
      }}
      icon="el:eye-close"
    />
    // <Button
    //   onClick={(e) => {
    //     e.stopPropagation();
    //     handleWatchingOpen();
    //   }}
    // >
    //   Watching
    // </Button>
  );
  const isWatching = (task) => {
    if (user?.roles[0]?.id === 1 || user?.roles[0]?.id === 3 || user?.roles[0]?.id === 4) {
      return <div>{task?.watchers?.some((item) => item.id === user?.id) ? WatchButton : watchTaskDetail}</div>;
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={openDetails} onClose={handleModalClose}>
      <DialogContent>
        <CardContent sx={{ p: 0 }}>
          <Stack justifyContent="space-between" direction="row">
            <Stack spacing={2} direction="row" alignItems="center">
              {column?.id === '3' ? (
                <Button variant="outlined" onClick={handleBlockPopupOpen}>
                  {task?.is_blocked ? 'Unblock' : 'Block'}
                </Button>
              ) : null}
              {/* <Button onClick={handleWatchOpen}>Watch</Button> */}
              <Typography onClick={handleWatchOpen}>{column?.name !== 'Completed' && isWatching(task)}</Typography>
            </Stack>
            <Typography variant="h3" color="text.secondary">
              {user?.roles[0]?.id === 2 ? (
                ''
              ) : (
                <Iconify
                  onClick={() => navigate(`/tasks/edit/${task.id}`)}
                  sx={{
                    // position: 'absolute',
                    // top: 0,
                    // right: 50,
                    fontSize: 40,
                    marginTop: 0,
                    cursor: 'pointer',
                    padding: 1,
                  }}
                  icon="mi:edit"
                />
              )}
              {
                <Iconify
                  onClick={() => navigate(`/tasks/details/${task.id}`)}
                  sx={{
                    // position: 'absolute',
                    // top: 0,
                    // right: 10,
                    fontSize: 40,
                    marginTop: 0,
                    cursor: 'pointer',
                    padding: 1,
                  }}
                  icon="mdi:arrow-expand-all"
                />
              }
            </Typography>
          </Stack>

          <Typography variant="h3" color="text.secondary">
            {task?.title}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <div className="pop-up-flex-box">
                <PopUpLabelTag
                  background={task?.is_due ? '#FFA48D' : '#FFE16A'}
                  textColor={task?.is_due ? '#212B36' : '#212B36'}
                  labelTitle={`${moment(task?.due_date).format('Do MMM YYYY')} ${moment(task?.due_time, 'HH:mm').format(
                    'hh:mm A'
                  )}`}
                  topLabel="Due Date"
                />
                <PopUpLabelTag labelTitle={task?.project.title} topLabel="" />

                <MembersAvatar
                  fullNameCreator={task.task_users[0].assigned_by_user?.full_name}
                  fullNameAssigned={task.task_users[0].user?.full_name}
                  assingedUrl={userUrl}
                  // creatorUrl={creatorUrl}
                />
              </div>

              <div style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                {task?.labels?.map((label) => (
                  <Typography
                    key={label?.id}
                    sx={{
                      top: 10,
                      left: 10,
                      padding: '2px 8px',
                      fontSize: 14,
                      borderRadius: '6px',
                      ...{
                        // color: label?.color,
                        backgroundColor: label?.color,
                        opacity: 4,
                      },
                    }}
                  >
                    <span style={{ color: 'white', textTransform: 'lowercase' }}> {label?.name}</span>
                  </Typography>
                ))}
              </div>

              {task?.ticket && (
                <div style={{ marginTop: 10 }}>
                  <Typography
                    variant="paragraph"
                    color="text.secondary"
                    style={{
                      fontSize: '12px',
                      backgroundColor: palette.light.background.neutral,
                      width: 'fit-content',
                      padding: '5px 10px',
                    }}
                    className="mt-5px"
                  >
                    {task?.ticket}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} style={{ marginTop: '1.5rem' }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Description
              </Typography>
              <Box sx={{ bgcolor: 'background.neutral' }} style={{ padding: '4px 8px', borderRadius: 6 }}>
                <Markdown
                  children={
                    task?.description && task?.description !== 'null' ? task?.description : 'No Description ... '
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ marginTop: '1.5rem' }} columnGap={5}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <PopUpNormalLabel
                  paddX={8}
                  paddY={4}
                  labelRadius={6}
                  title={task?.task_priority?.name}
                  labelColor="green"
                  priority={task.priority}
                />
                <PopUpNormalLabel
                  paddX={8}
                  paddY={4}
                  labelRadius={6}
                  title={task.task_status.name}
                  labelColor="yellow"
                />
                <PopUpDiffLabel
                  paddX={8}
                  paddY={4}
                  labelRadius={6}
                  title="Est. time"
                  mainTitle={task?.formatted_planned_time}
                />
                {/* {  console.log("added changes",task)} */}
                {LastUpdated.current_status !== 2 && task?.primary_user_spend_time !== 0 ? (
                  <PopUpDiffLabel
                    paddX={8}
                    paddY={4}
                    labelRadius={6}
                    title={' user spend time'}
                    mainTitle={task?.formatted_primary_user_spend_time}
                  />
                ) : null}

                {LastUpdated.current_status !== 2 && task?.formatted_spend_time !== '00:00' ? (
                  <PopUpDiffLabel
                    paddX={8}
                    paddY={4}
                    labelRadius={6}
                    title={'Total spend time'}
                    mainTitle={task?.formatted_spend_time}
                  />
                ) : null}

                {LastUpdated.current_status !== 2 && task?.tester_spend_time !== 0 ? (
                  <PopUpDiffLabel
                    paddX={8}
                    paddY={4}
                    labelRadius={6}
                    title={' Tester spend time'}
                    mainTitle={task?.formatted_tester_spend_time}
                  />
                ) : null}

                {/* {LastUpdated.current_status === 5 && task?.tester_spend_time !== 0 ? (
                  <PopUpDiffLabel
                    paddX={8}
                    paddY={4}
                    labelRadius={6}
                    title={' Tester spend time'}
                    mainTitle={task?.tester_spend_time}
                  />
                ) : null} */}

                {LastUpdated.current_status === 2 ? (
                  <PopUpDiffLabel
                    paddX={8}
                    paddY={4}
                    labelRadius={6}
                    title={LastUpdated.current_status === 2 ? 'Started at' : 'Spend time'}
                    mainTitle={
                      LastUpdated.current_status === 2
                        ? moment(LastUpdated?.updated_at).calendar()
                        : task?.formatted_spend_time
                    }
                  />
                ) : null}
              </div>
            </Grid>
          </Grid>
          {task?.is_blocked && (
            <>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                is Blocked By
              </Typography>
              {task.dependent_task.map((dependentTask) => (
                <Card key={dependentTask.id} sx={{ p: 2, border: '1px solid rgb(204, 204, 204)' }}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '15px',
                    }}
                    children={dependentTask.title}
                  />
                  <Stack
                    direction="row"
                    spacing={2}
                    key={dependentTask.id}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: 1 }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      {dependentTask.ticket ? (
                        <Typography
                          variant="paragraph"
                          color="text.secondary"
                          children={dependentTask.ticket}
                          style={{
                            fontSize: '12px',
                            backgroundColor: palette.light.background.neutral,
                            // backgroundColor: dependentTask.ticket ? 'yellow' : 'null',
                            width: 'fit-content',
                            padding: '5px 10px',
                            marginBottom: '0',
                          }}
                        />
                      ) : null}
                      <Iconify
                        onClick={() => window.open(`/tasks/details/${dependentTask.id}`, '_blank')}
                        sx={{
                          fontSize: 40,
                          cursor: 'pointer',
                          padding: 1,
                        }}
                        icon="carbon:view-filled"
                      />
                    </Stack>

                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: '12px',
                        backgroundColor: palette.light.background.neutral,
                        width: 'fit-content',
                        padding: '5px 10px',
                        marginBottom: '0',
                      }}
                    >
                      {dependentTask?.task_status?.name}
                    </Typography>
                  </Stack>
                </Card>
              ))}
            </>
          )}
          {/* {task?.watchers?.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                is Watched By
              </Typography>
              {task?.watchers?.map((watching, index) => (
                <Card key={index} sx={{ p: 2, border: '1px solid rgb(204, 204, 204)' }}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '15px',
                    }}
                    children={`${watching?.first_name} ${watching?.last_name}`}
                  />
                </Card>
              ))}
            </>
          )} */}

          {task?.watchers?.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ my: 2 }}>
                is Watched By
              </Typography>
              <Card sx={{ p: 2, border: '1px solid rgb(204, 204, 204)', display: 'flex', flexWrap: 'wrap' }}>
                {task?.watchers?.map((watching, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{
                      fontSize: '15px',
                      marginRight: 2,
                      display: 'inline-block',
                      bgcolor: palette.light.background.neutral,
                      py: 1,
                      px: 2,
                      borderRadius: 1,
                    }}
                    children={`${watching?.first_name} ${watching?.last_name}`}
                  />
                ))}
              </Card>
            </>
          )}

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: '2rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">Activity</Typography>
              <Button variant="contained" onClick={() => setShowComment(!showTaskCommnet)}>
                {showTaskCommnet ? 'Hide details' : 'Show details'}
              </Button>
            </div>
          </div>
          <>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center', marginTop: '1rem' }}>
              <PopUpAvatarAssigned
                fullName={`${user?.first_name} ${user?.last_name}`}
                imageUrl={`${user?.profile_picture}`}
              />
              {/* <TextField fullWidth value={addComment} onChange={onChange} /> */}
              <MentionsInput
                className="mentions__suggestions__list"
                value={addComment}
                onChange={onChange}
                style={{
                  suggestions: {
                    maxHeight: '300px',
                    overflowY: 'auto',
                    borderRadius: 8,
                    boxShadow: '2px 3px 5px rgba(0,0,0,0.5)',
                    padding: 12,
                    borderBottom: 1,
                  },
                }}
                renderSuggestion={renderSuggestion}
              >
                <Mention
                  data={userData}
                  style={{ overflowY: 'scroll' }}
                  renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                    <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', padding: '5px 0' }}>
                      {highlightedDisplay}
                    </div>
                  )}
                />
              </MentionsInput>
              {addComment && (
                <Button variant="contained" onClick={AddTaskComments}>
                  Save
                </Button>
              )}
            </div>
            {/* {addComment && (
              <Button variant="contained" onClick={AddTaskComments}>
                Save
              </Button>
            )} */}
            <>
              {showTaskCommnet &&
                taskComments?.map((comment) => {
                  const dateTime = new Date(comment.created_at);

                  const formattedDate = new Intl.DateTimeFormat('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: '2-digit',
                  }).format(dateTime);

                  const formattedTime = new Intl.DateTimeFormat('en-GB', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                  }).format(dateTime);

                  return (
                    <div
                      key={comment?.id}
                      style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: '2rem' }}
                    >
                      {/* {console.log('date is null', comment?.start_date, 'this one', comment.created_at)} */}
                      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        <PopUpAvatarAssigned
                          fullName={comment.user?.full_name}
                          imageUrl={`https://internal-pms-dev.s3.ap-south-1.amazonaws.com/${comment?.user?.profile_picture}`}
                        />
                        <Typography variant="subtitle1" fontWeight="bold">
                          {comment.user?.full_name}
                        </Typography>
                        <Typography variant="subtitle2">{`${formattedDate} - ${formattedTime}`}</Typography>
                      </div>
                      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        <PopUpCommentLabel
                          color={palette.light.grey[200]}
                          paddX={8}
                          paddY={4}
                          labelRadius={6}
                          title={comment?.comment}
                          mentionName={comment?.user?.first_name}
                          spanColor={palette.light.grey[300]}
                          taskCommentId={comment?.id}
                          getTaskComments={getTaskComments}
                          loginUserId={comment?.user?.id}
                          auto_comment={comment?.auto_comment}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          </>
        </CardContent>
      </DialogContent>
      <KanbanBlockPopup open={openBlockPopup} onClose={handleBlockPopupClose} task={task} column={column} reblock />
      <KanbanWatchPopup open={openWatchPopup} onClose={handleWatchClose} task={task} redirect />
    </Dialog>
  );
}

KanbanDetails.propTypes = {
  onCloseDetails: PropTypes.func,
  openDetails: PropTypes.bool,
  task: PropTypes.object,
  column: PropTypes.object,
};
