import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import AvailabilitySidebar from 'src/components/AvailabilitySidebar';
// import InstallButton from 'src/components/installApp';
import AxiosAll from 'src/services/axiosAll';
import AddLogsForm from 'src/pages/logs/addLogs';
import { setCount } from 'src/redux/actions/reviewCount';

// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Iconify from '../../../components/Iconify';
import Logo from '../../../components/Logo';
import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
// import UseFullLinkPopover from './UsefulLink';
import BreakComponent from './BreakComponent';
import LogoutModal from './LogoutModal';
import JoinUserModal from './JoinUserModal';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [login, setLogin] = useState(false);
  const [LoginModal, setLoginModal] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [breakstart, setBreak] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rcount } = useSelector((state) => state.reviewReducer);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    getCounts();
  }, [breakstart, LoginModal, logOut]);

  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, userDetails?.token);
    const { data } = response;
    dispatch(setCount(data));
  };
  console.log('LoginModal', LoginModal);
  useEffect(() => {
    if (rcount?.availability_status?.availability_status_id === 1) {
      setLogin(false);
      setLoginModal(true);
    } else if (rcount?.availability_status?.availability_status_id === 2) {
      setLogin(true);
      setLoginModal(false);
    } else if (rcount?.availability_status?.availability_status_id === 3) {
      setLogin(false);
      setLoginModal(true);
    } else if (rcount?.availability_status?.availability_status_id === 4) {
      setBreak(true);
      setLoginModal(false);
    } else if (rcount?.availability_status?.availability_status_id === 6) {
      setLogin(true);
      setLoginModal(false);
    } else if (rcount?.availability_status) {
      setLogin(false);
      setLoginModal(true);
    }
  }, [rcount?.availability_status]);

  const handleAddOpen = () => {
    setOpen(true);
  };

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {userDetails?.user?.role_id !== 1 ? (
            <>
              {login ? (
                <>
                  {' '}
                  {!breakstart && (
                    <Button
                      variant="contained"
                      sx={{ ml: '0.5rem' }}
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={handleAddOpen}
                      startIcon={<Iconify icon={'eva:plus-fill'} />}
                    >
                      Add Logs
                    </Button>
                  )}{' '}
                  <Button
                    variant="contained"
                    onClick={() => setBreak(true)}
                    style={{ whiteSpace: 'nowrap' }}
                    sx={{
                      backgroundColor: breakstart ? 'green' : 'red',
                      '&:hover': {
                        backgroundColor: breakstart ? 'red' : 'red',
                      },
                    }}
                  >
                    Break
                  </Button>{' '}
                  <Button
                    variant="contained"
                    onClick={() => setLogOut(true)}
                    style={{ whiteSpace: 'nowrap' }}
                    sx={{
                      backgroundColor: logOut ? 'green' : '#FFA48D',
                      color: '#212B36',
                      fontWeight: '400',
                      '&:hover': {
                        backgroundColor: logOut ? '#FFA48D' : '#FFA48D',
                      },
                    }}
                  >
                    Log Off
                  </Button>{' '}
                </>
              ) : (
                ''
                // <Button variant="contained" onClick={() => Login(2)}>
                //  Join
                // </Button> ""
              )}
            </>
          ) : null}
          {/* <UseFullLinkPopover />
          <InstallButton /> */}
          <AvailabilitySidebar />
          {/* <InstallButton /> */}
          {LoginModal && (
            <JoinUserModal login={login} setLogin={setLogin} LoginModal={LoginModal} setLoginModal={setLoginModal} />
          )}
          <AccountPopover />
          {logOut && <LogoutModal setLogin={setLogin} setLogOut={setLogOut} logOut={logOut} />}
          {breakstart && <BreakComponent breakstart={breakstart} setBreak={setBreak} />}
          <AddLogsForm open={open} onClose={() => setOpen(false)} onNextStep={() => setOpen(false)} />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
