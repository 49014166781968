import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import { useLocation } from 'react-router-dom';
// @mui
import { Button, Card, Container, Divider, Table, TableBody, ToggleButton, ToggleButtonGroup } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-duplicates

// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import FilterModal from 'src/components/filterModal/indx';
import { setCount } from 'src/redux/actions/reviewCount';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table';
// sections
import { LogTableRow } from '../../sections/@dashboard/logs/list';
import TimeSheet from '../timeSheet';
import EditLogsForm from './EditLog';
import AddLogsForm from './addLogs';

const TABLE_HEAD = [
  { id: 'id', label: 'Developer', align: 'left' },
  { id: 'type', label: 'Log Type', align: 'left' },
  { id: 'task_title', label: 'Title', align: 'left' },
  { id: 'work', label: 'Work', align: 'left' },
  { id: 'time', label: 'Time', align: 'center' },
  { id: 'start_time', label: 'Start', align: 'left' },
  { id: 'end_time', label: 'End', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'action', label: 'Edit', align: 'left' },
];

// ----------------------------------------------------------------------

export default function Logs() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { userDetails ,userList , projectList   } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [isLogShow, setIsLogShow] = useState('list');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const taskID = urlParams.get('task_id') || '';
  const date = urlParams.get('date');
  const type = urlParams.get('type');

  const location = useLocation();
  const { state } = location;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [filterId, setFilterId] = useState('');
  const [total, setTotal] = useState(0);
  const [editTask, setEditTask] = useState();
  const [dateFilter, setDate] = useState(date || moment(new Date()).format('YYYY-MM-DD'));
  const [filterWork, setFilterWork] = useState('all');
  const [userID, setUserID] = useState('');
  const [query, setQuery] = useState();
  const [project, setProject] = useState();
  const [users, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const { setColor } = useSettings();
  const [totalTime, setTotalTime] = useState('');
  const [totalMin, setTotalMin] = useState(0);
  const dispatch = useDispatch();
  const loglimit = 100;

  const handleFilterName = (filterId) => {
    setFilterId(filterId);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterWork(event.target.value);
  };

  const getLogesLead = async () => {
    setLoading(true);
    setTableData([]);
    const response = await AxiosAll(
      'get',
      `/task-time-logs?query=${query || ''}&page=${
        page + 1
      }&project_id=${projectId}&type=team_log&user_id=${userID}&type=${type}&date=${
        (dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''
      }&limit=${loglimit}`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      // do not add duplicate data
      setLoading(false);

      // setAllTask(response?.data?.data?.data);
      setTableData(response?.data?.data?.data);
      getCounts();
      setTotal(response?.data?.data?.total);
      setTotalTime(response?.data?.data?.total_time);
    } else {
      setTotal();
      setLoading(false);
    }
  };
  const getLoges = async () => {
    setLoading(true);
    setTableData([]);
    const response = await AxiosAll(
      'get',
      `/task-time-logs?query=${query || ''}&page=${
        page + 1
      }&project_id=${projectId}&user_id=${userID}&type=${type}&date=${
        (dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''
      }&limit=${loglimit}`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      // do not add duplicate data
      setLoading(false);

      // setAllTask(response?.data?.data?.data);
      setTableData(response?.data?.data?.data);
      getCounts();
      setTotal(response?.data?.data?.total);
    } else {
      setTotal();
      setLoading(false);
    }
  };
  const getCounts = async () => {
    // eslint-disable-next-line no-template-curly-in-string
    const response = await AxiosAll('get', '/counts', {}, token);
    const { data } = response;

    // if (data?.statusCode === 200) {
    // console.log("data", data)
    // dispatch(setTotal(response?.data?.data?.total))
    dispatch(setCount(data));
    // }
  };

  const handleEditRow = (data) => {
    setEditOpen(true);
    setEditTask(data);
  };

  const denseHeight = dense ? 52 : 72;

  // const getUser = async () => {
  //   setUser([]);
  //   const response = await AxiosAll('get', '/common/users', {}, token);
  //   if (response?.status === 200) {
  //     setUser(response?.data);
  //   }
  // };

  // const getProject = async () => {
  //   setProject([]);
  //   const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
  //   if (response?.status === 200) {
  //     setProject(response?.data);
  //   }
  // };

  const handleAddOpen = () => {
    setOpen(true);
    setEditTask('');
  };

  // useEffect(() => {
  //   getUser();
  // }, []);

  // useEffect(() => {
  //   getProject();
  // }, [userID]);

  const isNotFound =
    (!tableData?.length && !!filterId) ||
    (!tableData?.length && !!filterWork) ||
    (!tableData?.length && !!filterStatus);

  useEffect(() => {
    if (userDetails?.user?.roles[0]?.id === 2) {
      getLoges(dateFilter);
    } else {
      getLogesLead(dateFilter);
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskID, date, open, editOpen, query, projectId, dateFilter, setDate, userID, page]);

  useEffect(() => {
    if (dateFilter) {
      const key = 'date';
      const value = dateFilter;
      const val = 'time';
      const total = tableData.reduce((accumulator, currentValue) => {
        if (currentValue[key] === value) {
          return accumulator + currentValue[val];
        }
        return accumulator;
      }, 0);
      setTotalMin(total);
    } else {
      let startdate = moment();
      startdate = startdate.format('YYYY-MM-DD');

      const key = 'date';
      const value = startdate;
      const val = 'time';
      const total = tableData.reduce((accumulator, currentValue) => {
        if (currentValue[key] === value) {
          return accumulator + currentValue[val];
        }
        return accumulator;
      }, 0);
      setTotalMin(total);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, dateFilter]);

  function padToTwoDigits(num) {
    return num.toString().padStart(2, '0');
  }
  const handleToggle = (e, val) => {
    if (val === 'sheet') {
      setIsLogShow('sheet');
    } else if (val === 'list') {
      setIsLogShow('list');
    }
  };

  return (
    <Page title="Logs">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isLogShow === 'list' ? 'Logs List' : 'Time Sheet'}
          links={[{ name: 'Tasks', href: '/kanban' }, { name: isLogShow === 'list' ? 'Logs' : 'Time Sheet' }]}
          action={
            <>
              <ToggleButtonGroup
                color="primary"
                value={isLogShow}
                exclusive
                onChange={handleToggle}
                aria-label="Platform"
              >
                <ToggleButton value="list">List View</ToggleButton>
                <ToggleButton value="sheet">Calendar View</ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="contained"
                sx={{ ml: '0.5rem' }}
                onClick={handleAddOpen}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Add Logs
              </Button>
            </>
          }
        />
        {isLogShow === 'list' ? (
          <>
            <div style={{ marginBottom: '1.5rem' }}>
              {userDetails?.user?.role_id === 1 ? (
                <>
                  <span className="time_text">Spend time :</span>
                  <span className="time_text">{totalTime}</span>
                </>
              ) : (
                <>
                  <span className="time_text">
                    {dateFilter === moment(new Date()).format('YYYY-MM-DD') ? "Today's spend time :" : 'Spend time :'}
                  </span>
                  <span className="time_text">{`${padToTwoDigits(Math.floor(totalMin / 60))}:${padToTwoDigits(
                    totalMin % 60
                  )}`}</span>
                </>
              )}
              <span>
                <FilterModal
                  setProjectID={setProjectId}
                  setDate={setDate}
                  dateFilter={dateFilter}
                  setUserID={setUserID}
                  projectId={projectId}
                  userID={userID}
                  project={projectList}
                  users={userList}
                  setPage={setPage}
                />
              </span>
            </div>
            <Card className="main_container">
              <Divider />
              <AddLogsForm open={open} onClose={() => setOpen(false)} onNextStep={() => setOpen(false)} />

              <EditLogsForm
                open={editOpen}
                editTask={editTask}
                onClose={() => setEditOpen(false)}
                onNextStep={() => setEditOpen(false)}
              />

              {/* <LogTableToolbar
            filterId={filterId}
            filterWork={filterWork}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            search="logs"
            setQuery={setQuery}
          /> */}

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    hideCheckbox
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected?.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                  />
                  {loading ? (
                    <>
                      <TableSkeleton />
                      <TableSkeleton />
                      <TableSkeleton />
                      <TableSkeleton />
                      <TableSkeleton />
                    </>
                  ) : (
                    <TableBody>
                      {tableData?.map((row) => (
                        <LogTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                        />
                      ))}

                      {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  )}
                </Table>
              </Scrollbar>

              {/* <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[20]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
              <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label="Dense"
                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
              />
            </Box> */}
            </Card>
          </>
        ) : (
          <>
            <TimeSheet openNew={open} setOpenNew={setOpen} />
          </>
        )}
      </Container>
    </Page>
  );
}
