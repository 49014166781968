import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AxiosAll from 'src/services/axiosAll';

const GoogleLoginButton = ({ checkToken }) => {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const { enqueueSnackbar } = useSnackbar();
    const [search, setSearch] = useSearchParams();

    const googleToken = async (user) => {
        try {
            const Data = {
                code: user,
            }
            const response = await AxiosAll('post', '/google-token', Data, token);
            const { data } = response;

            if (data.statusCode === 200) {
                checkToken(true);
                enqueueSnackbar(data?.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data?.message, { variant: 'error' })
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        search?.get('code') && googleToken(search?.get('code'))
    }, [])

    // const handleGoogleLogin = () => {
    //     const provider = new GoogleAuthProvider();
    //     provider.addScope('https://www.googleapis.com/auth/calendar');
    //     provider.setCustomParameters({
    //         state: 'state_parameter_passthrough_value',
    //         include_granted_scopes: true
    //     });
    //     // setPersistence(auth, browserLocalPersistence)
    //     signInWithPopup(auth, provider)
    //         .then((result) => {
    //             // Handle successful login
    //             const user = result.user;
    //             googleToken(result?._tokenResponse);
    //             console.log('Logged in user:', user);
    //         })
    //         .catch((error) => {
    //             // Handle errors
    //             console.error('Google login error:', error);
    //         });
    // };
    return (
        <Button Button variant="contained" sx={{ ml: '0.5rem' }} onClick={() => window.open('https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar&access_type=offline&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=http://localhost:4000/leaves&response_type=code&client_id=269166421078-nn60o0tu013s3p9r95nut8hldili7947.apps.googleusercontent.com', "_self")}>Sign in for meeting</Button>
    );
};
export default GoogleLoginButton;