import moment from 'moment/moment';
import { imagesAll } from 'src/assets/Images_index';

const DownloadedCertificate = ({ selectedDate, employeeName, currentYear }) => (
  <div
    style={{
      width: 1083,
      height: 794,
      position: 'relative',
      display: 'flex',
      zIndex: 0,
      
    }}
  >
    <img
      src={imagesAll.certificateFrame}
      alt=""
      style={{
        width: 1083,
        height: 'auto',
        position: 'absolute',
        zIndex: -1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <img alt="" src={imagesAll.rsvrSquareLogo} style={{ width: 106, height: 'auto', marginBottom: '45px' }} />
      <h3
        style={{
          textTransform: 'uppercase',
          fontSize: 55,
          fontFamily: 'Abhaya Libre',
          color: '#494543',
          lineHeight: '1.1',
          margin: 0,
        }}
      >
        Certificate
      </h3>

      <div style={{ display: 'flex', justifyContent: 'space-between', width: 450 }}>
        <img src={imagesAll.certificateLine} alt="" style={{ width: 62 }} />
        <img src={imagesAll.certificateLine} alt="" style={{ width: 62 }} />
      </div>

      <h3
        style={{
          textTransform: 'uppercase',
          fontSize: 15,
          fontFamily: 'Abhaya Libre',
          color: '#494543',
          lineHeight: '1.1',
          letterSpacing: 2,
          marginBottom: 30,
        }}
      >
        Employee of the {selectedDate} Certificate
      </h3>

      <h3
        style={{
          fontSize: 15,
          letterSpacing: 2,
          marginBottom: 30,
          textTransform: 'uppercase',
          fontFamily: 'Abhaya Libre',
          color: '#494543',
          lineHeight: '1.1',
        }}
      >
        This is to certify that
      </h3>

      <div style={{ position: 'relative', marginTop: 40, width: 635 }}>
        <h3
          style={{
            color: '#494543',
            fontFamily: 'Allura',
            fontSize: 70,
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            textAlign: 'center',
            margin: 0,
          }}
        >
          {employeeName}
        </h3>
        <img src={imagesAll.certificateSignatureLine} alt="" style={{ marginBottom: 15 }} />
      </div>

      {selectedDate === 'month' ? (
        <h3
          style={{
            fontFamily: 'Abhaya Libre',
            color: '#494543',
            fontSize: 15,
            letterSpacing: 1,
            marginBottom: 30,
            width: 635,
            textAlign: 'center',
          }}
        >
          In recognition of exemplary dedication and outstanding performance,{' '}
          <span
            style={{
              color: '#08AAB6',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {employeeName}
          </span>{' '}
          has been honored as the Employee of the Month. Throughout{' '}
          {selectedDate === 'month' ? (
            <span
              style={{
                color: '#08AAB6',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              {moment(new Date()).format('DD-MM-YYYY')}
            </span>
          ) : (
            <span
              style={{
                color: '#08AAB6',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              {currentYear}
            </span>
          )}
          ,{' '}
          <span
            style={{
              color: '#08AAB6',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {employeeName}
          </span>{' '}
          showcased exceptional commitment towards his/her work and made a significant contribution to the success of
          RSVR. This certificate is a small token of our appreciation for your dedication and commitment to excellence.
          RSVR commends{' '}
          <span
            style={{
              color: '#08AAB6',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {employeeName}
          </span>{' '}
          for his/her remarkable dedication and valuable contributions.
        </h3>
      ) : (
        <h3
          style={{
            fontFamily: 'Abhaya Libre',
            color: '#494543',
            fontSize: 15,
            letterSpacing: 1,
            marginBottom: 30,
            width: 635,
            textAlign: 'center',
          }}
        >
          In recognition of exemplary dedication and outstanding performance,{' '}
          <span
            style={{
              color: '#08AAB6',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {employeeName}
          </span>{' '}
          has been honored as the Employee of the Year. Throughout{' '}
          {selectedDate === 'month' ? (
            <span
              style={{
                color: '#08AAB6',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              {moment(new Date()).format('DD-MM-YYYY')}
            </span>
          ) : (
            <span
              style={{
                color: '#08AAB6',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              {currentYear}
            </span>
          )}
          ,{' '}
          <span
            style={{
              color: '#08AAB6',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {employeeName}
          </span>{' '}
          showcased exceptional commitment towards his/her work and made a significant contribution to the success of
          RSVR. This certificate is a small token of our appreciation for your dedication and commitment to excellence.
          RSVR commends
          <span
            style={{
              color: '#08AAB6',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            {employeeName}
          </span>{' '}
          for his/her remarkable dedication and valuable contributions.
        </h3>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: 571,
          alignItems: 'end',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: 175, textAlign: 'center' }}>
          <h3 style={{ fontSize: 22, letterSpacing: 2, color: '#494543', fontFamily: 'Abhaya Libre' }}>
            {moment(new Date()).format('DD-MM-YYYY')}
          </h3>
          <h3
            style={{
              textTransform: 'uppercase',
              fontSize: 22,
              letterSpacing: 2,
              color: '#494543',
              fontFamily: 'Abhaya Libre',
              margin: 0,
            }}
          >
            date
          </h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: 175, textAlign: 'center' }}>
          <img src={imagesAll.signatureRSVR} alt="" style={{ width: 175, height: 'auto' }} />
          <h3
            style={{
              textTransform: 'uppercase',
              fontSize: 22,
              letterSpacing: 2,
              color: '#494543',
              fontFamily: 'Abhaya Libre',
              margin: 0,
            }}
          >
            signature
          </h3>
        </div>
      </div>
    </div>
  </div>
);

export default DownloadedCertificate;
