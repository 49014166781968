import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
  TableSkeleton
} from '../../components/table';
// sections
import TaskTableRow from '../../sections/@dashboard/report/TaskTableRow';
import TaskTableToolbar from '../../sections/@dashboard/report/TaskTableToolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'task', label: 'Task', align: 'left' },
  { id: 'predecessor', label: 'Predecessor Task', align: 'center' },
  { id: 'successor', label: 'Successor Task', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'dueDate', label: 'Due Date', align: 'center' },
  { id: 'allocated', label: 'Allocated Users', align: 'center' },
];

// ----------------------------------------------------------------------

export default function ReportsProjectDetail() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const params = useParams();

  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const getProjectDetail = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/projects/${params?.id}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setProjectDetails(data?.data);
      setLoading(false);
    }
  };

  const getProjectTask = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/tasks?page=${page + 1}&project_id=${params?.id}&query=${filterName}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setRowsPerPage(data?.data?.per_page);
      setTableData(data?.data?.data);
      setCount(data?.data?.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProjectTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterName]);

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData?.length && !!filterName) ||
    (!tableData?.length && !!filterRole) ||
    (!tableData?.length && !!filterStatus);

  return (
    <Page title="Project: Detail">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Project Details"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Projects' }, { name: 'Details' }]}
        />
        <Card>
          <Typography variant="h4" sx={{ p: 3 }}>
            {projectDetails?.title}
          </Typography>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Description
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.description}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Start Date
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {moment(projectDetails?.start_date).format("D MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Due Date
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {moment(projectDetails?.due_date).format("D MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Project Managers
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.project_managers?.map((user) => (
                    <div key={user.id}>
                      {user.first_name} {user.last_name}
                    </div>
                  ))}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Status
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.project_status?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Members
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.users?.map((user) => (
                    <div key={user.id}>
                      {user.first_name} {user.last_name}
                    </div>
                  ))}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Visibility
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {projectDetails?.visibility}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/project/edit/${params?.id}`);
              }}
            >
              Edit Project
            </Button>
          </Box>
        </Card>

        <br />
        <Card>
          <header>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                p: 3,
              }}
            >
              <Typography variant="h4" sx={{ mb: 3 }}>
                Tasks
              </Typography>
            </Box>
          </header>

          <TaskTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            // optionsRole={ROLE_OPTIONS}
            search="current task"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <TaskTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      // onEditRow={() => handleEditRow(row.name)}
                      />
                    ))}

                    {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}