import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Stack,
  Typography,
  InputAdornment,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AxiosAll from 'src/services/axiosAll';
import { useSelector } from 'react-redux';
import palette from 'src/theme/palette';
import { useNavigate } from 'react-router';

export default function KanbanWatchPopup({ open, onClose, task, column, redirect, rewatch, callback = () => {} }) {
  const [query, setQuery] = useState('');
  const [selecttask, setSelectTask] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [comment, setComment] = useState('');
  const { userDetails, userList } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;

  const warningMainColor = palette.light.warning.main;
  const grey900Color = palette.light.grey[900];
  console.log(userDetails);
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSave = async () => {
    try {
      const data = {
        dependentTaskId: selecttask?.id,
        comment: `Watched Task :  ${comment}`,
      };
      const response = await AxiosAll('post', `/tasks/${task?.id}/add-to-watchlist`, data, token);
      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage('Added to watch list successfully!');
        setSnackbarOpen(true);
        console.log('Added to watch list successfully!', response.data);
        // window.location.reload();
        if (redirect) {
          navigate(`/tasks/details/${task?.id}`);
        }
        callback();
      }
      onClose();
    } catch (error) {
      console.error('Error Added to watch list successfully!', error);
    }
  };
  console.log('task', task);
  const handleRemove = async () => {
    try {
      const response = await AxiosAll('post', `/tasks/${task?.id}/remove-from-watchlist`, {}, token);
      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage(' Removed from watch list successfully!');
        setSnackbarOpen(true);
        console.log('Removed from watch list successfully!', response.data);
        // window.location.reload();
        if (redirect) {
          navigate(`/tasks/details/${task?.id}`);
        }
        callback();
      }
      onClose();
    } catch (error) {
      console.error('Error Removed from watch list successfully!', error);
    }
  };

  const handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await AxiosAll('get', `/tasks/dropdown?query=${query}`, {}, token);
      console.log(response);
      setSearchResults(response.data.data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
  console.log(searchResults, selecttask);
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogContent>
          <>
            {task?.watchers?.some((item) => item.id === user?.id) || rewatch ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Watch
                </Typography>
                <Typography variant="body1" color="text.secondary" marginY={2}>
                  Are you sure you want to remove it from watch list?
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleRemove}>
                    Remove
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                <Typography variant="h6" gutterBottom>
                  Watch
                </Typography>
                <Typography variant="body1" color="text.secondary" marginY={2}>
                  Are you sure you want to watch this task?
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleSave}>
                    Confirm
                  </Button>
                </Stack>
              </>
            )}

            {/* {task?.watchers?.length <= 0 ? (
            <>
            <Typography variant="h6" gutterBottom>
                Watch
              </Typography>
              <Typography variant="body1" color="text.secondary" marginY={2}>
                This Task is Watching  by Admin.
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Confirm
                </Button>
              </Stack>
              </>
          ): 
          (
            <>
             <Typography variant="h6" gutterBottom>
                Watch
              </Typography>
              <Typography variant="body1" color="text.secondary" marginY={2}>
                This Task is Watching  by Admin.
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Remove
                </Button>
              </Stack> 
              </>
            )} */}
          </>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

KanbanWatchPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  column: PropTypes.object,
  task: PropTypes.object,
};
