import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-duplicates
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, Card, Chip, Grid, Stack, TextField, Typography } from '@mui/material';
// utils
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';

// routes
// components
import axios from 'axios';
import moment from 'moment/moment';
// eslint-disable-next-line import/no-unresolved
import { newServer } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

ProjectNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentProject: PropTypes.object,
};

export default function ProjectNewEditForm({ isEdit, currentProject }) {
  const navigate = useNavigate();
  const params = useParams();

  const [search, setSearch] = useState('');
  const [startupDropdown, setStartupDropdown] = useState([]);
  const [projectStatusDropdown, setProjectStatusDropdown] = useState([]);
  const [userDropdownData, setUserDropdownData] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [users, setUsers] = useState([]);
  const [startupObject, setStartupObject] = useState(null);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    startup: Yup.string().required('Startup is required'),
    title: Yup.string().required('Title is required'),
    status: Yup.string().required('Status is required'),
    description: Yup.string().required('Description is required'),
    startDate: Yup.string().required('Start Date is required'),
    dueDate: Yup.string().required('Due Date is required'),
    visibility: Yup.string().required('Visibility is required'),
    state: Yup.string().required('State is required'),
    users: Yup.array().min(1, 'Users is required'),
    manager: Yup.string().required('Manager is required'),
    tester: Yup.string().required('Tester is required'),
  });

  const defaultValues = useMemo(
    () => ({
      startup: currentProject?.startup_detail?.name || '',
      title: currentProject?.title || '',
      status: currentProject?.status || '1',
      startDate: currentProject?.start_date || '',
      dueDate: currentProject?.due_date || '',
      visibility: currentProject?.visibility || 'visible',
      state: currentProject?.active_state || 'active',
      description: currentProject?.description || '',
      users: [],
      manager: currentProject?.project_managers?.[0]?.id || '',
      tester: currentProject?.project_testers?.[0]?.id || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProject]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentProject) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentProject]);

  useEffect(() => {
    if (isEdit && currentProject) {
      const newUserData = currentProject?.users?.map((item) => {
        const { first_name, last_name, id } = item;
        return { id, label: `${first_name} ${last_name}` };
      });
      setValue('users', newUserData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentProject]);

  useEffect(() => {
    if (values?.startup) {
      const newData = startupDropdown?.filter((item) => item?.name === values?.startup);
      if (newData?.length) {
        setStartupObject(newData[0]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.startup, startupDropdown]);

  useEffect(() => {
    // remove users from dropdown if already selected and add if unselected
    const newData = userDropdownData?.filter((item) => {
      const found = values?.users?.find((user) => user.id === item.id);
      if (found) {
        return false;
      }
      return true;
    });
    setUserDropdown(newData);
    setUsers(values?.users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.users, userDropdownData]);

  const getStartupDropdownData = async () => {
    const response = await AxiosAll('get', `/common/startups?search=${search}`, null, token);
    const { data } = response;
    // replace name with label in data object
    const newData = data.map((item) => {
      const { name, ...rest } = item;
      return { ...rest, label: name };
    });

    setStartupDropdown(newData);
  };

  const getProjectStatusDropdownData = async () => {
    const response = await AxiosAll('get', `/common/project-statuses`, null, token);
    const { data } = response;
    setProjectStatusDropdown(data);
  };

  const getUsersDropdownData = async () => {
    const response = await AxiosAll('get', `/common/users`, null, token);
    const { data } = response;
    const newData = data.map((item) => {
      const { first_name, last_name, ...rest } = item;
      return { ...rest, label: `${first_name} ${last_name}` };
    });
    // setUserDropdown(newData);
    setUserDropdownData(newData);
  };

  useEffect(() => {
    getStartupDropdownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getProjectStatusDropdownData();
    getUsersDropdownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    // eslint-disable-next-line no-debugger
    try {
      const formData = new FormData();
      formData.append('startup_id', startupObject?.id || currentProject?.startup_id);
      formData.append('title', values?.title);
      formData.append('status', values?.status);
      formData.append('start_date', moment(values?.startDate).format('YYYY-MM-DD'));
      formData.append('due_date', moment(values?.dueDate).format('YYYY-MM-DD'));
      formData.append('visibility', values?.visibility);
      formData.append('active_state', values?.state);
      formData.append('description', values?.description);
      formData.append('manager_id', values?.manager);
      formData.append('tester_id', values?.tester);

      users.forEach((item, index) => {
        formData.append(`user_ids[${index}]`, item?.id);
      });

      const createStartupURL = `${newServer}/projects`;
      const updateStartupURL = `${newServer}/projects/${params.id}`;

      const response = await axios.post(isEdit ? updateStartupURL : createStartupURL, formData, {
        headers: {
          contentType: 'multipart/form-data',
          Authorization: `Bearer ${userDetails.token}`,
        },
      });

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        navigate('/project/project-list', { replace: true });
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }

      reset();
    } catch (error) {
      console.log(error);
      const { data } = error?.response;
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <div>
                <LabelStyle>Select Startup</LabelStyle>
                <Controller
                  name="startup"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      onChange={(event, newValue) => {
                        field.onChange(newValue.label);
                        setStartupObject(newValue);
                      }}
                      isOptionEqualToValue={(option, value) => option.label === value}
                      options={startupDropdown}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search startup"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div>
                <LabelStyle>Project Title</LabelStyle>
                <RHFTextField name="title" placeholder="Title" />
              </div>

              <div>
                <LabelStyle>Project Status</LabelStyle>
                <RHFSelect name="status" placeholder="Status">
                  {projectStatusDropdown.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </RHFSelect>
              </div>

              <div>
                <LabelStyle>Start date</LabelStyle>
                {/* <RHFTextField min={new Date().toISOString().split('T')[0]} name="startDate" type="date" /> */}
                <RHFTextField
                  min={isEdit ? undefined : new Date().toISOString().split('T')[0]}
                  name="startDate"
                  type="date"
                />
              </div>
              <div>
                <LabelStyle>Due date</LabelStyle>
                <RHFTextField name="dueDate" type="date" min={values?.startDate} />
              </div>
              <div>
                <LabelStyle>Visibility</LabelStyle>
                <RHFSelect name="visibility">
                  <option value="visible">Visible</option>
                  <option value="hidden">Invisible</option>
                </RHFSelect>
              </div>
              {/* <div>
                <LabelStyle>State</LabelStyle>
                <RHFSelect name="state">
                  <option value="active">Active</option>
                  <option value="archive">Archived</option>
                </RHFSelect>
              </div> */}
              <div>
                <LabelStyle>Manager</LabelStyle>
                <RHFSelect name="manager">
                  <option value={''}>Select</option>
                  {userDropdownData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </RHFSelect>
              </div>
              <div>
                <LabelStyle>Users</LabelStyle>
                <Controller
                  name="users"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                      }}
                      getOptionDisabled={(option) => option}
                      options={userDropdown}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip key={option.id} label={option.label} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField placeholder="Search user" {...params} error={!!error} helperText={error?.message} />
                      )}
                    />
                  )}
                />
              </div>

              <div>
                <LabelStyle>Tester</LabelStyle>
                <RHFSelect name="tester">
                  <option value={''}>Select</option>
                  {userDropdownData.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </RHFSelect>
              </div>
            </Box>

            <Stack sx={{ mt: 3 }}>
              <LabelStyle>Description</LabelStyle>
              <RHFTextField name="description" placeholder="Write the description here..." multiline rows={4} />
            </Stack>

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create' : 'Save Changes'}
              </LoadingButton>
              {isEdit && (
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={() => {
                    navigate('/project/project-list');
                  }}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
