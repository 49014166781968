import {
  Card, Container, Table,
  TableBody, TableCell, TableContainer, TablePagination, TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useTable from 'src/hooks/useTable';
import AxiosAll from 'src/services/axiosAll';
import { fToNow } from 'src/utils/formatTime';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom } from '../../components/table';
import useSettings from '../../hooks/useSettings';

const TABLE_HEAD = [
  { id: 'id', label: '', alignRight: false },
  { id: 'activity', label: 'Activity', align: 'left' },
  { id: 'activity_type', label: 'Type', align: 'left' },
  { id: 'activity_action_type', label: 'Action', align: 'left' },
  { id: 'timelog', label: 'Timelog', align: 'left' },
];

const Activities = () => {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [activityList, setActivityList] = useState([]);
  const [count, setCount] = useState(0);

  const getActivity = async () => {
    try {
      const res = await AxiosAll('get', `/activities?page=${page + 1}&limit=${rowsPerPage}`, null, userDetails?.token);
      const data = res.data.data;
      setActivityList(data);
      setCount(res?.data?.data?.total)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <Page title="Activities">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Activities" links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Activities' }]} />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size="medium">
                <TableHeadCustom
                  hideCheckbox
                  headLabel={TABLE_HEAD}
                  rowCount={activityList.length}
                  numSelected={activityList.length}
                />
                <TableBody>
                  {activityList?.data?.map((activity, index) => (
                    <TableRow key={index} hover>
                      <TableCell>{activity.id}</TableCell>
                      <TableCell>{activity.message}</TableCell>
                      <TableCell>{activity.activity_type}</TableCell>
                      <TableCell>{activity.activity_action_type}</TableCell>
                      <TableCell>{fToNow(activity.created_at)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default Activities;
