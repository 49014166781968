import { Box, Typography } from '@mui/material';
import React from 'react';

export const PopUpDiffLabel = ({ title, paddY, paddX, labelRadius, mainTitle }) => {
  const mainPadding = `${paddY}px ${paddX}px`;

  // console.log(mainPadding);
  return (
    <Box
      sx={{ bgcolor: 'background.neutral' }}
      style={{
        // backgroundColor: color,
        padding: mainPadding,
        borderRadius: labelRadius,
        display: 'flex',
        gap: 4,
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1">{title} : {mainTitle}</Typography>
    </Box>
  );
};
