import { useSnackbar } from 'notistack';
// form
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
// components
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { imagesAll } from 'src/assets/Images_index';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Markdown from 'src/components/Markdown';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import AxiosAll from 'src/services/axiosAll';
import { Controller } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Details } from '@mui/icons-material';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));
// ----------------------------------------------------------------------

export default function LeaveDetail() {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const { enqueueSnackbar } = useSnackbar();
  const [textInputValue, setTextInputValue] = useState(null);
  const params = useParams();
  const { themeStretch } = useSettings();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [fieldData, setFieldData] = useState([]);
  const [readyToCompensate, setReadyToCompensate] = useState('');
  const [showDatePicker, setShowDatePicker] = useState({});
  const [selectedDates, setSelectedDates] = useState({});
  const [selectDate, setSelectDate] = useState({});

  const [leaveBgColor, setLeaveBgColor] = useState();

  const fromDate = moment().subtract(12, 'months').startOf('month');

  const toDate = moment().endOf('month');

  const [userLeaveOverview, setUserLeaveOverview] = useState({});

  const nav = useNavigate();
  const generateFields = () => {
    const fields = [];
    if (startDate && endDate) {
      const diffDays = moment(endDate).diff(moment(startDate), 'days');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < diffDays; i++) {
        const date = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
        const d = moment(startDate).add(i, 'days');
        const Day = d.format('dddd').substring(0, 3);
        console.log('diffDays', diffDays);
        fields.push(
          <div key={date}>
            <LabelStyle className={fieldData.includes(date) ? 'dateColorgreen' : 'dateColorRed'}>
              {date} &nbsp; {Day} &nbsp;:
              <Checkbox
                className={fieldData.includes(date) ? 'checkboxColorgreen' : 'checkboxColorRed'}
                type="checkbox"
                value={date}
                onChange={handleFieldChange}
              />
              {fieldData.includes(date) && readyToCompensate === 'true' && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Compensate Date
                  </Typography>
                  <DesktopDatePicker
                    label="Select Date"
                    inputFormat="YYYY-MM-DD"
                    value={selectedDates[date] || null}
                    onChange={(newValue) => handleDateChange(date, newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            </LabelStyle>
          </div>
        );
      }
    }
    return fields;
  };

  const handleFieldChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setFieldData([...fieldData, value]);
    } else {
      setFieldData(fieldData.filter((v) => v !== value));
    }
  };

  const handleDateChange = (date, newValue) => {
    setSelectedDates({ ...selectedDates, [date]: moment(newValue).format('YYYY-MM-DD') });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const date of fieldData) {
      formData[date] = true;
    }

    const rejectedDates = [];

    if (startDate && endDate) {
      const diffDays = moment(endDate).diff(moment(startDate), 'days');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < diffDays; i++) {
        const date = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
        if (!formData[date]) {
          rejectedDates.push(date);
        }
      }
    }
    if (textInputValue !== null) {
      try {
        const formData1 = new FormData();

        fieldData?.map((item, index) => formData1.append(`dates[${index}][approved]`, item));
        rejectedDates?.map((item, index) => formData1.append(`dates[${index}][rejected]`, item));
        formData1.append('comment', textInputValue);
        formData1.append('is_compensating', readyToCompensate);
        const compensationDates = Object.values(selectedDates).filter((date) => date !== null);
        formData1.append('compensation_dates', JSON.stringify(compensationDates));

        const response = await AxiosAll(
          'post',
          `/leave/approve-or-reject/${detail?.id}`,
          formData1,
          token,
          'multipart/form-data'
        );

        if (response.data.statusCode === 200) {
          enqueueSnackbar(response.data?.message, { variant: 'success' });
          setTextInputValue('');
          nav('/leaves');
        } else {
          enqueueSnackbar(response.data?.message, { variant: 'error' });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      enqueueSnackbar('Please add comment', { variant: 'error' });
    }
  };

  const handleTextInputChange = (e) => {
    setTextInputValue(e.target.value);
  };

  const getLeaveDetail = async () => {
    setLoading(true);
    try {
      const response = await AxiosAll('get', `/holiday/${params?.id}`, {}, token);
      if (response?.status === 200) {
        const data = response?.data?.data;
        setDetail(data);
        setStartDate(moment(data?.start_date).format('YYYY-MM-DD'));
        const end = new Date(data?.end_date).setDate(new Date(data?.end_date).getDate() + 1);
        setEndDate(moment(end).format('YYYY-MM-DD'));
        setReadyToCompensate(data?.is_compensating ? 'yes' : 'no');

        getUserLeaveOverview(data?.applied_by);
      }
    } catch (error) {
      console.log('Somethings not right', error?.message);
    } finally {
      setLoading(false);
    }
  };

  const getUserLeaveOverview = async (userId) => {
    try {
      const response = await AxiosAll('get', `/leave/${userId}?startDate=${fromDate}&endDate=${toDate}`, {}, token);
      if (response?.status === 200) {
        const data = response?.data?.data;
        setUserLeaveOverview(data?.overview || {});
      }
    } catch (error) {
      console.log('Somethings not right!', error?.message);
    }
  };

  useEffect(() => {
    setFieldData([]);
    getLeaveDetail();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    detail && setTextInputValue(detail?.comment);
  }, [detail]);

  const otherLeaves = userLeaveOverview?.halfDay / 2 + userLeaveOverview?.fullDay;
  const sickLeaves = userLeaveOverview?.sickHalfDay / 2 + userLeaveOverview?.sickFullDay;
  const festivalLeaves = userLeaveOverview?.festivalHalfDay / 2 + userLeaveOverview?.festivalFullDay;

  const totalLeaves = otherLeaves - userLeaveOverview?.compensated;

  const getBackgroundColor = () => {
    if (totalLeaves < 10) {
      return '#99ff99';
    }

    if (totalLeaves >= 10 && totalLeaves <= 12) {
      return '#ff9999';
    }

    return '#ff4d4d';
  };

  useEffect(() => {
    const leaveColor = getBackgroundColor();
    setLeaveBgColor(leaveColor);
  }, [totalLeaves]);

  return (
    <Page title="Leave Action">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Leave Details" links={[{}]} />

        {loading ? (
          <Stack>
            <CircularProgress />
          </Stack>
        ) : (
          <Card>
            <Typography sx={{ margin: '0.5rem 0 0.5rem 1.5rem' }}>
              {detail?.applied_by_user?.first_name} {detail?.applied_by_user?.last_name} apply for{' '}
              {detail?.leave_type?.replace(/_/g, ' ')} leave{' '}
            </Typography>
            <Divider />

            <CardContent>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      alt={detail?.applied_by_user?.first_name}
                      src={detail?.applied_by_user?.profile_picture || imagesAll.mainWhiteLogo}
                      sx={{ mr: 2 }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {detail?.applied_by_user?.first_name} {detail?.applied_by_user?.last_name}
                    </Typography>
                  </Typography>

                  <div>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Subject
                    </Typography>
                    <Typography>{detail?.name}</Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Leave Type
                    </Typography>
                    <Typography>{detail?.leave_type?.replace(/_/g, ' ')}</Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Leave Category
                    </Typography>
                    <Typography>{detail?.leave_category?.replace(/_/g, ' ')}</Typography>
                  </div>
                  {detail?.leave_category === 'festival' && (
                    <div>
                      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        Festival Name
                      </Typography>
                      <Typography>{detail?.festival_name?.replace(/_/g, ' ')}</Typography>
                    </div>
                  )}

                  <div>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Description
                    </Typography>
                    <Markdown children={detail?.description} />
                  </div>
                  <div>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      {detail?.applied_by_user?.first_name} {detail?.applied_by_user?.last_name} Ready to compensate
                    </Typography>
                    <RadioGroup row value={readyToCompensate}>
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" disabled />

                      <FormControlLabel value="no" control={<Radio />} label="No" disabled />
                    </RadioGroup>
                  </div>

                  {detail?.status === 'pending' ? (
                    <div>
                      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        Do you want {detail?.applied_by_user?.first_name} {detail?.applied_by_user?.last_name} to
                        compensate for this leave?
                      </Typography>
                      <RadioGroup row value={readyToCompensate} onChange={(e) => setReadyToCompensate(e.target.value)}>
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  ) : null}

                  {detail?.compensation_dates?.length > 0 && (
                    <div>
                      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        Compensate Date
                      </Typography>
                      {detail.compensation_dates.map((date) => (
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          {date?.date}
                        </Typography>
                      ))}
                    </div>
                  )}

                  <div>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Apply Date
                    </Typography>
                    {detail?.status === 'pending' ? (
                      generateFields()
                    ) : (
                      <Typography sx={{ mb: 2 }}>
                        {detail?.start_date === detail?.end_date
                          ? moment(detail?.start_date).format('YYYY/MM/DD')
                          : `${moment(detail?.start_date).format('YYYY/MM/DD')} TO  ${moment(detail?.end_date).format(
                              'YYYY/MM/DD'
                            )}`}
                      </Typography>
                    )}
                    {detail?.status === 'pending' ? (
                      <TextField
                        label="Add Comments"
                        value={textInputValue}
                        onChange={handleTextInputChange}
                        fullWidth
                        // sx={{ width: {xs: '100%', md: '50%', lg:"700px"},}}
                        multiline
                        onFocus={textInputValue !== '' || textInputValue !== null}
                      />
                    ) : (
                      <div>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          Comment
                        </Typography>
                        <Typography>{textInputValue}</Typography>
                      </div>
                    )}
                  </div>
                </Stack>
                <Card sx={{ my: 3 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Leave Overview ({' '}
                      {`From ${fromDate.format('DD-MM-YYYY')} To ${toDate.format('DD-MM-YYYY')} Includes all leaves`} )
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Leave Type</TableCell>
                            <TableCell align="right">Count</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Sick Leaves
                            </TableCell>
                            <TableCell align="right">{sickLeaves}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Festival Leaves
                            </TableCell>
                            <TableCell align="right">{festivalLeaves}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Other Leaves
                            </TableCell>
                            <TableCell align="right">{otherLeaves}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Compensated Days
                            </TableCell>
                            <TableCell align="right">{userLeaveOverview?.compensated}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Total Leaves
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                backgroundColor: leaveBgColor,
                                color: 'black', // Optional: Ensures text visibility against background color
                              }}
                            >
                              {totalLeaves}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>

                <Divider />

                <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                  {detail?.status === 'pending' && (
                    <LoadingButton onClick={handleSubmit} type="submit" variant="contained" sx={{ ml: 2 }}>
                      Submit
                    </LoadingButton>
                  )}
                </Stack>
              </form>
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  );
}
