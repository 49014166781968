import { Draggable, Droppable } from '@hello-pangea/dnd';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
// @mui
import { Paper, Stack, alpha } from '@mui/material';
// redux
// import { useDispatch } from '../../../redux/store';
// import { deleteColumn, updateColumn, addTask, deleteTask } from '../../../redux/slices/kanban';
// components
import { useBoolean } from 'src/hooks/use-boolean';
import { clearColumn, createTask, deleteColumn, deleteTask, updateColumn, updateTask } from 'src/pages/kanban/moveData';
//
import KanbanColumnToolBar from './KanbanColumnToolBar';
import KanbanTaskItem from './kanban-task-item';

// ----------------------------------------------------------------------

KanbanColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
};

export default function KanbanColumn({ column, tasks, index, callBack }) {
  const { enqueueSnackbar } = useSnackbar();

  const openAddTask = useBoolean();

  const handleUpdateColumn = useCallback(
    async (columnName) => {
      try {
        if (column.name !== columnName) {
          updateColumn(column.id, columnName);

          enqueueSnackbar('Update success!', {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [column.id, column.name, enqueueSnackbar]
  );

  const handleClearColumn = useCallback(async () => {
    try {
      clearColumn(column.id);
    } catch (error) {
      console.error(error);
    }
  }, [column.id]);

  const handleDeleteColumn = useCallback(async () => {
    try {
      deleteColumn(column.id);

      enqueueSnackbar('Delete success!', {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    } catch (error) {
      console.error(error);
    }
  }, [column.id, enqueueSnackbar]);

  const handleAddTask = useCallback(
    async (taskData) => {
      try {
        createTask(column.id, taskData);

        openAddTask.onFalse();
      } catch (error) {
        console.error(error);
      }
    },
    [column.id, openAddTask]
  );

  const handleUpdateTask = useCallback(async (taskData) => {
    try {
      updateTask(taskData);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleDeleteTask = useCallback(
    async (taskId) => {
      try {
        deleteTask(column.id, taskId);

        enqueueSnackbar('Delete success!', {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [column.id, enqueueSnackbar]
  );

  // rendering add Task commented

  // const renderAddTask = (
  //   <Stack
  //     spacing={2}
  //     sx={{
  //       pb: 3,
  //     }}
  //   >
  //     {openAddTask.value && (
  //       <KanbanTaskAdd
  //         status={column.name}
  //         onAddTask={handleAddTask}
  //         onCloseAddTask={openAddTask.onFalse}
  //       />
  //     )}

  //     <Button
  //       fullWidth
  //       size="large"
  //       color="inherit"
  //       startIcon={
  //         <Iconify
  //           icon={openAddTask.value ? 'solar:close-circle-broken' : 'mingcute:add-line'}
  //           width={18}
  //           sx={{ mr: -0.5 }}
  //         />
  //       }
  //       onClick={openAddTask.onToggle}
  //       sx={{ fontSize: 14 }}
  //     >
  //       {openAddTask.value ? 'Close' : 'Add Task'}
  //     </Button>
  //   </Stack>
  // );

  const addStyle = column.name === 'In Progress';

  return (
    <Draggable draggableId={column.id} index={index} isDragDisabled>
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            px: 2,
            borderRadius: 2,
            bgcolor: addStyle ? '#C8FACD' : 'background.neutral',
            ...(snapshot.isDragging && {
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.24),
            }),
          }}
          className="column-kanban-new-design"
        >
          <Stack {...provided.dragHandleProps}>
            <KanbanColumnToolBar
              column={column}
              columnName={column.name}
              onUpdateColumn={handleUpdateColumn}
              onClearColumn={handleClearColumn}
              onDeleteColumn={handleDeleteColumn}
            />

            <Droppable droppableId={column.id} type="TASK">
              {(dropProvided) => (
                <Stack
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                  spacing={2}
                  sx={{
                    py: 3,
                    width: 280,
                  }}
                  className="column-kanban-height-design"
                >
                  {column.taskIds.map((taskId, taskIndex) => (
                    <KanbanTaskItem
                      key={taskId}
                      column={column}
                      index={taskIndex}
                      task={tasks[taskId]}
                      onUpdateTask={handleUpdateTask}
                      onDeleteTask={() => handleDeleteTask(taskId)}
                      callBack={callBack}
                    />
                    // kanban-skeleton add when loader is active
                    // <KanbanTaskItemSkeleton key={taskId} index={taskIndex} sx={{ width: 280 }} />
                  ))}

                  {dropProvided.placeholder}
                </Stack>
              )}
            </Droppable>

            {/* {renderAddTask} */}
          </Stack>
        </Paper>
      )}
    </Draggable>
  );
}

KanbanColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
  tasks: PropTypes.object,
};
