import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import MobilePicker from 'src/components/Timepicker';
import { findTimeDifference } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddLogsForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNextStep: PropTypes.func,
  onCreateBilling: PropTypes.func,
  editTask: PropTypes.object,
};

export default function AddLogsForm({ open, onClose, editTask }) {
  const NewAddressSchema = Yup.object().shape({
    // time: Yup.string().min(5, 'Must be in the form of HH:MM').required('Time is required'),
    comments: Yup.string().required('Description  is required'),
    date: Yup.string().required('date is required'),
    // project: Yup.string().required('Project is required'),
    // type: Yup.string().required('type is required'),
  });

  const typeData = [
    { id: 'discussion', title: 'Discussion' },
    { id: 'internal_meeting', title: 'Internal Meeting' },
    { id: 'external_meeting', title: 'External Meeting' },
    { id: 'holiday', title: 'Holiday' },
    { id: 'break', title: 'Break' },
    { id: 'celebration', title: 'Celebration' },
    { id: 'down_time', title: 'Down Time' },
  ]
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [project, setProject] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const defaultValues = useMemo(
    () => ({
      // time: '01:00',
      comments: '',
      date: moment(new Date()).format('YYYY-MM-DD'),
      // task: '',
      project: project[0]?.id || '',
      type: "discussion"
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]
  );


  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch()

  const handleOnClose = () => {
    reset(defaultValues)
    onClose()
    setStartTime("00:00");
    setEndTime("00:00")
  }

  const afterCheck = async (value) => {
    // eslint-disable-next-line no-debugger
    const data = {
      comment: value?.comments,
      // task_id: value?.task || '',
      date: value?.date,
      // time_format: value?.time,
      start_time: startTime,
      end_time: endTime,
      type: value?.type,
      project_id: value?.project
    };
    try {
      const response = await AxiosAll('post', `/task-time-logs`, data, token);
      if (response?.status === 200) {
        handleOnClose()
        reset(defaultValues)
        navigate('/logs');
      } else {
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.data?.message, { variant: 'error' });
    }
    setLoading(false);
  }

  const onSubmit = async (value) => {
    setLoading(true);
    if ((values?.type === "discussion" || values?.type === "external_meeting") && values?.project === "") {
      enqueueSnackbar("Please add project.", { variant: 'error' });
      setLoading(false);
    } else if (startTime === "00:00" || endTime === "00:00" || startTime === endTime) {
      enqueueSnackbar("Start time or End time needs to change.", { variant: 'error' });
      setLoading(false);
    } else if (startTime > endTime) {
      enqueueSnackbar("Start time is greater than End time.", { variant: 'error' });
      setLoading(false);
    } else {
      afterCheck(value);
    }
  };

  const getProject = async () => {
    const response = await AxiosAll('get', '/common/projects', {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  useEffect(() => {
    getProject();
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
      <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
          {editTask ? "Edit Log" : "Add Log"}
        </div>
        <div> Time Spend :  &nbsp;{findTimeDifference({ start: startTime, end: endTime })}  </div></DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>Type</LabelStyle>
              <RHFSelect disabled={!!editTask} name="type" >
                {typeData?.map((data, index) => (
                  <option key={index} value={data?.id}>
                    {data?.title}
                  </option>
                ))}
              </RHFSelect>
            </div>

            {(values?.type === "discussion" || values?.type === "external_meeting") && <div>
              <LabelStyle>Project*</LabelStyle>
              <RHFSelect disabled={!!editTask} name="project" >
                <option value="">Select Project</option>
                {project?.map((data, index) => (
                  <option key={index} value={data?.id}>
                    {data?.title}
                  </option>
                ))}
              </RHFSelect>
            </div>}

            <div>
              <LabelStyle>Date*</LabelStyle>
              <RHFTextField name="date" type="date" />
            </div>

            {/* <div>
              <LabelStyle>Time Taken*</LabelStyle>
              <Controller
                name="time"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleTimeChange(e, field.onChange)}>
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        error={!!error}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="body2">HH:MM</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </div> */}
            <div className='pop-editor'>
              <LabelStyle>Description</LabelStyle>
              <RHFEditor simple name="comments" />
            </div>
            <Stack direction={{xs: 'column', sm: 'row'}} justifyContent={{xs: 'start', sm: 'space-between'}} spacing={2}>
              <div style={{ flexDirection: 'row' }}>
                Start Time :   &nbsp;
                <MobilePicker time={startTime} handleTime={setStartTime} />
              </div>
              <div style={{ flexDirection: 'row' }}>
                End Time :   &nbsp;
                <MobilePicker time={endTime} handleTime={setEndTime} />
              </div>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Submit
          </LoadingButton>
          <Button color="inherit" variant="outlined" onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
