import { Grid, Paper, styled } from '@mui/material';
import { colorData } from 'src/core';

// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function ColorInfo() {
    return (
        <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} xs={10}>
            {colorData?.map((item, index) => (
                <Grid key={index} style={{ display: 'flex', marginLeft: '1.5rem', }} className="graphdots" >
                    <Item>{item?.name} &nbsp; :</Item>

                    <Item style={{ backgroundColor: item?.color, width: '15px', height: '15px' }}> </Item>
                </Grid>
            ))}
        </Grid>
    )
}

export default ColorInfo;