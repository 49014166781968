import PropTypes from 'prop-types';
// form

// @mui
import { Avatar, AvatarGroup, CardContent, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import Markdown from 'src/components/Markdown';
import MoMTable from 'src/components/table/MoMTable';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

MinutesDetail.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
};

export default function MinutesDetail({ open, setOpen, data ,MoMid}) {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const [loading, setLoading] = useState(true);
    const [updateMemberRow, SetUpdateMemberRow] = useState([])
    const [userData, setUserData] = useState({})

    const handleOnClose = () => {
        setOpen(false)
    }

    function handleLinkClick(link) {
        window.open(link, '_blank');
    }

    const getUser = async () => {
        const response = await AxiosAll('get', `/common/users`, null, token);
        if (response?.status === 200) {
            const { data } = response;
            const newData = data?.map((item) => {
                const { first_name, last_name, ...rest } = item;
                return { ...rest, label: `${first_name} ${last_name}` };
            });
            setUserData(newData);
            setLoading(false)

        }
    };

    const getMOMById = async () => {
        const response = await AxiosAll('get', `/minutes-of-meeting/${MoMid}`, null, token);
        if (response?.status === 200) {
            const { data } = response?.data;
            // console.log(data?.data)


            const updatedMomActions = data?.mom_actions.map(item => {
                return {
                    ...item,
                    LocalId: item?.id /* generate or assign a LocalId value here */
                };
            });
            SetUpdateMemberRow(updatedMomActions)
            setLoading(false)

        }
    };

    useEffect(() => {
        if (open) {
            getUser()
            getMOMById()
        }
    },[open])

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleOnClose}>

            <DialogContent>
                <CardContent>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Project
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {data?.project?.title}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Title
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {data?.title}
                            </Typography>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Time
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {data?.time_format}
                            </Typography>
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Date
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {moment(data?.date).format("D MMM YYYY")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Assigned User
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <AvatarGroup max={8} sx={{ '& .MuiAvatar-root': { width: 25, height: 25 }, justifyContent: 'flex-end' }}>
                                    {data?.assigned_users?.map((e, index) => <Avatar alt={e?.full_name} src={e?.profile_picture} key={index} />)}
                                </AvatarGroup>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} style={{ marginTop: '1.5rem' }}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Description
                        </Typography>
                        <Markdown children={data?.description} />
                    </Grid>
                    <MoMTable userData={userData}  updateMemberRow={updateMemberRow}/>
                </CardContent>
            </DialogContent>

        </Dialog>
    );
}
