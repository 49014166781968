import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Box, Card,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import FilterTaskModal from 'src/components/filterModal/filterTaskModal';
import ColorInfo from 'src/components/table/ColorInfo';
import { TABLE_HEAD, TABLE_HEAD_ADMIN } from 'src/core';
import { setCount } from 'src/redux/actions/reviewCount';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
// sections
import { TaskTableRow, TaskTableToolbar } from '../../sections/@dashboard/task/list';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

// ----------------------------------------------------------------------

export default function FavouriteTasks() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  // get redux state
  const { userDetails ,userList , projectList , priorityList } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;

  const [tableData, setTableData] = useState([]);

  const [filterTitle, setFilterTitle] = useState('');

  const [filterDeadline, setFilterDeadline] = useState('all');
  const params = useParams();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [total, setTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [project, setProject] = useState();
  const [users, setUser] = useState();
  const [userID, setUserID] = useState('');
  const [projectId, setProjectId] = useState('');
  const [dateFilter, setDate] = useState();
  const [StatusId, setStatusId] = useState(params?.status ? params?.status : '');
  const [status, setStatus] = useState()
  const [priorities, setPriorities] = useState()
  const [prioritieId, setPrioritieId] = useState('')
  const dispatch = useDispatch();

  const handleFilterName = (filterTitle) => {
    setFilterTitle(filterTitle);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterDeadline(event.target.value);
  };

  const getTasks = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/tasks?page=${page + 1}&query=${filterTitle}&project_id=${projectId}&user_id=${userID}&status=${StatusId}&priority=${prioritieId}&type=favorite_tasks&due_date=${(dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setLoading(false);
      setTableData(data?.data?.data);
      setTotal(response?.data?.data?.total);
      getCounts();
    }
  };
  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, token);
    const { data } = response;

    dispatch(setCount(data))
  };
  const addToFavourite = async (id) => {
    const response = await AxiosAll('post', `/mark-as-favorite/${id}`, {}, token);
    if (response?.status === 200) {
      enqueueSnackbar(response?.data?.message, { variant: 'success' });
      getTasks();
    }
  };
  // const getUser = async () => {
  //   setUser([]);
  //   const response = await AxiosAll('get', '/common/users', {}, token);
  //   if (response?.status === 200) {
  //     setUser(response?.data);
  //   }
  // };

  // const getProject = async () => {
  //   setProject([]);
  //   const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
  //   if (response?.status === 200) {
  //     setProject(response?.data);
  //   }
  // };

  const getStatus = async () => {
    setStatus([]);
    const response = await AxiosAll('get', `/task-statuses`, {}, token);
    if (response?.status === 200) {
      setStatus(response?.data?.data?.data);
    }
  };

  // const getPrioritie = async () => {
  //   setPriorities([]);
  //   const response = await AxiosAll('get', `/task-priorities`, {}, token);
  //   if (response?.status === 200) {
  //     setPriorities(response?.data?.data?.data);
  //   }
  // };

  useEffect(() => {
    getTasks();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterTitle, projectId, userID, StatusId, prioritieId, dateFilter]);

  const handleConfirmDelete = () => {
    if (selected.length > 0 && multipleDeleteMode) {
      handleDeleteRows(selected);
    } else {
      handleDeleteRow(deleteId);
    }
    setDeleteDialog(false);
  };
  const handleDeleteRow = async (id) => {
    const res = await AxiosAll('delete', `/tasks/${id}`, {}, token)
    const { data } = res;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      getTasks();
    }
    else if (data?.statusCode === 419) {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  // bulk delete is yet to complete.
  const handleDeleteRows = async (selected) => {
    const response = await AxiosAll('delete', `/tasks`, { ids: selected }, token);
    const { data } = response;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      setSelected([]);
      getTasks();
    } else {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const handleEditRow = (id) => {
    navigate(`/tasks/edit/${id}`);
  };
  const handleAddSub = (id) => {
    navigate(`/tasks/add-sub/${id}`);
  };

  const denseHeight = dense ? 52 : 72;

  // useEffect(() => {
  //   getUser();
  // }, []);

  // useEffect(() => {
  //   getProject();
  // }, [userID]);

  useEffect(() => {
    getStatus();
  }, []);

  // useEffect(() => {
  //   getPrioritie()
  // }, [])

  const isNotFound =
    (!tableData?.length && !!filterTitle) ||
    (!tableData?.length && !!filterDeadline) ||
    (!tableData?.length && !!filterStatus);

  return (
    <Page title="Favourite Tasks">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Favourite Task List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Task List' }]}
        />
        {user?.roles[0]?.id === 1 ? <FilterTaskModal
          setStatusId={setStatusId}
          setProjectID={setProjectId}
          setDate={setDate}
          dateFilter={dateFilter}
          setUserID={setUserID}
          projectId={projectId}
          userID={userID}
          project={projectList}
          status={status}
          StatusId={StatusId}
          users={userList}
          priorities={priorityList}
          setPrioritieId={setPrioritieId}
          setPage={setPage}
        /> : null}

        <Card>
          <Divider />

          <ConfirmDialogue
            open={deleteDialog}
            loading={loading}
            onClose={() => setDeleteDialog(false)}
            onConfirm={() => handleConfirmDelete(deleteId)}
            title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
              }?`}
            content="This action cannot be undone. Please confirm to proceed."
          />
          <TaskTableToolbar
            filterTitle={filterTitle}
            filterDeadline={filterDeadline}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
            search="task"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData?.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => {
                        setDeleteDialog(true);
                        setMultipleDeleteMode(true);
                      }}
                      >
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={user?.roles[0]?.id === 1 ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData?.map((row) => row.id)
                    )
                  }
                />
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <TaskTableRow
                        key={row.id}
                        row={row}
                        setDeleteDialog={setDeleteDialog}
                        setMultipleDeleteMode={setMultipleDeleteMode}
                        setDeleteId={setDeleteId}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        // onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onAddSub={() => handleAddSub(row?.id)}
                        getTasks={() => getTasks()}
                        addToFavourite={() => addToFavourite(row.id)}
                      />
                    ))}

                    {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
            <ColorInfo />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page >
  );
}
