import { Route, Routes } from 'react-router-dom';
import ResetPassword from 'src/pages/auth/ResetPassword';
// eslint-disable-next-line import/no-unresolved
import Page403 from 'src/pages/Page403';
// eslint-disable-next-line import/no-unresolved
import Page404 from 'src/pages/Page404';
// eslint-disable-next-line import/no-unresolved
import Page500 from 'src/pages/Page500';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import Login from '../pages/auth/Login';
import NewPassword from '../pages/auth/NewPassword';
import Register from '../pages/auth/Register';
// import ResetPassword from '../pages/auth/ResetPassword';
// import VerifyCode from '../pages/auth/VerifyCode';

// eslint-disable-next-line arrow-body-style
const PublicRoutes = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="change-password" element={<NewPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      {/* <Route path="verify-code" element={<VerifyCode />} /> */}
      {/* <Route path="admin-register" element={<AdminRegister />} /> */}
      <Route path={'404'} element={<Page404 />} />
      <Route path={'403'} element={<Page403 />} />
      <Route path={'500'} element={<Page500 />} />
      <Route path={'*'} element={<Login />} />
    </Routes>
  );
};

export default PublicRoutes;
