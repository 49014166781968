import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { MenuItem, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/services/axiosAll';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

ReviewTaskRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onAddSub: PropTypes.func,
  // selected: PropTypes.bool,
  getTasks: PropTypes.func,
  // setDeleteId: PropTypes.func,
  // setDeleteDialog: PropTypes.func,
  // setMultipleDeleteMode: PropTypes.func
};

export default function ReviewTaskRow({ row, getTasks }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { title, task_priority, project, due_date, spend_time_format, planned_time_format, task_status, is_due } = row;
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [openMenu, setOpenMenuActions] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
    // setMultipleDeleteMode(false);
  };

  const handleComplete = async (row) => {
    try {
      const data1 = {
        task_status: '5',
      };

      const response = await AxiosAll('post', `/tasks/status-change/new/${row?.id}`, data1, token);

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        getTasks();
        handleCloseMenu();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
        handleCloseMenu();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReassign = async (row) => {
    try {
      const data1 = {
        task_status: '1',
      };

      const response = await AxiosAll('post', `/tasks/status-change/new/${row?.id}`, data1, token);

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        getTasks();
        handleCloseMenu();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
        handleCloseMenu();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTesting = async (row) => {
    try {
      const data1 = {
        task_status: '6',
      };

      const response = await AxiosAll('post', `/tasks/status-change/new/${row?.id}`, data1, token);

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        getTasks();
        handleCloseMenu();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
        handleCloseMenu();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TableRow>
      {/* <TableCell padding="checkbox">
                <Checkbox checked={selected} onClick={onSelectRow} />
            </TableCell> */}

      {is_due ? (
        <TableCell
          align="left"
          variant="subtitle2"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/tasks/details/${row?.id}`)}
          noWrap
        >
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={'error'}
            sx={{ textTransform: 'capitalize', height: 'auto', whiteSpace: 'normal', lineHeight: '1.5' }}
          >
            {title}
          </Label>
        </TableCell>
      ) : (
        <TableCell
          align="left"
          variant="subtitle2"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/tasks/details/${row?.id}`)}
          style={{ whiteSpace: 'nowrap' }}
        >
          {title}
        </TableCell>
      )}

      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
        {project?.title}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }} style={{ whiteSpace: 'nowrap' }}>
        {moment(due_date).format('D MMM')}
      </TableCell>

      <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={task_priority?.color}
          sx={{ textTransform: 'capitalize' }}
        >
          {task_priority?.name}
        </Label>
      </TableCell>

      {task_status?.id === 4 ? (
        <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
          <Tooltip title={row?.reviewer?.full_name}>
            <Typography
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={task_status?.color}
              sx={{ textTransform: 'capitalize' }}
            >
              {/* {task_status?.name} */}
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={task_status?.color}
                sx={{ textTransform: 'capitalize' }}
              >
                {task_status?.name}
              </Label>
            </Typography>
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={task_status?.color}
            sx={{ textTransform: 'capitalize' }}
          >
            {task_status?.name}
          </Label>
        </TableCell>
      )}

      {row?.task_users.length > 0 ? (
        <TableCell
          align="left"
          style={{ whiteSpace: 'nowrap' }}
        >{`${row?.task_users[0]?.user?.first_name} ${row?.task_users[0]?.user?.last_name}`}</TableCell>
      ) : (
        <TableCell />
      )}
      <TableCell align="left" sx={{ textTransform: 'capitalize' }} style={{ whiteSpace: 'nowrap' }}>
        {planned_time_format}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }} style={{ whiteSpace: 'nowrap' }}>
        {spend_time_format}
      </TableCell>

      <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {row?.task_status?.id === 4 && (
                <>
                  <MenuItem
                    onClick={() => {
                      handleComplete(row);
                    }}
                  >
                    <Iconify icon={'fluent-mdl2:completed'} />
                    Complete
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleReassign(row);
                    }}
                  >
                    <Iconify icon={'pajamas:assignee'} />
                    Reassign
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleTesting(row);
                    }}
                  >
                    <Iconify icon={'uim:process'} />
                    Testing
                  </MenuItem>
                </>
              )}

              {row?.task_status?.id === 6 && (
                <>
                  <MenuItem
                    onClick={() => {
                      handleComplete(row);
                    }}
                  >
                    <Iconify icon={'fluent-mdl2:completed'} />
                    Complete
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleReassign(row);
                    }}
                  >
                    <Iconify icon={'pajamas:assignee'} />
                    Reassign
                  </MenuItem>
                </>
              )}

              {/* {task_status?.id === 1 && (
                <MenuItem>
                  <StartDialog task={row} getTasks={getTasks} />
                </MenuItem>
              )} */}

              {/* <MenuItem>
                  <CompleteDialog task={row} getTasks={getTasks} />
                </MenuItem>  */}

              {/* If task assigned_status is 1(not started) then no time log */}
              {/* {task_status?.id !== 1 && row?.has_siblings !== 1 && (
                <MenuItem>
                  <TimeDailogs task={row} getTasks={getTasks} />
                </MenuItem>
              )} */}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
