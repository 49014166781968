import { isBefore } from 'date-fns';
import merge from 'lodash/merge';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material';
// components
import moment from 'moment';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

// const COLOR_OPTIONS = [
//   '#00AB55', // theme.palette.primary.main,
//   '#1890FF', // theme.palette.info.main,
//   '#54D62C', // theme.palette.success.main,
//   '#FFC107', // theme.palette.warning.main,
//   '#FF4842', // theme.palette.error.main
//   '#04297A', // theme.palette.info.darker
//   '#7A0C2E', // theme.palette.error.darker
// ];

const getInitialValues = (event, range) => {
  const _event = {
    title: event?.title || '',
    description: event?.extendedProps?.description || '',
    type: event?._def?.extendedProps?.type || "holiday",
    allDay: event?.allDay || false,
    startDate: moment(event?._instance?.range?.start).format('YYYY-MM-DD') || new Date(),
    endDate: moment(event?._instance?.range?.end).subtract(1, 'day').format('YYYY-MM-DD') || new Date(),
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

CalendarForm.propTypes = {
  event: PropTypes.object,
  range: PropTypes.object,
  onCancel: PropTypes.func,
  getHoliday: PropTypes.func,
  isAdmin: PropTypes.bool
};

export default function CalendarForm({ event, range, onCancel, getHoliday, isAdmin }) {
  const { enqueueSnackbar } = useSnackbar();
  // get redux state
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const isCreating = Object.keys(event).length === 0;
  const disable = !isAdmin && Object.keys(event).length !== 0;

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('end Date is required'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newEvent = {
        name: data.title,
        start_date: data.startDate,
        end_date: data.endDate,
        type: data.type,
        show: data.allDay,
      };
      if (event?._def?.publicId) {
        const response = await AxiosAll('post', `/holiday/${event?._def?.publicId}`, newEvent, token);
        const { data } = response;

        if (data.statusCode === 200) {
          enqueueSnackbar(data?.message, { variant: 'success' });
          getHoliday();
        } else {
          enqueueSnackbar(data?.message, { variant: 'error' })
        }
      } else {
        const response = await AxiosAll('post', `/holiday`, newEvent, token);
        const { data } = response;

        if (data.statusCode === 200) {
          enqueueSnackbar(data?.message, { variant: 'success' });
          getHoliday();
        } else {
          enqueueSnackbar(data?.message, { variant: 'error' })
        }
      }
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    const response = await AxiosAll('delete', `/holiday/${event?._def?.publicId}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      getHoliday();
      onCancel();
    }
  };

  const values = watch();

  const isDateError = isBefore(new Date(values.end), new Date(values.start));

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="title" label="Title" disabled={disable} />

        <LabelStyle>Select type of event</LabelStyle>
        <RHFSelect name="type" disabled={disable} >
        <option value={"compensate"}>
           Compensate
          </option>
          <option value={"holiday"}>
            Holiday
          </option>
          <option value={"event"}>
            Event
          </option>
          <option value={"birthday"}>
            Birthday
          </option>
          {/* ))} */}
        </RHFSelect>


        {/* Start Date */}
        <div>
          <LabelStyle>Start Date</LabelStyle>
          <RHFTextField  min={new Date().toISOString().split('T')[0]} name="startDate" type="date" disabled={disable} />
        </div>

        {/* End Date */}
        {values.type !== 'compensate' && (
          <div>
            <LabelStyle>End Date</LabelStyle>
            <RHFTextField
              name="endDate"
              type="date"
              min={values?.startDate}
              disabled={disable}
            />
          </div>
        )}
        {/* <Controller
          name="textColor"
          control={control}
          render={({ field }) => (
            <ColorSinglePicker value={field.value} onChange={field.onChange} colors={COLOR_OPTIONS} />
          )}
        /> */}
        {isAdmin && <RHFSwitch name="allDay" label="Show All" />}
      </Stack>

      <DialogActions>
        {!isCreating && isAdmin && (
          <Tooltip title="Delete Event">
            <IconButton onClick={handleDelete}>
              <Iconify icon="eva:trash-2-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        {isAdmin && <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Add
        </LoadingButton>}
      </DialogActions>
    </FormProvider>
  );
}
