import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField, Tooltip, Typography, styled } from '@mui/material';
// components
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import AutoLinkText from 'react-autolink-text2';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import ResponsiveDateTimePickers from 'src/components/picker';
import { isObjectEmpty } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));
// ----------------------------------------------------------------------

AddMeeting.propTypes = {
    open: PropTypes.bool,
    setOpenAddMeet: PropTypes.func,
    getHoliday: PropTypes.func,
    isAdmin: PropTypes.bool,
    meetData: PropTypes.object,
    editMeet: PropTypes.bool,
};

export default function AddMeeting({ open, setOpenAddMeet, getHoliday, meetData, editMeet }) {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const { enqueueSnackbar } = useSnackbar();
    const [userDropdown, setUserDropdown] = useState([]);
    const [startDateTime, setStartDateTime] = useState(moment());
    const [endDateTime, setEndDateTime] = useState(moment());
    const idToRemove = userDetails?.user?.id;

    const NewFormSchema = Yup.object().shape({
        title: Yup.string().max(255).required('Title is required'),
        users: Yup.array().min(1, 'Users is required'),
    });
    const defaultValues = {
        title: "",
        description: '',
        startDate: '',
        endDate: '',
        users: [],
    }

    const defaultEditValues = useMemo(
        () => ({
            title: meetData?.title || "",
            description: meetData?.extendedProps?.description || '',
            startDate: '',
            endDate: '',
            users: meetData?.extendedProps?.users || [],
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [meetData]
    );
    const methods = useForm({
        resolver: yupResolver(NewFormSchema),
        defaultValues,
    });
    useEffect(() => {
        if (editMeet) {
            reset(defaultEditValues);
        }
    }, [editMeet]);

    useEffect(() => {
        if (editMeet && meetData) {
            reset(defaultEditValues);
        }
    }, [editMeet && meetData]);

    const {
        reset,
        watch,
        control,
        handleSubmit,
        setValue,
        formState: { isSubmitting },
    } = methods;
    const values = watch();

    const handleOnClose = () => {
        reset(defaultValues);
        setStartDateTime(moment());
        setEndDateTime(moment());
        setOpenAddMeet();
    }

    const onSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('name', values?.title);
            formData.append('description', values?.description);
            formData.append('start_date', startDateTime.toISOString());
            formData.append('end_date', endDateTime.toISOString());
            formData.append('user_ids[0]', idToRemove);

            values?.users.map((item, index) => formData.append(`user_ids[${index + 1}]`, item?.id));

            const response = await AxiosAll('post', '/meeting', formData, token, 'multipart/form-data');
            const { data } = response;

            if (data.statusCode === 200) {
                enqueueSnackbar(data?.message, { variant: 'success' });
                getUsersDropdownData();
                handleOnClose();
                reset();
                getHoliday();
            } else {
                enqueueSnackbar(data?.message, { variant: 'error' })
            }
        } catch (error) {
            console.error(error);
        }
    };
    const getUsersDropdownData = async () => {
        const response = await AxiosAll('get', `/common/users`, null, token);
        const { data } = response;
        const newData = data.map((item) => {
            const { full_name, ...rest } = item;
            return { ...rest, label: full_name };
        });
        setUserDropdown(newData.filter((item) => item.id !== idToRemove));
    };

    useEffect(() => {
        getUsersDropdownData();
    }, []);

    // User handle code
    useEffect(() => {
        // remove users from dropdown if already selected and add if unselected
        const newData = userDropdown?.filter((item) => {
            const found = values?.users?.find((user) => user.id === item.id);
            if (found) {
                return false;
            }
            return true;
        });
        setUserDropdown(newData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.users]);


    // On edit or opening of meeting i manually set dates and user for showing and dropdown.
    useEffect(() => {
        if (meetData) {
            const newUserData1 = meetData?.extendedProps?.users?.map((item) => ({ id: item?.id, label: `${item?.first_name} ${item?.last_name}` }));
            setValue('users', newUserData1);
            setEndDateTime(moment(meetData?.extendedProps?.end_date_time));
            setStartDateTime(moment(meetData?.extendedProps?.start_date_time));

            const newData = userDropdown?.filter((item) => {
                const found = newUserData1?.find((user) => user.id === item.id);
                if (found) {
                    return false;
                }
                return true;
            });
            setUserDropdown(newData);
        }
    }, [open])

    const handleDelete = async () => {
        const response = await AxiosAll('delete', `/meeting/${meetData?._def?.publicId}`, {}, token);
        const { data } = response;

        if (data?.statusCode === 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            getUsersDropdownData();
            handleOnClose();
            reset();
            getHoliday();
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleOnClose}
        >
            <DialogTitle>Add Meeting</DialogTitle>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>

                    <Stack spacing={3} >
                        <div>
                            <RHFTextField name="title" label='Title' />
                        </div>

                        <div>
                            {isObjectEmpty(meetData) === false ?
                                <>
                                    <LabelStyle>Meeting URL</LabelStyle>

                                    <AutoLinkText text={values?.description} style={{ cursor: 'pointer' }} />
                                </>
                                :
                                <RHFTextField name="description" label='Meeting URL' />
                            }
                        </div>

                        {/* Start Date */}

                        <ResponsiveDateTimePickers placeholder={"Select Start Date and Time"} selectedDateTime={startDateTime} setSelectedDateTime={setStartDateTime} />

                        {/* End Date */}
                        <ResponsiveDateTimePickers placeholder={"Select End Date and Time"} selectedDateTime={endDateTime} setSelectedDateTime={setEndDateTime} min={startDateTime} />

                        {/* Users */}
                        <div>
                            <LabelStyle>Users</LabelStyle>
                            <Controller
                                name="users"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field}
                                        multiple
                                        freeSolo
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        getOptionDisabled={(option) => option}
                                        options={userDropdown}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip key={option.id} label={option.label} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} />}
                                    />
                                )}
                            />
                        </div>
                    </Stack>
                </DialogContent>

                <Divider />

                <DialogActions>
                    {(editMeet && userDetails?.user?.id === meetData?.extendedProps?.creator) &&
                        <>
                            <Tooltip title="Delete Meeting">
                                <IconButton onClick={handleDelete}>
                                    <Iconify icon="eva:trash-2-outline" width={20} height={20} />
                                </IconButton>
                            </Tooltip>
                            <Box sx={{ flexGrow: 1 }} />
                        </>}

                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={handleOnClose}
                    >
                        Cancel
                    </Button>
                    {userDetails?.user?.role_id !== 2 && <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Submit
                    </LoadingButton>}
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}
