import { AnimatePresence, m } from 'framer-motion';
import { useEffect, useState } from 'react';
// @mui
import { Backdrop, Divider, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
// eslint-disable-next-line import/extensions
import { useSelector } from 'react-redux';
// utils
// eslint-disable-next-line import/extensions
import moment from 'moment';
import cssStyles from '../../utils/cssStyles';
// config
// eslint-disable-next-line import/extensions
// import { NAVBAR, defaultSettings } from ';
//
// eslint-disable-next-line import/extensions
import Iconify from '../Iconify';
// eslint-disable-next-line import/extensions
import Scrollbar from '../Scrollbar';
// eslint-disable-next-line import/extensions

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  width: window.innerWidth <= 450 ? '55vw' : '25vw',
  flexDirection: 'column',
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

export default function FilterModal({ setProjectID, project, users, setDate, dateFilter, setUserID, setPage }) {
  const [open, setOpen] = useState(false);

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const [uservalue, setUserVlaue] = useState();
  const [projectValue, setProjectValue] = useState();
  // const [taskValue, setTaskValue] = useState();
  const [date, setDueDate] = useState('')

  const handleUsers = (e) => {
    // eslint-disable-next-line no-debugger
    setUserID(e.target.value);
    setUserVlaue(e.target.value);
    setProjectID('');
    setProjectValue('');
    setDueDate('');
    setDate(dateFilter);
    setPage(0)
  };

  const handleProject = (e) => {
    // eslint-disable-next-line no-debugger
    setProjectID(e.target.value);
    setProjectValue(e.target.value);
    setPage(0);
  };

  // const handleTaskId = (e) => {
  // eslint-disable-next-line no-debugger
  //   setTaskID(e.target.value);
  //   setTaskValue(e.target.value);
  //   setPage(0);
  // };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setDueDate(e.target.value)
    setPage(0);
  };

  const handleReset = () => {
    setPage(0);
    setDate(moment(new Date()).format('YYYY-MM-DD'));
    setProjectValue('');
    setUserVlaue('');
    setProjectID("");
    setUserID("");
    setDueDate('')
  };


  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />

      {!open && (
        <Iconify
          onClick={() => setOpen(true)}
          icon={'material-symbols:filter-alt-sharp'}
          className="Filter "
          width={30}
          height={30}
          sx={{ mb: 2 }}
        />
      )}

      <AnimatePresence>
        {open && (
          <>
            <RootStyle>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  Filter Logs
                </Typography>

                <IconButton onClick={handleReset}>
                  <Iconify icon={'ic:round-refresh'} width={20} height={20} />
                </IconButton>

                <IconButton onClick={handleClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <Scrollbar sx={{ flexGrow: 1 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    {user?.roles[0]?.id === 1 ? (
                      <TextField
                        fullWidth
                        select
                        className="dropdown_container"
                        label="User"
                        defaultValue={''}
                        value={uservalue}
                        onChange={handleUsers}
                        SelectProps={{
                          MenuProps: {
                            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                          },
                        }}
                        sx={{
                          maxWidth: { sm: 240 },
                          textTransform: 'capitalize',
                        }}
                      >
                        {users &&
                          users?.map((option) => (
                            <MenuItem
                              key={option?.id}
                              value={option?.id}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                              }}
                            >
                              {option?.first_name} {option?.last_name}
                            </MenuItem>
                          ))}
                      </TextField>
                    ) : null}{' '}
                    <TextField
                      fullWidth
                      select
                      label="Project"
                      className="dropdown_container"
                      defaultValue={''}
                      onChange={handleProject}
                      value={projectValue}
                      SelectProps={{
                        MenuProps: {
                          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        },
                      }}
                      sx={{
                        maxWidth: { sm: 240 },
                        textTransform: 'capitalize',
                      }}
                    >
                      {project &&
                        project?.map((option) => (
                          <MenuItem
                            key={option?.id}
                            value={option?.id}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: 'body2',
                              textTransform: 'capitalize',
                            }}
                          >
                            {option?.title}
                          </MenuItem>
                        ))}
                    </TextField>{' '}
                    {/* <TextField
                      fullWidth
                      select
                      label="Task"
                      className="dropdown_container"
                      onChange={handleTaskId}
                      value={taskValue}
                      defaultValue={''}
                      SelectProps={{
                        MenuProps: {
                          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        },
                      }}
                      sx={{
                        maxWidth: { sm: 240 },
                        textTransform: 'capitalize',
                      }}
                    >
                      {tasks &&
                        tasks?.map((option) => (
                          <MenuItem
                            key={option?.id}
                            value={option?.id}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: 'body2',
                              textTransform: 'capitalize',
                            }}
                          >
                            {option?.title}
                          </MenuItem>
                        ))}
                    </TextField> */}
                    <div className="dropdown_container">
                      <TextField id="date" type="date" value={date} onChange={handleDateChange} />
                    </div>
                    {/* <TextField
        fullWidth
        value={filterTitle}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={`Search ${search ?? `user`}...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      /> */}
                  </Stack>
                  {/* 
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Contrast</Typography>
                    <SettingContrast />
                  </Stack>

                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Direction</Typography>
                    <SettingDirection />
                  </Stack>

                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Layout</Typography>
                    <SettingLayout />
                  </Stack>

                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Presets</Typography>
                    <SettingColorPresets />
                  </Stack>

                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Stretch</Typography>
                    <SettingStretch />
                  </Stack> */}

                  {/* <SettingFullscreen /> */}
                </Stack>
              </Scrollbar>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
