import { ALLPRIORITY, ALLSTATUS } from '../constant';

const initialState = {
    userDetails: {},
};

// eslint-disable-next-line default-param-last
const dashBoardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALLPRIORITY:
            return { ...state, userDetails: action.data };
        case ALLSTATUS:
            return { ...state, userDetails: {} };

        default:
            return state;
    }
};

export default dashBoardReducer;