import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from 'src/theme/palette';

export const PopUpLabelTag = ({ background, topLabel, labelTitle, textColor }) => (
  <div >
    <Typography variant="caption">{topLabel}</Typography>
    <div style={{ backgroundColor: background || palette.light.grey[200], padding: '4px 8px', width: 'fit-content', borderRadius: 5 }}>
      <Typography variant="body1" color={`${textColor || "text.secondary"}`}>{labelTitle}</Typography>
    </div>
  </div>
);

PopUpLabelTag.propTypes = {
  topLabel: PropTypes.string,
  labelTitle: PropTypes.string,
  background: PropTypes.string
};
