import PropTypes from 'prop-types';
// @mui
import { TableCell, TableRow, Typography } from '@mui/material';
// components

// ----------------------------------------------------------------------

DropdownRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    // onDeleteRow: PropTypes.func,
    setDeleteDialog: PropTypes.func,
    setDeleteId: PropTypes.func,
    setMultipleDeleteMode: PropTypes.func,
};

export default function DropdownRow({ row, selected, selectId }) {

    const { id } = row;

    return (
        <TableRow hover selected={selected}>

            <TableCell align="left">{id}</TableCell>
            {selectId === 'projects' && <TableCell align="center">{row?.startup_id}</TableCell>}
            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>
                    {row?.title || row?.name || row?.full_name}
                </Typography>
            </TableCell>


        </TableRow>
    );
}
