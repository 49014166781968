// import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Box, Card,
    Container,
    FormControlLabel, Switch,
    Table,
    TableBody,
    TableContainer,
    TablePagination
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';

// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import TrackerTableRow from 'src/sections/@dashboard/user/list/TrackerTableRow';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// import { _userList } from '../../_mock';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table';
// sections
import { UserTableToolbar } from '../../sections/@dashboard/user/list';
// eslint-disable-next-line import/extensions
// import ChangePassword from './dialogue/ChangePassword';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = [
    'all',
    'ux designer',
    'full stack designer',
    'backend developer',
    'project manager',
    'leader',
    'ui designer',
    'ui/ux designer',
    'front end developer',
    'full stack developer',
];

const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    // { id: 'company', label: 'Company', align: 'left' },
    { id: 'role', label: 'Role', align: 'left' },
    { id: 'team', label: 'Team', align: 'left' },
    // { id: 'isVerified', label: 'Verified', align: 'center' },
    { id: 'status', label: 'Last Log', align: 'left' },
    // { id: 'action', label: 'Action', align: 'right' },
];

// ----------------------------------------------------------------------

export default function Tracker() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setRowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { themeStretch } = useSettings();

    const navigate = useNavigate();
    const { userDetails } = useSelector((state) => state.loginReducer);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [tableData, setTableData] = useState([]);
    const [currentUserId, setCurrentUserId] = useState('');
    const [count, setCount] = useState(10);
    const [filterName, setFilterName] = useState('');
    const [filterRole, setFilterRole] = useState('all');
    // const [page, setPage] = useState(1)

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

    const { enqueueSnackbar } = useSnackbar();

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
    };

    const handleFilterRole = (event) => {
        setFilterRole(event.target.value);
        setPage(0);
    };

    const handleDeleteRows = (selected) => {
        const deleteRows = tableData.filter((row) => !selected.includes(row.id));
        setSelected([]);
        setTableData(deleteRows);
    };

    const handleEditRow = (id) => {
        navigate(`/manage-user/edit-user/${id}`);
    };

    const getUsers = async () => {
        try {
            setLoading(true);
            const res = await AxiosAll(
                'get',
                `/report?page=${page + 1}&page_count=${20}&query=${filterName}`,
                null,
                userDetails.token
            );

            const data = res?.data?.data;
            setRowsPerPage(20);
            setTableData(data);
            setCount(data?.length);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleConfirmDelete = () => {
        handleDeleteRow(deleteId);

        setDeleteDialog(false);
    };

    useEffect(() => {
        getUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterName]);

    const handleDeleteRow = async (id) => {
        try {
            const res = await AxiosAll('delete', `/users/${id}`, {}, userDetails.token);
            if (res.data.statusCode === 200) {
                enqueueSnackbar('User deleted successfully', {
                    variant: 'success',
                });
                getUsers();
            } else {
                enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handlePasswordChange = async (id) => {
        setOpen(true);
        setCurrentUserId(id);
    };

    const denseHeight = dense ? 52 : 72;

    const isNotFound =
        (!tableData?.length && !!filterName) ||
        (!tableData?.length && !!filterRole) ||
        (!tableData?.length && !!filterStatus);

    return (
        <Page title="No Task">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="User with no task"
                    links={[{ name: 'Tasks', href: '/kanban' }, { name: 'User with no task' }]}
                // action={
                //     <Button
                //         variant="contained"
                //         component={RouterLink}
                //         to={'/manage-user/create-user'}
                //         startIcon={<Iconify icon={'eva:plus-fill'} />}
                //     >
                //         New User
                //     </Button>
                // }
                />

                <Card>
                    {/* <UserTableToolbar
                        filterName={filterName}
                        filterRole={filterRole}
                        onFilterName={handleFilterName}
                        onFilterRole={handleFilterRole}
                        optionsRole={ROLE_OPTIONS}
                    /> */}
                    {/* <ConfirmDialogue
                        open={deleteDialog}
                        loading={loading}
                        onClose={() => setDeleteDialog(false)}
                        onConfirm={handleConfirmDelete}
                        title={`Are you sure you want to delete this user?`}
                        content="This action cannot be undone. Please confirm to proceed."
                    /> */}

                    {/* <ChangePassword open={open} onClose={() => setOpen(false)} id={currentUserId} /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>

                            <Table size={dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    hideCheckbox
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData?.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />

                                {loading ? (
                                    <TableSkeleton />
                                ) : (
                                    <TableBody>
                                        {tableData?.map((row) => (
                                            <TrackerTableRow
                                                key={row.id}
                                                row={row}
                                                setDeleteDialog={setDeleteDialog}
                                                setDeleteId={setDeleteId}
                                                handlePasswordChange={handlePasswordChange}
                                                selected={selected.includes(row.id)}
                                                onSelectRow={() => onSelectRow(row.id)}
                                                onDeleteRow={() => handleDeleteRow(row.id)}
                                                onEditRow={() => handleEditRow(row.id)}
                                            />
                                        ))}

                                        {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                                        <TableNoData isNotFound={isNotFound} />
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[20]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label="Dense"
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page>
    );
}