import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import { useNavigate } from 'react-router';
import moment from 'moment';

// ----------------------------------------------------------------------

TableRowReportView.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  // onDeleteRow: PropTypes.func,
  setDeleteDialog: PropTypes.func,
  setDeleteId: PropTypes.func,
  setMultipleDeleteMode: PropTypes.func,
};

export default function TableRowReportView({
  row,
  selected,
  onEditRow,
  onSelectRow,
  setMultipleDeleteMode,
  // onDeleteRow,
  // onAddUser,
  // onAddManager,
  setDeleteDialog,
  setDeleteId,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { title } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
    setMultipleDeleteMode(false);
  };

  function handleReformType(type) {
    return type?.replace('_', ' ')?.replace(/\w\S*/g, (w) => w?.replace(/^\w/, (c) => c?.toUpperCase()));
  }

  return (
    <TableRow hover={row?.task?.id} sx={{ cursor: row?.task?.id && 'pointer' }}>
      <TableCell
        onClick={() => row?.task?.id && window.open(`/tasks/details/${row?.task?.id}`, '_blank')}
        align="left"
        style={{ whiteSpace: 'nowrap' }}
      >
        {row?.task?.title || handleReformType(row?.type) || 'Discussion'}
      </TableCell>
      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
        {row?.user?.first_name} {row?.user?.last_name}
      </TableCell>
      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
        {moment(row?.date).format('DD-MM-YYYY')}
      </TableCell>
      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
        {row?.time_format}
      </TableCell>
    </TableRow>
  );
}
