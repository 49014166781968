import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
// utils
import { fData } from '../../../utils/formatNumber';
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import Label from '../../../components/Label';
import { addLogin } from '../../../redux/actions/login';

// ----------------------------------------------------------------------

ProfileNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function ProfileNewEditForm({ isEdit, currentUser }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);
  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]*$/, 'First name should only contain alphabet')
      .required('First name is required'),
    lastName: Yup.string()
      .matches(/^[A-Za-z]*$/, 'Last name should only contain alphabet')
      .required('Last name is required'),
    email: Yup.string().required('Email is required').email(),
    position: Yup.string().required('Position is required'),
    // team: Yup.array().min(1, 'Please select at least one team'),
    // role: Yup.array().min(1, 'Please select at least one role'),
    avatarUrl: Yup.mixed().test('required', 'Avatar is required', (value) => value !== ''),
  });

  const defaultValues = useMemo(
    () => ({
      firstName: currentUser?.first_name || '',
      lastName: currentUser?.last_name || '',
      email: currentUser?.email || '',
      position: currentUser?.position || '',
      // team: currentUser?.team || [],
      // role: currentUser?.role || [],
      avatarUrl: currentUser?.profile_picture || '',
      status: currentUser?.status || 'active',
      profile_picture: currentUser?.profile_picture || '',
      date_of_birth: moment(currentUser?.dob).format('yyyy-MM-DD') || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  //   const date = new Date();
  // const minDate = date.setFullYear(currentDate.getFullYear() - 18)

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('first_name', values.firstName);
      formData.append('last_name', values.lastName);
      formData.append('email', values.email);
      formData.append('position', values.position);
      formData.append('dob', values.date_of_birth);
      if (values.profile_picture === values.avatarUrl) {
        formData.append('profile_picture', '');
      } else {
        formData.append('profile_picture', values.avatarUrl);
      }

      const res = await AxiosAll('post', `/update-profile`, formData, userDetails.token, 'multipart/form-data');
      const { data } = res;
      if (data.statusCode === 200) {
        await dispatch(
          addLogin({
            ...userDetails,
            user: {
              ...userDetails.user,
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              position: values.position,
            },
          })
        );

        if (values.profile_picture !== values.avatarUrl) {
          await dispatch(
            addLogin({
              ...userDetails,
              user: {
                ...userDetails.user,
                profile_picture: URL.createObjectURL(values.avatarUrl),
              },
            })
          );
        }

        enqueueSnackbar(data.message, { variant: 'success' });
        navigate('/profile');
      } else {
        enqueueSnackbar(data.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            {isEdit && (
              <Label
                color={values.status !== 'active' ? 'error' : 'success'}
                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
              >
                {values.status}
              </Label>
            )}

            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <div>
                <LabelStyle>First Name</LabelStyle>
                <RHFTextField name="firstName" />
              </div>
              <div>
                <LabelStyle>Last Name</LabelStyle>
                <RHFTextField name="lastName" />
              </div>
              {/* <div>
                <LabelStyle>Email</LabelStyle>
                <RHFTextField disabled={true} name="email" />
              </div> */}
              <div>
                <LabelStyle>Position</LabelStyle>
                <RHFTextField name="position" />
              </div>
              <div>
                <LabelStyle> Date of birth</LabelStyle>
                <RHFTextField max={maxDate.toISOString().split('T')[0]} name="date_of_birth" type="date" />
              </div>
            </Box>

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save Changes
              </LoadingButton>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={() => {
                  navigate('/profile');
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
