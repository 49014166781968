import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container, FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
  TableSkeleton
} from '../../components/table';
// sections
import { StartupTableRow, StartupTableToolbar } from '../../sections/@dashboard/startup/list';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Startup Title', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'startDate', label: 'Start Date', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------

export default function StartupList() {
  const {
    dense,
    page,
    order,
    setPage,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const { enqueueSnackbar } = useSnackbar();

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    const response = await AxiosAll('delete', `/startups/${id}`, {}, token);
    const { data } = response;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      if (selected.length > 0) {
        setSelected([]);
      }
      getStartups();
    } else {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const handleDeleteRows = async (selected) => {
    const response = await AxiosAll('delete', `/startups`, { ids: selected }, token);
    const { data } = response;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      setSelected([]);
      getStartups();
    } else {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const getStartups = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/startups?page=${page + 1}&query=${filterName}`, {}, token);
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      setRowsPerPage(data?.data?.per_page);
      setTableData(data?.data?.data);
      setCount(data?.data?.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStartups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterName]);

  const handleConfirmDelete = () => {
    if (selected.length > 0 && multipleDeleteMode) {
      handleDeleteRows(selected);
    } else {
      handleDeleteRow(deleteId);
    }
    setDeleteDialog(false);
  };

  const handleEditRow = (id) => {
    navigate(`/startup/edit/${id}`);
  };

  // const dataFiltered = applySortFilter({
  //   tableData,
  //   comparator: getComparator(order, orderBy),
  //   filterName,
  //   filterRole,
  //   filterStatus,
  // });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData?.length && !!filterName) ||
    (!tableData?.length && !!filterRole) ||
    (!tableData?.length && !!filterStatus);

  return (
    <Page title="Startup: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Startup List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'List' }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={'/startup/create-startup'}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New startup
            </Button>
          }
        />

        <Card>
          <ConfirmDialogue
            open={deleteDialog}
            loading={loading}
            onClose={() => setDeleteDialog(false)}
            onConfirm={handleConfirmDelete}
            title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these startups' : 'this startup'
              }?`}
            content="This action cannot be undone. Please confirm to proceed."
          />

          <StartupTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
          // optionsRole={ROLE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setDeleteDialog(true);
                          setMultipleDeleteMode(true);
                        }}
                      >
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <StartupTableRow
                        key={row.id}
                        row={row}
                        setDeleteDialog={setDeleteDialog}
                        setMultipleDeleteMode={setMultipleDeleteMode}
                        setDeleteId={setDeleteId}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        // onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                      />
                    ))}

                    {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

// function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
//   const stabilizedThis = tableData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   tableData = stabilizedThis.map((el) => el[0]);

//   if (filterName) {
//     tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
//   }

//   if (filterRole !== 'all') {
//     tableData = tableData.filter((item) => item.description === filterRole);
//   }

//   if (filterStatus !== 'all') {
//     tableData = tableData.filter((item) => item.status === filterStatus);
//   }

//   return tableData;
// }
