// @mui
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// layouts
// eslint-disable-next-line import/no-unresolved
import NewPasswordForm from 'src/sections/auth/new-password';
// components
// sections
// assets
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import { SentIcon } from '../../assets';
import Page from '../../components/Page';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  // maxWidth:'33.33vw',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NewPassword() {
  const location = useLocation();
  const nav = useNavigate();

  const [validToken, setValidToken] = useState(false);

  const token = location.search.split('=')[1];



  return (
    <Page title="New Password" className="newpassword">
      <LogoOnlyLayout />

      <Container>
        <ContentStyle sx={{ textAlign: 'center' }} className="widthcontainer container-style">
          <SentIcon sx={{ mb: 5, mx: 'auto', height: 120 }} />

          <Typography variant="h3" gutterBottom className="main_heading">
            Create a new password
          </Typography>



          <Box sx={{ mt: 5, mb: 3 }}>
            <NewPasswordForm token={token} />
          </Box>

        </ContentStyle>
      </Container>
    </Page>
  );
}
