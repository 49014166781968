/* eslint-disable import/no-unresolved */
import { Grid } from '@mui/material';
import React from 'react';
import { imagesAll } from 'src/assets/Images_index';
import Image from 'src/components/Image';
import { OldSocialLogin as SocialLogin } from 'react-social-login';

const SocialLoginComponent = () => {
  // feild social login func
  // feild social login func
  const handleSocialLogin = (data) => {
    console.error(data);
  };

  const authHandler = (err, data) => {
    console.log(err, data);
  };

  return (
    <>
      <Grid container spacing={0} className="social-section">
        <Grid item xs={4}>
          <SocialLogin
            provider="google"
            appId="692998375865-79upffd61s7jb48jm8asivk218d6ivqh.apps.googleusercontent.com"
            callback={handleSocialLogin}
          >
            <div className="social-box">
              {/* test */}
              <Image src={imagesAll.google} className="social_icon" />
            </div>
          </SocialLogin>
        </Grid>
        <Grid item xs={4}>
          <SocialLogin provider="facebook" appId="3168780090025701" callback={handleSocialLogin}>
            <div className="social-box">
              {' '}
              <Image src={imagesAll.facebook} className="social_icon" />
            </div>
          </SocialLogin>
        </Grid>
        {/* <Grid item xs={4}>
          <TwitterLogin
            authCallback={authHandler}
            consumerKey={'lzIbdUWa08TLtjeoUSCQwNOjV'}
            consumerSecret={'oqnFVX0waclk47cskj3Yz060G0Er5fYU7932h4OdmxSpnqDvWO'}
          >
            <div className="social-box">
              <Image src={imagesAll.twiter} className="social_icon" />
            </div>
          </TwitterLogin>
        </Grid> */}
      </Grid>
      <div className="only_social_section">
        <fieldset>
          <legend>OR</legend>
        </fieldset>
      </div>
    </>
  );
};

export default SocialLoginComponent;
