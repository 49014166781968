/* eslint-disable no-useless-escape */
/* eslint-disable import/order */
import { Box, Button, TextField, Typography } from '@mui/material';
import { PopUpNormalBtn } from './popUpNormalBtn';
import AxiosAll from 'src/services/axiosAll';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

export const PopUpCommentLabel = ({
  title,
  color,
  paddY,
  paddX,
  labelRadius,
  taskCommentId,
  spanColor,
  mentionName,
  getTaskComments,
  loginUserId,
  auto_comment,
}) => {
  const mainPadding = `${paddY}px ${paddX}px`;
  const { userDetails  ,userList} = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const [updateCommentInput, setUpdateCommentInput] = useState(false);
  const [updateComment, setUpdateCoomment] = useState();
  const [userData, setUserData] = useState([]);

  const DeleteComments = async () => {
    const res = await AxiosAll('delete', `/task-comments/comment/${taskCommentId}`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data?.data;
      console.log('success', res);
      getTaskComments();
    } else {
      console.log('error', res);
    }
  };

  const handleUpdateComment = async () => {
    const data = {
      comment: updateComment,
    };
    const res = await AxiosAll('post', `/task-comments/comment/${taskCommentId}`, data, token);
    if (res?.status === 200) {
      setUpdateCommentInput(false);
      setUpdateCoomment('');
      getTaskComments();
    } else {
      console.log('error', res);
    }
  };

  const getUser = async () => {
    const res = await AxiosAll('get', `/common/users`, {}, token);
    if (res?.status === 200) {
      const mentionableNames = res.data.map((person) => ({
        id: person.id,
        display: person.full_name,
      }));
      const newData = res?.data;
      setUserData(mentionableNames);
    }
  };

  useEffect(() => {
    getUser();
  }, [updateCommentInput]);

  const onChange = (e) => {
    if (updateComment?.length !== 0) {
      setUpdateCoomment(e.target.value);
    }
  };

  const handleEdit = () => {
    setUpdateCommentInput(true);
    setUpdateCoomment(title);
  };

  const handleCancleEditComment = () => {
    setUpdateCommentInput(false);
    setUpdateCoomment('');
  };

  const mentionRegex = /@\[([^\]]+)\]\(\d+\)/g;
  const mentionedUsers = [];

  // Replace mentions with corresponding usernames and store them
  const replacedTitle = title.replace(mentionRegex, (_, username) => {
    mentionedUsers.push(username);
    return `@@${username}@@`;
  });

  // Replace '\n' with <br> for line breaks
  const withLineBreaks = replacedTitle.replace(/\n/g, '<br>');

  // Split the text based on @@
  const parts = withLineBreaks.split('@@');

  const mentionIndex = 0;

  const renderSuggestion = (suggestion, search, highlightedDisplay, index, focused) => (
    <li
      key={suggestion.id}
      className={`mention ${focused ? 'focused' : ''}`}
      style={{ borderBottom: '1px solid #000' }}
    >
      <span>{highlightedDisplay}</span>
    </li>
  );

  return (
    <div style={{ width: '100%' }}>
      {updateCommentInput ? (
        <div
          className="edit-input-pop-up"
          style={{ display: 'flex', gap: 8, alignItems: 'start', flexDirection: 'column', width: '100%' }}
        >
          <MentionsInput
            className="mentions__suggestions__list"
            value={updateComment}
            onChange={onChange}
            style={{
              suggestions: {
                height: '300px',
                overflowY: 'auto',
                borderRadius: 8,
                boxShadow: '2px 3px 5px rgba(0,0,0,0.5)',
                padding: 12,
                borderBottom: 1,
              },
            }}
            renderSuggestion={renderSuggestion}
          >
            <Mention
              data={userData}
              renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', padding: '5px 0' }}>
                  {highlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>
          <div style={{ display: 'flex', gap: 10 }}>
            <Button variant="contained" onClick={handleCancleEditComment}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleUpdateComment}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Box
            sx={{ bgcolor: 'background.neutral' }}
            style={{
              // backgroundColor: color,
              padding: mainPadding,
              borderRadius: labelRadius,
              display: 'flex',
              gap: 4,
              alignItems: 'start',
              width: '100%',
            }}
          >
            <Box
              sx={{ bgcolor: 'background.span' }}
              style={{
                // backgroundColor: spanColor,
                borderRadius: labelRadius,
                padding: '10px 4px',
                marginRight: 4,
                marginTop: 2,
              }}
            />
            <div>
              {parts.map((part, index) => (
                <React.Fragment key={index}>
                  {index % 2 === 0 ? (
                    // This part contains normal text
                    <span dangerouslySetInnerHTML={{ __html: part }} />
                  ) : (
                    // This part contains a mention
                    <Typography
                      sx={{ bgcolor: 'background.span', display: 'inline', color: 'text.span' }}
                      style={{
                        // background: spanColor,
                        borderRadius: labelRadius,
                        padding: '2px 4px',
                        marginRight: 4,
                        fontWeight: 900,
                      }}
                    >
                      {`@${mentionedUsers.shift()} `}
                    </Typography>
                  )}
                </React.Fragment>
              ))}
              {/* Your existing JSX for rendering other content */}
            </div>
          </Box>
          {loginUserId === user?.id && auto_comment === false && (
            <div style={{ display: 'flex', gap: 8, marginTop: 8 }}>
              <PopUpNormalBtn title="edit" onClick={handleEdit} />
              <PopUpNormalBtn title="delete" onClick={DeleteComments} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
