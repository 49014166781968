import { Button } from '@mui/material';
import React from 'react';

export const PopUpNormalBtn = ({ title, onClick }) => (
  <Button
    type="button"
    onClick={onClick}
    sx={{
      bgcolor: 'transparent',
      border: 'none',
      textDecoration: 'underline',
      p: 0,
      width: 'fit-content',
      minWidth: 'fit-content',
      justifyContent: 'start'
    }}
    // style={{
    //   backgroundColor: 'transparent',
    //   border: 'none',
    //   textDecoration: 'underline',
    //   fontSize: 14,
    //   cursor: 'pointer',
    // }}
  >
    {title}
  </Button>
);
