/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import MultipleSelectBox from 'src/components/MultiSelect/multiselect';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { imagesAll } from 'src/assets/Images_index';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import { SkeletonProjectTab } from 'src/components/skeleton';
import { TaskStatusTableToolbar } from 'src/sections/@dashboard/task_status/list';
import AxiosAll from 'src/services/axiosAll';
import ReportDetail from '../today/reportDetail';
import SideAddTask from './AddTaskSideCard';

export default function ProgressCardTask({ page, setPage }) {
  const theme = useTheme();
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [search, setSearch] = useSearchParams();
  const [userId, setUserId] = useState(search.get('user_id') || '');
  const [statusId, setStatusId] = useState(search.get('status_id') || '2');
  const [userData, setUserData] = useState({});
  const [userDropdownData, setUserDropdownData] = useState([]);
  const [statusDropdownData, setStatusDropdownData] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [age, setAge] = React.useState('');
  const [personName, setPersonName] = React.useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const statusDropdown = [
    {
      id: 1,
      title: 'Not Started',
    },
    {
      id: 2,
      title: 'In Progress',
    },
    {
      id: 3,
      title: 'On Hold',
    },
    {
      id: 4,
      title: 'In Review',
    },
    {
      id: 5,
      title: 'Completed',
    },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const getUserWiseData = async () => {
    setLoading(true);
    const response = await AxiosAll(
      'get',
      `/users-tasks/v2?statuses=${search.get('status_id') || statusId}&userId=${search.get('user_id') || userId}`,
      {},
      token
    );
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      setTableData(data?.data);
      setLoading(false);
    }
  };

  const CurrentDate = new Date().toISOString().split('T')[0];
  const loginDate = localStorage.getItem('loginDate');
  const usersData = JSON.parse(localStorage.getItem('usersDropdownData'));

  const getUsersDropdownData = async () => {
    if (loginDate === CurrentDate && usersData) {
      setUserDropdownData(usersData);
      console.log('Data retrieved from local storage:', usersData);
    } else {
      try {
        const response = await AxiosAll('get', `/common/users`, null, token);
        const { data } = response;
        const newData = data.map((item) => {
          const { first_name, last_name, ...rest } = item;
          return { ...rest, label: `${first_name} ${last_name}` };
        });

        localStorage.setItem('usersDropdownData', JSON.stringify(newData));
        localStorage.setItem('loginDate', CurrentDate);

        setUserDropdownData(newData);
        console.log('Data from API and stored in local storage:', newData);
      } catch (error) {
        console.error('Error fetching users dropdown data:', error);
      }
    }
  };

  // const getStatusDropdownData = async () => {
  //   setLoading(true);
  //   const response = await AxiosAll('get', `/task-statuses?page=${page + 1}&query=${filterName}`, {}, token);
  //   const { data } = response;
  //   window.scrollTo(0, 0);
  //   if (data?.statusCode === 200) {
  //     setStatusDropdownData(data?.data?.data);
  //     setLoading(false);
  //   }
  // };

  const storedData = JSON.parse(localStorage.getItem('statusDropdownData'));
  const getStatusDropdownData = async () => {
    setLoading(true);
    if (loginDate === CurrentDate && storedData) {
      setStatusDropdownData(storedData);
      setLoading(false);
    } else {
      try {
        const response = await AxiosAll('get', `/task-statuses?page=${page + 1}&query=${filterName}`, {}, token);
        const { data } = response;
        window.scrollTo(0, 0);

        if (data?.statusCode === 200) {
          localStorage.setItem('statusDropdownData', JSON.stringify(data?.data?.data));
          localStorage.setItem('loginDate', CurrentDate);

          setStatusDropdownData(data?.data?.data);
        }
      } catch (error) {
        console.error('Error fetching status dropdown data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Call getUsersDropdownData once on component mount
    getUsersDropdownData();
    getStatusDropdownData();
  }, []);

  useEffect(() => {
    if (!statusId) {
      setStatusId('2');
    }
    getUserWiseData();
    // getUsersDropdownData();
  }, [userId, statusId]);

  const handleUsers = (e) => {
    // eslint-disable-next-line no-debugger
    navigate(`${location.pathname}?user_id=${e?.target?.value}&status_id=${statusId}`);
    setUserId(e.target.value);
  };

  const handleStatus = (e) => {
    // eslint-disable-next-line no-debugger
    navigate(`${location.pathname}?user_id=${userId}&status_id=${e?.target?.value}`);
    setStatusId(e.target.value);

    const {
      target: { value },
    } = e;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div className="task-card-section">
      <ReportDetail idToOpen={id} setOpen={setOpen} open={open} />
      <SideAddTask userData={userData} setOpen={setSidebarOpen} open={sidebarOpen} getUserWiseData={getUserWiseData} />
      <Box className="progresscard_text">
        <Stack
          spacing={2}
          className="stack-center mom_margin"
          flexWrap="wrap"
          gap={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: { xs: 3, sm: 0 } }}
        >
          {/* User wise */}
          <TextField
            fullWidth
            select
            label="User"
            value={userId}
            onChange={handleUsers}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key={''}
              value={''}
            >
              Select
            </MenuItem>
            {userDropdownData?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.label}
              </MenuItem>
            ))}
          </TextField>
          {/* Status wise */}
          {/* TODO MultipleSelectBox  */}
          {/* <MultipleSelectBox data={statusDropdown} /> */}
          {/* Status wise */}
          <TextField
            fullWidth
            select
            label="Status"
            value={statusId}
            onChange={handleStatus}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key={''}
              value={''}
            >
              Select
            </MenuItem>
            {statusDropdownData?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.name}
              </MenuItem>
            ))}
          </TextField>{' '}
        </Stack>
      </Box>
      {loading ? (
        <>
          {' '}
          <SkeletonProjectTab />
          <SkeletonProjectTab />
          <SkeletonProjectTab />
        </>
      ) : (
        tableData?.map((item, index) => (
          <Paper variant="outlined" className="task-card progresscard" sx={{ p: 2, bgcolor: 'grey.5008' }} key={index}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">{item?.full_name}</Typography>
              <Avatar alt={'Rahul Nagarwal'} src={item?.profile_picture || imagesAll.mainWhiteLogo} sx={{ mr: 2 }} />
              <div
                onClick={() => {
                  setUserData(item);
                  setSidebarOpen(true);
                }}
              >
                <Iconify
                  icon={'ic:baseline-plus'}
                  sx={{ cursor: 'Pointer' }}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </Box>

            <Box sx={{ height: '420px', overflowY: 'auto' }} className="box-height-overflow">
              {/* Tasks Card */}
              {item?.assigned_tasks?.map((cardData, index) => (
                //         <Card sx={{ p: 1.5, mt: 1,
                //           backgroundColor: cardData?.is_due? 'error.light' : '#fff'
                //         // backgroundColor: cardData?.task_status?.id?.color
                // }}

                //         key={index} >
                <Card
                  sx={{
                    p: 1.5,
                    mt: 1,
                    backgroundColor: (() => {
                      switch (cardData?.task_status?.id) {
                        case 1:
                          return 'skyblue';
                        case 2:
                          return ' rgba(255, 193, 7, 0.16)';
                        case 3:
                          return 'pink';
                        case 4:
                          return 'rgba(51, 102, 255, 0.16)';
                        case 5:
                          return '#C8FACD';
                        default:
                          return cardData?.is_due ? 'error.light' : '#fff';
                      }
                    })(),
                    // color:'white',
                  }}
                  key={index}
                >
                  <Grid container spacing={3}>
                    ,{/* title and name */}
                    <Grid item xs={10} md={10}>
                      <Typography variant="overline" sx={{ textTransform: 'initial' }}>
                        {cardData?.task_users?.full_name}
                      </Typography>

                      {cardData?.task_priority?.id === 1 && cardData?.task_status?.id !== 5 ? (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={'error'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      ) : cardData?.is_due ? (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={'primary'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                            onClick={() => console.log('from 2', cardData?.id)}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                            onClick={() => console.log('from 3', cardData?.id)}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  {/* Time */}
                  <Box
                    Box
                    sx={{
                      display: 'flex',
                      rowGap: 2,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                      Alloted Time
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.planned_time_format}
                    </Typography>
                    <Typography variant="overline" sx={{ mb: 0.5, ml: 1, textTransform: 'initial' }}>
                      Spend Time
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.spend_time_format}
                    </Typography>
                  </Box>

                  {/* Dates */}
                  <Box
                    sx={{
                      display: 'flex',
                      rowGap: 2,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                      Start Date
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {moment(cardData?.start_date).format('D MMM YYYY')}
                    </Typography>
                    <Typography variant="overline" sx={{ mb: 0.5, ml: 1, textTransform: 'initial' }}>
                      Due Date
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {moment(cardData?.due_date).format('D MMM YYYY')}
                    </Typography>
                  </Box>

                  {/* Status and priority */}
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                      justifyContent: 'space-between',
                      justifyItems: 'center',
                      alignItems: 'flex-start',
                      '& > *:first-child': {
                        alignSelf: 'flex-start',
                      },
                      '& > *:last-child': {
                        alignSelf: 'flex-end',
                      },
                    }}
                  >
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={cardData?.task_priority?.color}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    >
                      {cardData?.task_priority?.name}
                    </Label>
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={cardData?.task_status?.color}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    >
                      {cardData?.task_status?.name}
                    </Label>
                  </Box>
                </Card>
              ))}
            </Box>
          </Paper>
        ))
      )}
    </div>
  );
}
