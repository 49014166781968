/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { Avatar, Box, Button, Card, Grid, Paper, Typography, useTheme } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { imagesAll } from 'src/assets/Images_index';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import { SkeletonProjectTab } from 'src/components/skeleton';
import AxiosAll from 'src/services/axiosAll';
import ReportDetail from '../today/reportDetail';
import SideAddTask from './AddTaskSideCard';

export default function UserCard() {
  const theme = useTheme();
  const [tableData, setTableData] = useState([]);

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [userData, setUserData] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const getUserWiseData = async () => {
    setLoading(true);
    const response = await AxiosAll('get', `/users-tasks`, {}, token);
    const { data } = response;
    window.scrollTo(0, 0);

    if (data?.statusCode === 200) {
      setTableData(data?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserWiseData();
  }, []);

  return (
    <div className="task-card-section"  >
      <ReportDetail idToOpen={id} setOpen={setOpen} open={open} />
      <SideAddTask userData={userData} setOpen={setSidebarOpen} open={sidebarOpen} getUserWiseData={getUserWiseData} />

      {loading ? (
        <>
          {' '}
          <SkeletonProjectTab />
          <SkeletonProjectTab />
          <SkeletonProjectTab />
        </>
      ) : (
        tableData?.map((item, index) => (
          <Paper variant="outlined" className="task-card" sx={{ p: 2, bgcolor: 'grey.5008' }} key={index} >
          
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">{item?.full_name}</Typography>
              <Avatar alt={'Rahul Nagarwal'} src={item?.profile_picture || imagesAll.mainWhiteLogo} sx={{ mr: 2 }} />
              <div
                onClick={() => {
                  setUserData(item);
                  setSidebarOpen(true);
                  }}
              >
                <Iconify
                  icon={'ic:baseline-plus'}
                  sx={{ cursor: 'Pointer' }}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </Box>
            
            
            <Box sx={{ height: '420px', overflowY: 'auto' }} className="box-height-overflow">
              {/* Tasks Card */}
              {item?.assigned_tasks?.map((cardData, index) => (
                <Card sx={{ p: 1.5, mt: 1, backgroundColor: cardData?.is_due ? 'error.light' : '#fff' }} key={index} onClick={()=>
                {setId(cardData?.id);
                  setOpen(true);}
                }>
                  <Grid container spacing={3}>
                    {/* title and name */}
                    <Grid item xs={10} md={10}>
                      <Typography variant="overline" sx={{ textTransform: 'initial' }}>
                        {cardData?.task_users?.full_name}
                      </Typography>

                      {cardData?.task_priority?.id === 1 && cardData?.task_status?.id !== 5 ? (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={'error'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      ) : cardData?.is_due ? (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={'primary'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                            onClick={() => console.log('from 2', cardData?.id)}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setId(cardData?.id);
                            setOpen(true);
                          }}
                        >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            sx={{
                              textTransform: 'capitalize',
                              height: 'auto',
                              whiteSpace: 'normal',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                            }}
                            onClick={() => console.log('from 3', cardData?.id)}
                          >
                            {cardData?.title}
                          </Label>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  {/* Time */}
                  <Box
                    Box
                    sx={{
                      display: 'flex',
                      rowGap: 2,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                      Alloted Time
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.planned_time_format}
                    </Typography>
                    <Typography variant="overline" sx={{ mb: 0.5, ml: 1, textTransform: 'initial' }}>
                      Spend Time
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.spend_time_format}
                    </Typography>
                    
                    
                    
                  </Box>

                  {/* Dates */}
                  <Box
                    sx={{
                      display: 'flex',
                      rowGap: 2,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <Typography variant="overline" sx={{ mb: 0.5, textTransform: 'initial' }}>
                      Start Date
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {moment(cardData?.start_date).format('D MMM YYYY')}
                    </Typography>
                    <Typography variant="overline" sx={{ mb: 0.5, ml: 1, textTransform: 'initial' }}>
                      Due Date
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {moment(cardData?.due_date).format('D MMM YYYY')}
                    </Typography>
                  </Box>
                    <Box 
                      sx={{
                        display: 'flex',
                        rowGap: 2,
                        columnGap: 1,
                        gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                      }}
                    >
                    <Typography variant="overline" sx={{ mb: 0.5,  textTransform: 'initial' }}>
                      Ticket:
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {cardData?.ticket}
                    </Typography>
                    </Box>
                   

                  {/* Status and priority */}
                  <Box
                    sx={{
                      display: 'flex',
                      gap:'10px',
                      // gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)' },
                      // justifyContent: 'space-between',
                      // justifyItems: 'center',
                      // alignItems: 'flex-start',
                      '& > *:first-child': {
                        alignSelf: 'flex-start',
                      },
                      '& > *:last-child': {
                        alignSelf: 'flex-end',
                      },
                    }}
                  >
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={cardData?.task_priority?.color}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    >
                      {cardData?.task_priority?.name}
                    </Label>
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={cardData?.task_status?.color}
                      sx={{
                        textTransform: 'capitalize',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    >
                      {cardData?.task_status?.name}
                    </Label>
                  </Box>
                </Card>
              ))}
            </Box>
          </Paper>
        ))
      )}
    </div>
  );
}
