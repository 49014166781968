/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import { Avatar, CardContent, Dialog, DialogContent, DialogTitle, List, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import useSettings from 'src/hooks/useSettings';
import AxiosAll from 'src/services/axiosAll';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

LogList.propTypes = {
    listopen: PropTypes.bool,
    setListopen: PropTypes.func,
    clickedDate: PropTypes.string,
};

export default function LogList({ listopen, setListopen, clickedDate, user, project }) {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const [log, setLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalTime, setTotalTime] = useState("00:00")
    const { setColor } = useSettings();
    const handleOnClose = () => {
        setListopen(false)
    }
    const limit = 1000;
    const getLogDetail = async () => {
        const response = await AxiosAll('get', `/task-time-logs?type=team_log&user_id=${user}&project_id=${project}&date=${clickedDate}&limit=${limit}`, null, userDetails.token);
        const { data } = response;
        await setLog(data?.data?.data);
        await setLoading(true);
        await setTotalTime(data?.data?.total_time)
        return {};
    };

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        listopen && getLogDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickedDate, listopen]);
    return (
        <Dialog fullWidth maxWidth="sm" open={listopen} onClose={handleOnClose}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', pt: 2, justifyContent: 'space-around' }}>
                <ListSubheader sx={{ py: 1, px: 2.5, typography: 'h5', color: setColor?.main }}>
                    {moment(clickedDate).format("DD-MM-YYYY")}
                </ListSubheader>
                {userDetails?.user?.id !== 1 && <Typography variant='body1' >Time: <span>{totalTime || "00:00"}</span></Typography>}
            </DialogTitle>
            {loading && <DialogContent >
                <CardContent sx={{ pt: 0 }}>

                    <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                        <List>
                            {log?.map((notification) => (
                                <NotificationItem key={notification?.id} item={notification} />
                            ))}
                        </List>
                    </Scrollbar>
                </CardContent>
            </DialogContent>}

        </Dialog>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        user: PropTypes.any,
        task: PropTypes.any,
        type: PropTypes.string,
        start_time: PropTypes.string,
        end_time: PropTypes.string,
    }),
};

function NotificationItem({ item }) {
    const navigate = useNavigate();
    return (
        <ListItemButton
            disabled={!item?.task}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...({
                    bgcolor: 'action.selected',
                }),
            }}
            onClick={() => navigate(`/tasks/details/${item?.task?.id}`)}
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.neutral' }} src={item?.user?.profile_picture} />
            </ListItemAvatar>
            <ListItemText
                primary={item?.task?.title || item?.type.replace("_", " ")}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{ mt: 0.5, }}>
                        <Typography variant="caption" >
                            {item?.task?.project?.title}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='alloted-time'>
                                <Typography variant="subtitle2" sx={{ mr: 0.5 }}>
                                    Start Time
                                </Typography>
                                <Iconify icon="eva:clock-outline" sx={{ mr: 1, width: 16, height: 16, }} />
                                <Typography variant="subtitle2">  {item?.start_time} </Typography>
                            </div>
                            <div className='alloted-time'>
                                <Typography variant="subtitle2" sx={{ mr: 0.5 }}>
                                    End Time
                                </Typography>
                                <Iconify icon="eva:clock-outline" sx={{ mr: 1, width: 16, height: 16 }} />
                                <Typography variant="subtitle2"> {item?.end_time}</Typography>
                            </div>

                        </div>
                    </Typography>
                }
            />
        </ListItemButton>
    );
}
