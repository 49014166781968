import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Avatar, Checkbox, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

TaskStatusTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  // onDeleteRow: PropTypes.func,
  getItemStyle: PropTypes.func,
  setDeleteDialog: PropTypes.func,
  setDeleteId: PropTypes.func,
  setMultipleDeleteMode: PropTypes.func,
};

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export default function TaskStatusTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  setMultipleDeleteMode,
  // onDeleteRow,
  getItemStyle,
  setDeleteDialog,
  setDeleteId,
}) {
  const theme = useTheme();

  const { name } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
    setMultipleDeleteMode(false);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell align="center">{name}</TableCell>

      <TableCell align="center">
        <Label color={row?.color} variant="ghost">
          {row?.color}
        </Label>
      </TableCell>

      <TableCell align="center">
        <IconWrapperStyle
          sx={{
            color: (theme) => theme.palette[row?.color].dark,
            backgroundImage: (theme) =>
              `linear-gradient(135deg, ${alpha(theme.palette[row?.color].dark, 0)} 0%, ${alpha(
                theme.palette[row?.color].dark,
                0.24
              )} 100%)`,
          }}
        >
          <Iconify icon={row?.icon} width={24} height={24} />
        </IconWrapperStyle>
      </TableCell>

      {/* <TableCell align="center">
        {!row?.is_default && (
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    // onDeleteRow();
                    setDeleteDialog(true);
                    setDeleteId(row.id);
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              </>
            }
          />
        )}
      </TableCell> */}
    </TableRow>
  );
}
