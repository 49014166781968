import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Avatar, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  // onDeleteRow: PropTypes.func,
  setDeleteDialog: PropTypes.func,
  setDeleteId: PropTypes.func,
  handlePasswordChange: PropTypes.func,
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  handlePasswordChange,
  // onDeleteRow,
  setDeleteDialog,
  setDeleteId,
}) {
  const theme = useTheme();

  const { first_name, last_name, profile_picture, position, working_hours, roles, teams } = row;
  const name = `${first_name} ${last_name}`;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={profile_picture} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>

      {/* <TableCell align="left">{company}</TableCell> */}

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {roles?.map((role) => (
          <Typography key={role?.id} variant="subtitle2" noWrap>
            {role?.name}
          </Typography>
        ))}
      </TableCell>
      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {teams?.map((team) => (
          <Typography key={team?.id} variant="subtitle2" noWrap>
            {team?.name}
          </Typography>
        ))}
      </TableCell>

      <TableCell align="center">
        {working_hours}
      </TableCell>

      <TableCell align="center">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {
                roles[0]?.id !== 1 && (
                  <MenuItem
                    onClick={() => {
                      setDeleteDialog(true);
                      setDeleteId(row.id);
                      // onDeleteRow();
                      handleCloseMenu();
                    }}
                    sx={{ color: 'error.main' }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                    Delete
                  </MenuItem>
                )
              }

              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handlePasswordChange(row?.id);
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'carbon:password'} />
                Change
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
