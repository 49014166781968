import PropTypes from 'prop-types';
// @mui
import { Avatar, AvatarGroup, Button, TableCell, TableRow } from '@mui/material';
// components
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import MinutesDetail from 'src/pages/m.o.m/MinutesDetail';
import Iconify from 'src/components/Iconify';
import currentURL from '../../../env';


// ----------------------------------------------------------------------

MoMRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
};

export default function MoMRow({ row, selected , Userid }) {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const baseURL = currentURL?.currentEnv;
    const { project, title, date, time_format ,id , created_by} = row;

    const handleExportFile = async (id) => {
        if (id) {
          try {
            window.open(
              `${baseURL}/minutes-of-meeting/download/${id}`
            );

            enqueueSnackbar(`Exporting MOM `, {
                variant: 'success',
              });
          } catch (error) {
            console.error('Error:', error);
          }
        }
      };

    return (
        <>
            <MinutesDetail open={open} setOpen={setOpen} data={row} MoMid={id} />

            <TableRow hover selected={selected}>

                <TableCell align="left" onClick={() => created_by?.id === Userid ? window.open(`/tasks/minuteofMeeting/${id}`, '_blank') : setOpen(true)} sx={{ cursor: 'pointer' } }>{title}</TableCell>
                <TableCell align="left" >{created_by?.full_name}</TableCell>

                <TableCell align="left">
                    {moment(date).format('DD-MM-YYYY')}
                </TableCell>

                <TableCell align="center">
                    {time_format}
                </TableCell>

                <TableCell align="left">
                    {project?.title}
                </TableCell>

                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <AvatarGroup max={6} sx={{ '& .MuiAvatar-root': { width: 25, height: 25 } }}>
                        {row?.assigned_users?.map((e, index) => <Avatar alt={e?.full_name} src={e?.profile_picture} key={index} />)}
                    </AvatarGroup>
                </TableCell>
                <TableCell align="left">
                  <Iconify onClick={()=>handleExportFile(row?.id)} icon={'octicon:download-16'} sx={{ width: 20, height: 20, cursor:"pointer" }} />

                </TableCell>
            </TableRow>
        </>
    );
}
