import { TextField } from '@mui/material';

const MobilePicker = ({ time, handleTime }) => {
  const handleTimeChange = (event) => {
    handleTime(event.target.value);
  };

  return (
    // <input type="time" value={time} onChange={handleTimeChange} style={{
    //     height: '30px',
    //     width: '150px',
    //     textAlign: 'center',
    //     fontSize: '20px',
    // }} />
    <TextField id="outlined-basic" variant="outlined" type="time" value={time} onChange={handleTimeChange} fullWidth />
  );
};

export default MobilePicker;
