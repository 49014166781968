import { Route, Routes } from 'react-router';
import Page404 from 'src/pages/Page404';
import StartupCreate from 'src/pages/Startup/Create';
import StartupList from 'src/pages/Startup/List';
import Activities from 'src/pages/activities/Activities';
import BulkUpload from 'src/pages/bulk_uploaad';

import Dashboard from 'src/pages/dashboard/dashboard';
import FavouriteTasks from 'src/pages/favourite_tasks/FavouriteTasks';
import Kanban from 'src/pages/kanban';
import Leaves from 'src/pages/leaves';
import Logs from 'src/pages/logs/list';
import UserMoM from 'src/pages/m.o.m';
import EditProfile from 'src/pages/profile/EditProfile';
import EmployeeCard from 'src/pages/profile/EmployeeCard';
import Profile from 'src/pages/profile/Profile';
import CreateProject from 'src/pages/project/Create';
import ProjectDetails from 'src/pages/project/Details';
import ProjectList from 'src/pages/project/List';
import ProjectStatus from 'src/pages/project_status/List';
import Reports from 'src/pages/reports';
import ReportsProjectDetail from 'src/pages/reports/Details';
import ReviewTask from 'src/pages/review';
import RoleList from 'src/pages/roles/List';

import TestingTask from 'src/pages/testing';
import TaskCard from 'src/pages/taskCard';
import UserCard from 'src/pages/taskCard/UserCard';
import TaskLabels from 'src/pages/task_labels/List';
import TaskPriorities from 'src/pages/task_priorities/List';
import TaskStatus from 'src/pages/task_status/List';
import TeamTasks from 'src/pages/tasks/TeamTasks';
import CreateTask from 'src/pages/tasks/addTasks';
import TabTasks from 'src/pages/tasks/tabTasks';
import Tasks from 'src/pages/tasks/tasks';
import TeamList from 'src/pages/teams/List';
import TimeSheet from 'src/pages/timeSheet';
import Today from 'src/pages/today';
import Tracker from 'src/pages/tracker';
import UserAccount from 'src/pages/user/UserAccount';
import UserCreate from 'src/pages/user/UserCreate';
import UserList from 'src/pages/user/UserList';
import UserReport from 'src/pages/user_report/userReport';
import LeaveDetail from 'src/sections/@dashboard/calendar/LeaveDetail';
import LeaveListDetail from 'src/sections/@dashboard/calendar/LeaveListDetail';
import SingleTaskDetail from 'src/sections/@dashboard/task/SingleTaskDetail';
import CreateCertificate from 'src/pages/certificate/create';
import MomAddForm from 'src/pages/m.o.m/AddMomForm';
import EditMomForm from 'src/pages/m.o.m/EditMomForm';
import ReportPrintPage from 'src/pages/reports/ReportPrintPage';
import ProgressCardTask from 'src/pages/taskCard/ProgressTaskCard';
import WatchTask from 'src/pages/watch';

const AdminRoutes = ({ role }) => (
  // Admin routes for Team Lead and Admin
  // Lead has role id 3 and admin has 1
  <Routes>
    {role === 3 || role === 4 ? ( // team lead
      <>
        <Route path={'dashboard'} element={<Dashboard />} />
        <Route path={'/'} element={<Dashboard />} />
        <Route path={'kanban'} element={<Kanban />} />
        <Route path={'kanban/add-task'} element={<CreateTask roleId={role} />} />
        <Route path={'profile'} element={<Profile />} />
        <Route path={'profile/edit'} element={<EditProfile />} />
        <Route path={'employee-card'} element={<EmployeeCard />} />
        <Route path={'activities'} element={<Activities />} />
        <Route path={'favourite-tasks'} element={<FavouriteTasks />} />
        <Route path={'tasks'} element={<Tasks roleId={role} />} />
        <Route path={'tasks/:status'} element={<Tasks roleId={role} />} />
        <Route path={'team-tasks'} element={<TeamTasks />} />
        <Route path={'team-tasks/:status'} element={<TeamTasks />} />
        <Route path={'team-tasks/add-task'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/edit/:id'} element={<CreateTask roleId={role} />} />
        <Route path={'bulk-upload'} element={<BulkUpload />} />
        <Route path={'project/details/:id'} element={<ProjectDetails />} />
        <Route path={'tasks/minuteofMeeting'} element={<MomAddForm />} />
        <Route path={'tasks/minuteofMeeting/:id'} element={<EditMomForm />} />
        <Route path={'watch'} element={<WatchTask />} />
        {/* <Route path={'tasks/add-sub/:id'} element={<CreateTask roleId={role} />} /> */}
        <Route path={'team-tasks/edit/:id'} element={<CreateTask roleId={role} />} />
        <Route path={'team-tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'logs'} element={<Logs />} />
        <Route path={'review'} element={<ReviewTask />} />
        <Route path={'testing'} element={<TestingTask />} />
        <Route path={'time-sheet'} element={<TimeSheet />} />
        <Route path={'tracker'} element={<Tracker />} />
        <Route path={'performance'} element={<UserReport />} />
        <Route path={'minutes-of-meeting'} element={<UserMoM />} />
        <Route path={'leaves'} element={<Leaves />} />
        <Route path={'leaves/:id'} element={<LeaveDetail />} />
        <Route path={'leaveslist/:id'} element={<LeaveListDetail />} />
        <Route path={'*'} element={<Page404 />} />
      </>
    ) : (
      // admin
      <>
        <Route path={'dashboard'} element={<Dashboard />} />
        <Route path={'/'} element={<Dashboard />} />
        <Route path={'profile'} element={<Profile />} />
        <Route path={'profile/edit'} element={<EditProfile />} />
        <Route path={'employee-card'} element={<EmployeeCard />} />
        <Route path={'activities'} element={<Activities />} />

        <Route path={'tasks'} element={<TabTasks roleId={role} />} />
        <Route path={'tasks/:status'} element={<TabTasks roleId={role} />} />
        <Route path={'tasks/status-wise'} element={<Kanban roleId={role} />} />
        <Route path={'kanban/add-task'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/status-wise/:status'} element={<Kanban roleId={role} />} />
        <Route path={'bulk-upload'} element={<BulkUpload />} />

        <Route path={'tasks/add-task'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/add-sub/:id'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/edit/:id'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/project-wise'} element={<TaskCard />} />
        <Route path={'tasks/user-wise'} element={<UserCard />} />
        <Route path={'tasks/progress'} element={<ProgressCardTask />} />
        <Route path={'tasks/minuteofMeeting'} element={<MomAddForm />} />
        <Route path={'tasks/minuteofMeeting/:id'} element={<EditMomForm />} />

        <Route path={'tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'favourite-tasks'} element={<FavouriteTasks />} />
        <Route path={'logs'} element={<Logs />} />
        <Route path={'review'} element={<ReviewTask />} />
        <Route path={'watch'} element={<WatchTask />} />
        <Route path={'time-sheet'} element={<TimeSheet />} />
        <Route path={'tracker'} element={<Tracker />} />
        <Route path={'today-task'} element={<Today roleId={role} />} />
        <Route path={'startup/create-startup'} element={<StartupCreate />} />
        <Route path={'startup/startup-list'} element={<StartupList />} />
        <Route path={'startup/edit/:id'} element={<StartupCreate />} />
        <Route path={'project/create-project'} element={<CreateProject />} />
        <Route path={'project/project-list'} element={<ProjectList />} />
        <Route path={'project/details/:id'} element={<ProjectDetails />} />
        <Route path={'project/edit/:id'} element={<CreateProject />} />
        <Route path={'report/project-reports'} element={<Reports />} />
        <Route path={'report/user-reports'} element={<UserReport />} />
        <Route path={'reports/project/:id'} element={<ReportsProjectDetail />} />
        {/* <Route path={'reports-detail'} element={<ReportPrintPage />} /> */}
        <Route path={'manage-user/create-user'} element={<UserCreate />} />
        <Route path={'manage-user/edit-user/:id'} element={<UserCreate />} />
        <Route path={'user/user-list'} element={<UserList />} />
        <Route path={'user/user-account'} element={<UserAccount />} />
        <Route path={'settings/certificate'} element={<CreateCertificate />} />
        <Route path={'settings/team-list'} element={<TeamList />} />
        <Route path={'leaves'} element={<Leaves />} />
        <Route path={'leaves/:id'} element={<LeaveDetail />} />
        <Route path={'leaveslist/:id'} element={<LeaveListDetail />} />
        {/* <Route path={'settings/tag-list'} element={<TagList />} /> */}
        <Route path={'settings/role-list'} element={<RoleList />} />
        <Route path={'settings/project-status'} element={<ProjectStatus />} />
        <Route path={'settings/task-status'} element={<TaskStatus />} />
        <Route path={'settings/task-priorities'} element={<TaskPriorities />} />
        <Route path={'settings/task-labels'} element={<TaskLabels />} />

        <Route path={'*'} element={<Page404 />} />
      </>
    )}
  </Routes>
);

export default AdminRoutes;
