/* eslint-disable eqeqeq */
/* eslint-disable import/order */
// @mui
import { Container } from '@mui/material';
// hooks
// eslint-disable-next-line import/no-unresolved
import { useLocation, useParams } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import TaskNewEditForm from 'src/sections/@dashboard/task/TaskNewEditForm';
// components
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import TaskNewAddForm from 'src/sections/@dashboard/task/TaskNewAddForm';
import AxiosAll from 'src/services/axiosAll';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import SideAddTask from '../taskCard/AddTaskSideCard';

// ----------------------------------------------------------------------

export default function CreateTask({ roleId }) {
  const { themeStretch } = useSettings();
  const params = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const isSub = pathname.includes('add-sub');
  const isAddtask = pathname.includes('add-task');
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [task, setTask] = useState([]);

  const getTask = async () => {
    const response = await AxiosAll('get', `/tasks/${params?.id}`, null, userDetails.token);
    const { data } = response;
    setTask(data?.data);
    return {};
  };

  useEffect(() => {
    if (isEdit || isSub) {
      getTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isSub]);

  return (
    <Page title="Task: Create a new task">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          // eslint-disable-next-line no-nested-ternary
          heading={isEdit ? 'Edit task' : isAddtask ? 'Add new task ' : 'Add sub task'}
          links={
            isEdit
              ? [
                  { name: 'Dashboard', href: '/dashboard' },
                  { name: 'Task list', href: roleId == 1 ? '/tasks/status-wise' : '/kanban' },
                  { name: 'Edit Task' },
                ]
              : [
                  { name: 'Dashboard', href: '/dashboard' },
                  { name: 'Task list', href: roleId == 1 ? '/tasks/status-wise' : '/kanban' },
                  { name: 'New Task' },
                ]
          }
        />
        {isEdit ? <TaskNewEditForm isEdit={isEdit} task={task} isSub={isSub} /> : <TaskNewAddForm task={task} />}
      </Container>
    </Page>
  );
}
