import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

ChangePassword.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string,
};

export default function ChangePassword({ open, onClose, id }) {
  const { userDetails } = useSelector((state) => state.loginReducer);

  const { enqueueSnackbar } = useSnackbar();

  const NewtagSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  });

  const defaultValues = useMemo(
    () => ({
      password: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewtagSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting ,errors},
  } = methods;

  const values = watch();
console.log("errors",errors)
  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('password', values?.password);

      const response = await AxiosAll(
        'post',
        `/user-change-password?user_id=${id}&password=${values?.password}`,
        {},
        userDetails.token
      );

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        onClose();
        reset();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Change Password</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>Password</LabelStyle>
              <RHFTextField name="password" placeholder="Change Password" />
            </div>
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              onClose();
              reset(defaultValues);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
