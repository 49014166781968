import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { Alert, Button, FormControlLabel, Stack, Switch, Tooltip } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/services/axiosAll';
import { RHFTextField } from '../hook-form';
import Iconify from '../Iconify';
import MobilePicker from '../Timepicker';
import DateInputFelid from '../dateInputFelid';

// import { FormControlLabel, FormGroup, Switch } from '@mui/material';

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

export default function InputTable({ userData, values, path, MomMId, updateMemberRow }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [meeting, setMeetingRows] = React.useState([
    {
      LocalId: 1,
      subject: '',
      description: '',
      action_point: '',
      due_time: null,
      due_date: null,
      assigned_user: '',
      create_task: false,
    },
    {
      LocalId: 2,
      subject: '',
      description: '',
      action_point: '',
      due_time: null,
      due_date: null,
      assigned_user: '',
      create_task: false,
    },
    {
      LocalId: 3,
      subject: '',
      description: '',
      action_point: '',
      due_time: null,
      due_date: null,
      assigned_user: '',
      create_task: false,
    },
    {
      LocalId: 4,
      subject: '',
      description: '',
      action_point: '',
      due_time: null,
      due_date: null,
      assigned_user: '',
      create_task: false,
    },
    {
      LocalId: 5,
      subject: '',
      description: '',
      action_point: '',
      due_time: null,
      due_date: null,
      assigned_user: '',
      create_task: false,
    },
  ]);

  React.useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('meetingrows'));
    if (storedData) {
      setMeetingRows(storedData);
    } else if (MomMId && updateMemberRow?.length > 0) {
      setMeetingRows(updateMemberRow);
      localStorage.setItem('meetingrows', JSON.stringify(updateMemberRow));
    } else {
      setMeetingRows(meeting);
    }
  }, [values]);
  // console.log('check aray or not ', Array.isArray(userData)); // Output should be true if userData is an array

  const addNewRow = () => {
    const newId = meeting.length + 1;
    const newRow = { LocalId: newId, action_point: '', description: '', assigned_user: '', create_task: false };
    setMeetingRows([...meeting, newRow]);
    localStorage.setItem('meetingrows', JSON.stringify([...meeting, newRow]));
  };

  const removeObjectById = (id) => {
    const updatedMeeting = meeting.filter((row) => row.LocalId !== id);
    // Update IDs to start from 1
    // const updatedRows = updatedMeeting.map((row, index) => ({ ...row, id: index + 1 }));
    setMeetingRows(updatedMeeting);
    localStorage.setItem('meetingrows', JSON.stringify(updatedMeeting));
  };
  const handleChange = (index, field, value) => {
    const updatedData = [...meeting];
    // Update the specific field in the copied array
    updatedData[index] = {
      ...updatedData[index],
      [field]: value,
    };

    // Update the state with the new array
    setMeetingRows(updatedData);
    localStorage.setItem('meetingrows', JSON.stringify(updatedData));
    setMessage('');
  };

  const assingValue = (id) => {
    if (id !== 0) {
      const result = userData?.find((e) => e?.id === id);
      return result;
    }
    // Handle the case when id is 0 or undefined
    return null;
  };

  const createMomFeature = async () => {
    const filteredData = meeting.filter(
      (item) => item.subject !== '' && item.description !== '' && item.action_point !== ''
    );
    if (filteredData?.length) {
      const data = {
        project_id: values?.project,
        date: values?.startDate,
        title: values?.title,
        details: filteredData,
      };
      const res = await AxiosAll('post', '/v2/minutes-of-meeting/', data, token);
      if (res?.status === 201) {
        const newData = res?.data;
        localStorage.removeItem('meetingrows');
        localStorage.removeItem('momPojectDetail');
        localStorage.removeItem('meetingrows1');
        localStorage.removeItem('momPojectDetail1');
        if (user?.role_id === 3 || user?.role_id === 4) {
          navigate('/minutes-of-meeting');
        } else {
          navigate('/tasks/project-wise');
        }
      }
    } else {
      setMessage('Please add minutes of meeting');
    }
  };
  // console.log('meeting', meeting);
  return (
    <TableContainer component={Paper} sx={{ mt: 4 }}>
      {message && <Alert severity="error">{message}</Alert>}
      {userData && userData?.length > 0 ? (
        <>
          {' '}
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className="addmom_table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Subject</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Action Point</TableCell>
                <TableCell align="center">Assign User</TableCell>
                <TableCell align="center">Create Task</TableCell>
                <TableCell align="center">Due Date</TableCell>
                <TableCell align="center">Due Time</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meeting?.map((row, index) => (
                <>
                  <TableRow
                    key={row.id}
                    sx={{ verticalAlign: 'top', cursor: row?.id && row?.create_task ? 'pointer' : '' }}
                    onClick={() => row?.id && row?.create_task && navigate(`/tasks/edit/${row?.task_id}`)}
                  >
                    <TableCell scope="row" className="min-width-table">
                      <RHFTextField
                        disabled={row?.id}
                        onChange={(e) => handleChange(index, 'subject', e.target.value)}
                        value={row?.subject}
                        name={`meetingrows[${index}].subject`}
                        fullWidth
                        variant="standard"
                        placeholder="Please describe subject.."
                      />
                    </TableCell>
                    <TableCell align="center" className="min-width-table">
                      <RHFTextField
                        disabled={row?.id}
                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                        value={row?.description}
                        name={`meetingrows[${index}].description`}
                        placeholder="Write Something Description..."
                        multiline
                        rows={3}
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell scope="row" className="min-width-table">
                      <RHFTextField
                        disabled={row?.id}
                        onChange={(e) => handleChange(index, 'action_point', e.target.value)}
                        value={row?.action_point}
                        name={`meetingrows[${index}].action_point`}
                        fullWidth
                        variant="standard"
                        placeholder="Action point  .."
                      />
                    </TableCell>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center' }} className="auto_select_box">
                      <Autocomplete
                        disablePortal
                        disabled={row?.id}
                        id="combo-box-demo"
                        name={`meetingrows[${index}].assigned_user`}
                        onChange={(e, value) => handleChange(index, 'assigned_user', value?.id)}
                        options={userData}
                        value={assingValue(row?.assigned_user ? row?.assigned_user : 0)}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} variant="standard" />}
                      />
                    </TableCell>
                    <TableCell align="center" style={{ verticalAlign: 'middle' }}>
                      {/* Add your create_task input field here */}
                      <FormControlLabel
                        control={
                          <Switch
                            type="checkbox"
                            disabled={row?.id}
                            checked={row.create_task}
                            name={`meetingrows[${index}].create_task`}
                            onChange={(e) => handleChange(index, 'create_task', e.target.checked)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center" style={{ verticalAlign: 'middle' }}>
                      <RHFTextField
                        disabled={!row.create_task || row?.id}
                        min={values?.startDate}
                        value={row?.due_date}
                        name={`meetingrows[${index}].due_date`}
                        type="date"
                        onChange={(e) => handleChange(index, 'due_date', e.target.value)}
                      />
                    </TableCell>
                    <TableCell align="center" style={{ verticalAlign: 'middle' }}>
                      <MobilePicker
                        disabled={!row.create_task || row?.id}
                        name={`meetingrows[${index}].due_time`}
                        time={row?.due_time}
                        handleTime={(e) => handleChange(index, 'due_time', e)}
                      />
                    </TableCell>

                    <TableCell align="center" style={{ verticalAlign: 'middle' }}>
                      {!row?.id && (
                        <Iconify
                          onClick={() => removeObjectById(row.id ? row?.id : row?.LocalId)}
                          icon={'fluent:delete-24-filled'}
                          width={28}
                          height={28}
                          style={{ cursor: 'pointer' }}
                        />
                      )}

                      {/* {meeting?.length > 1 && <Iconify onClick={() => removeObjectById(row.id)} icon={'fluent:delete-24-filled'} width={28} height={28} style={{ cursor: 'pointer' }} />} */}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
          <Stack spacing={3} direction="row" justifyContent="flex-end" sx={{ mt: 2, mb: 2, mr: 2 }}>
            <Button variant="contained" onClick={addNewRow}>
              Add Row
            </Button>
            <Button variant="contained" type="submit" onClick={createMomFeature}>
              submit
            </Button>
          </Stack>{' '}
        </>
      ) : null}
    </TableContainer>
  );
}
