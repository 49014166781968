/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { newServer } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../../components/hook-form';
import MobilePicker from 'src/components/Timepicker';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

TaskNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  isSub: PropTypes.bool,
  task: PropTypes.object,
};

export default function TaskNewEditForm({ isEdit, isSub, task }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [priorityData, setPriorityData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [userData, setUserData] = useState([]);
  const [teamWithUser, setTeamWithUser] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selArray, setSelArray] = useState([]);
  const [assignUserId, setAssignUserId] = useState('');
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [label, setLabels] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionIds, setSelectedOptionIds] = useState([]);
  const [taskDueTime, setTaskDueTime] = useState("00:00");

  // get redux state
  const { userDetails, userList, projectList, priorityList } = useSelector((state) => state.loginReducer);
  const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);

  const { token } = userDetails;

  const NewProductSchema = Yup.object().shape({
    task: Yup.string().required('Task is required'),
    visiblity: Yup.string().required('Startup visiblity is required'),
    plannedHour: Yup.string()
      .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Must be in the form of HH:MM')
      .required('Planned hour is required')
      .test('is-at-least-5-minutes', 'The minimum time should be 5 minutes.', (value) => {
        if (!value) return true;

        const [hour, minute] = value.split(':').map(Number);

        // Validate the time, excluding '00:00'
        return !(hour === 0 && minute === 0) && (hour > 0 || minute >= 5);
      }),
    startDate: Yup.string().required('Start date is required'),
    dueDate: Yup.string().required('Due date is required'),
  });

  const defaultValues = useMemo(
    () => ({
      task: task?.title || '',
      description: task?.description || '',
      plannedHour: task?.planned_time_format || '',
      refUrl: task?.reference_url || '',
      refTitle: task?.reference_title || '',
      startDate: task?.start_date || '',
      dueDate: task?.due_date || '',

      project: task?.project || projectList[0]?.id,
      status: task?.task_status?.id || taskStatus[0]?.id,
      predecessorTask: '',
      successorTask: '',
      assignUser: userList[0]?.id || '',
      priority: task?.task_priority?.id || priorityData?.[0]?.id,
      visiblity: task?.startup_visibility || 'visible',
      siblings: task?.has_siblings || '0',
      parentTask: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectList, taskStatus, allTask, userList, priorityData]
  );

  const defaultEditValues = useMemo(
    () => ({
      task: task?.title,
      description: task?.description || '',
      plannedHour: task?.planned_time_format,
      refUrl: task?.reference_url || '',
      refTitle: task?.reference_title || '',
      startDate: task?.start_date,
      dueDate: task?.due_date,

      project: task?.project_id,
      status: task?.task_status?.id || taskStatus[0]?.id,
      predecessorTask: task?.predecessor_task_id || '',
      successorTask: task?.following_task_id || '',
      priority: task?.task_priority?.id || priorityData[0]?.id,
      visiblity: task?.startup_visibility || 'visible',
      siblings: task?.has_siblings || '0',
      assignUser: assignUserId || userList[0]?.id,
      parentTask: task?.parent_task_id || 0,
      // teams: task?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectList, taskStatus, allTask, userList, priorityData, task]
  );
  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  useEffect(() => {
    // Check if task exists and has labels before updating state
    if (task && task.labels) {
      setSelectedOptions(task.labels);
      setSelectedOptionIds(task.labels.map((e) => e.id));
      setTaskDueTime(task?.due_time)

    }
  }, [task]);

  // console.log('selectedOptions', selectedOptions);
  // console.log('selectedOptionIds', selectedOptionIds);

  const handleChange = (event, value) => {
    setSelectedOptions(value);

    // Store selected option IDs in another state
    const selectedIds = value.map((option) => option.id);
    setSelectedOptionIds(selectedIds);
  };

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // Common dropdowns
  // const getPriority = async () => {
  //   const res = await AxiosAll('get', '/common/task-priorities', {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setPriorityData(newData);
  //   }
  // };

  const getTask = async () => {
    const res = await AxiosAll('get', `/common/tasks?project_id=${values?.project}`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setAllTask(newData);
    }
  };

  const getlabels = async () => {
    const res = await AxiosAll('get', `/labels`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data?.data;
      setLabels(newData);
    }
  };
  const getStatus = async () => {
    const statusType = 'task_edit';
    const addTaskURL = `${newServer}/common/task-statuses`;
    const updateTaskURL = `${newServer}/common/task-statuses?current_status=${task?.status}&type=${statusType}`;
    const res = await axios.get(task ? updateTaskURL : addTaskURL, {
      headers: {
        contentType: 'multipart/form-data',
        Authorization: `Bearer ${userDetails.token}`,
      },
    });
    if (res?.status === 200) {
      const newData = res?.data;
      setTaskStatus(newData);
    }
  };

  // const getUser = async () => {
  //   const res = await AxiosAll('get', `/common/users`, {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setUserData(newData);
  //   }
  // };

  useEffect(() => {
    getlabels();
  }, []);

  /* The above code is using the `useEffect` hook in React to perform an action when the `task` variable
changes. */
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    task?.status && getStatus();
  }, [task]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    values?.project && getTask()
    // getTeamWithUser())         teamWithUser is not required on edit task
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.project]);

  const assignUser = async () => {
    if (task?.assigned_user?.length !== 0) {
      if ((isEdit || isSub) && task) {
        const assignedUser = await task?.task_users?.map((item) => item.user_id);
        await setValue('assignUser', `${assignedUser[0]}`);
        setAssignUserId(assignedUser[0]);
      }
    }
  };
  const handleTimeChange = (e, onChange) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
    } else {
      onChange(value);
    }
  };

  useEffect(() => {
    assignUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isSub, task]);
  console.log('val', values);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('project_id', values?.project);
      formData.append('title', values?.task);
      formData.append('status', values?.status);
      formData.append('description', values?.description === 'null' ? '' : values.description);
      formData.append('startup_visibility', values?.visiblity);
      formData.append('start_date', moment(values.startDate).format('YYYY-MM-DD'));
      formData.append('due_date', moment(values?.dueDate).format('YYYY-MM-DD'));
      formData.append('predecessor_task_id', values?.predecessorTask);
      formData.append('following_task_id', values?.successorTask);
      // eslint-disable-next-line no-unused-expressions
      values?.siblings === '0' && formData.append('assigned_user_id', values?.assignUser);
      formData.append('priority', values?.priority);
      formData.append('planned_time_format', values?.plannedHour);
      formData.append('reference_url', values?.refUrl === 'null' ? '' : values.refUrl);
      formData.append('reference_title', values?.refTitle === 'null' ? '' : values.refTitle);
      formData.append('parent_task_id', values?.parentTask || 0);
      formData.append('has_siblings', values?.siblings);
      formData.append(`labels`, selectedOptionIds);
      formData.append('due_time', taskDueTime)

      // remove object if team id is not present in selected array
      const filteredPeople = selArray.filter((item) => selected.includes(item?.team_id));
      // pass object to formData
      const subTaskObj = {};
      filteredPeople.forEach((item, index) => {
        subTaskObj[`subTasks[${index}][team_id]`] = item.team_id;
        subTaskObj[`subTasks[${index}][user_id]`] = item.user_id;
      });
      Object.keys(subTaskObj).forEach((key) => {
        // eslint-disable-next-line no-unused-expressions
        !isEdit && values?.siblings === '1' && formData.append(key, subTaskObj[key]);
      });

      // formData.append('subTasks ', values?.siblings === '1' ? selArray : '')
      const createStartupURL = `${newServer}/tasks`;
      const updateStartupURL = `${newServer}/tasks/${params.id}`;

      const response = await axios.post(isEdit ? updateStartupURL : createStartupURL, formData, {
        headers: {
          contentType: 'multipart/form-data',
          Authorization: `Bearer ${userDetails.token}`,
        },
      });
      const { data } = response;
      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        if (isLead) {
          navigate('/kanban', { replace: true });
        } else {
          navigate('/tasks/status-wise', { replace: true });
        }
        setLoading(false);
        setSelected([]);
        reset();
      }
      reset();
    } catch (error) {
      setLoading(false);
      const { data } = error?.response;
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((isEdit || isSub) && task) {
      reset(defaultEditValues);
    }
    if (!(isEdit || isSub) && task) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, taskStatus, priorityData, projectList]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Project</LabelStyle>
                {task?.project?.title}
              </div>
              <div>
                <LabelStyle>Task</LabelStyle>
                <RHFTextField disabled={disable} name="task" label="Please describe task.." />
              </div>
              <div>
                <LabelStyle>Status</LabelStyle>
                <RHFSelect disabled={disable} name="status">
                  {taskStatus?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.name}
                    </option>
                  ))}
                </RHFSelect>
              </div>
              <div>
                <LabelStyle>Predecessor Tasks</LabelStyle>
                <RHFSelect disabled={disable} name="predecessorTask">
                  <option value={''}>Select</option>
                  {allTask?.map(
                    (item, index) =>
                      task?.id !== item?.id &&
                      item?.id != values?.successorTask && (
                        <option value={item?.id} key={index}>
                          {item?.title}
                        </option>
                      )
                  )}
                </RHFSelect>
              </div>

              <div>
                <LabelStyle>Successor Tasks</LabelStyle>
                <RHFSelect disabled={disable} name="successorTask">
                  <option value={''}>Select</option>
                  {allTask?.map(
                    (item, index) =>
                      task?.id !== item?.id &&
                      item?.id != values?.predecessorTask && (
                        <option value={item?.id} key={index}>
                          {item?.title}
                        </option>
                      )
                  )}
                </RHFSelect>
              </div>

              <div>
                <LabelStyle>Description</LabelStyle>
                <RHFEditor simple name="description" />
              </div>

              {userDetails?.user?.roles[0]?.id === 2 ? (
                <>
                  <div>
                    <LabelStyle>Assign User</LabelStyle>
                    <RHFSelect name="assignUser">
                      <option value={''}>Select</option>
                      <option
                        value={userDetails?.user?.id}
                      >{`${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`}</option>
                    </RHFSelect>
                  </div>{' '}
                </>
              ) : (
                <>
                  <div>
                    <LabelStyle>Has child task?</LabelStyle>
                    <RHFSelect disabled name="siblings">
                      <option value="0">{'No'}</option>
                      <option value="1">{'Yes'}</option>
                    </RHFSelect>
                  </div>

                  <div>
                    <LabelStyle>Assign User</LabelStyle>
                    <RHFSelect name="assignUser">
                      {userList?.map((item, index) => (
                        <option value={item?.id} key={index}>{`${item?.first_name} ${item?.last_name}`}</option>
                      ))}
                    </RHFSelect>
                  </div>
                  {isSub && (
                    <div>
                      <LabelStyle>Parent task</LabelStyle>
                      <RHFSelect disabled={disable} name="parentTask">
                        {allTask?.map(
                          (item, index) =>
                            task?.id !== item?.id && (
                              <option value={item?.id} key={index}>
                                {item?.title}
                              </option>
                            )
                        )}
                      </RHFSelect>
                    </div>
                  )}
                </>
              )}
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mt={2}>
                <div>
                  <LabelStyle>Priority*</LabelStyle>
                  <RHFSelect disabled={disable} name="priority">
                    {priorityList?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.name}
                      </option>
                    ))}
                  </RHFSelect>
                </div>
                <div>
                  <LabelStyle>Task Label</LabelStyle>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={label}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    onChange={handleChange}
                    value={selectedOptions}
                    defaultChecked={selectedOptions}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <FormControlLabel
                          style={{ width: '100%', color: option?.color }}
                          control={<Checkbox checked={selected} />}
                          label={option.name}
                        />
                      </li>
                    )}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select Options" />}
                  />
                </div>

                <div>
                  <LabelStyle>Startup Visiblity</LabelStyle>
                  <RHFSelect disabled={disable} name="visiblity">
                    <option value="visible">Visible</option>
                    <option value="hidden">Hidden</option>
                  </RHFSelect>
                </div>

                <div>
                  <LabelStyle>Estimate time (in hours and minutes)</LabelStyle>
                  <Controller
                    name="plannedHour"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <InputMask
                        maskChar={null}
                        mask="99:99"
                        value={field.value}
                        onChange={(e) => handleTimeChange(e, field.onChange)}
                      >
                        {(inputProps) => (
                          <TextField
                            {...inputProps}
                            error={!!error}
                            fullWidth
                            helperText={error?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="body2">HH:MM</Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </InputMask>
                    )}
                  />
                </div>


                <div>
                  <LabelStyle>Start Date</LabelStyle>
                  <RHFTextField disabled={disable} name="startDate" type="date" />
                </div>
                <div>
                  <LabelStyle>Due Date</LabelStyle>
                  <RHFTextField disabled={disable} name="dueDate" type="date" min={values?.startDate} />
                </div>
                <div>
                  <div style={{ flexDirection: 'row' }}>
                    <LabelStyle>Due Time </LabelStyle>
                    <MobilePicker time={taskDueTime} handleTime={setTaskDueTime} />
                  </div>
                </div>

                <div>
                  <LabelStyle>Reference URL</LabelStyle>
                  <RHFTextField disabled={disable} name="refUrl" type="url" />
                </div>

                <div>
                  <LabelStyle>Reference Title</LabelStyle>
                  <RHFTextField disabled={disable} name="refTitle" />
                </div>
              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={loading}>
              Save Changes
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
