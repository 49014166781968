import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { findTimeDifference } from 'src/core';
import Iconify from '../Iconify';
import MobilePicker from '../Timepicker';
import { FormProvider, RHFEditor, RHFTextField } from '../hook-form';

// --------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

TimeDailogs.propTypes = {
  task: PropTypes.object,
  getTasks: PropTypes.func,
};
export default function TimeDailogs({ task, getTasks }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [isDisable, setIsDisable] = useState(false);
  // console.log("end", endTime)
  const NewAddressSchema = Yup.object().shape({
    // time: Yup.string().min(5, 'Must be in the form of HH:MM').required('Time is required'),
    comments: Yup.string().required('Comments  is required'),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const defaultValues = {
    // time: task?.planned_time_format,
    comments: '',
    date: moment(new Date()).format('YYYY-MM-DD'),
  };
  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch()

  const handleOnClose = () => {
    reset(defaultValues)
    setOpen(false)
    setStartTime("00:00");
    setEndTime("00:00")
  }

  const afterCheck = async (value) => {
    try {
      const data1 = {
        task_id: task?.id,
        comment: value?.comments,
        // time_format: value?.time,
        start_time: startTime,
        end_time: endTime,
        date: value?.date,
      };
      const response = await AxiosAll('post', '/task-time-logs', data1, token);
      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        setOpen(false);
        getTasks();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsDisable(false);
  }
  const onSubmit = async (value) => {
    setIsDisable(true);
    // eslint-disable-next-line no-debugger
    if (startTime === "00:00" || endTime === "00:00" || startTime === endTime) {
      enqueueSnackbar("Start time or End time needs to change.", { variant: 'error' });
      setIsDisable(false);
    } else if (startTime > endTime) {
      enqueueSnackbar("Start time is greater than End time.", { variant: 'error' });
      setIsDisable(false);
    } else {
      afterCheck(value);
    }
  };

  return (
    <div>
      <Button className="timeBTn" onClick={handleClickOpen}>
        <Iconify icon={'ic:sharp-more-time'} />
        Time log
      </Button>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
        <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            Create time log
          </div>
          <div> Time Spend :  &nbsp;{findTimeDifference({ start: startTime, end: endTime })}  </div></DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={2}>

              {/* <div>
                <LabelStyle>Time Taken</LabelStyle>
                <Controller
                  name="time"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleTimeChange(e, field.onChange)}>
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          error={!!error}
                          fullWidth
                          helperText={error?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography variant="body2">HH:MM</Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </div> */}
              <div>
                <LabelStyle>Date</LabelStyle>
                <RHFTextField name="date" type="date" />
              </div>
              <div>
                <LabelStyle>Description</LabelStyle>
                {/* <RHFTextField name="description" label="Write Something Awesome..." multiline rows={4} /> */}
                <RHFEditor simple name="comments" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ flexDirection: 'row' }}>
                  Start Time :   &nbsp;
                  <MobilePicker time={startTime} handleTime={setStartTime} />
                </div>
                <div style={{ flexDirection: 'row' }}>
                  End Time :   &nbsp;
                  <MobilePicker time={endTime} handleTime={setEndTime} />
                </div>
              </div>
            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton type="submit" variant="contained" loading={isDisable}>
              Submit
            </LoadingButton>
            <Button color="inherit" variant="outlined" onClick={handleOnClose}>
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}
