import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Container, FormControlLabel, Switch,
  Table,
  TableBody,
  TableContainer
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import {
  TableHeadCustom,
  TableNoData, TableSkeleton
} from '../../components/table';
// sections
import { RoleTableRow, RoleTableToolbar } from '../../sections/@dashboard/role/list';
import CreateRole from './Create';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Role Title', align: 'center' },
  // { id: 'activeUsers', label: 'Active Users', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: '', align: 'center' },
];

// ----------------------------------------------------------------------

export default function RoleList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [currentRole, setCurrentRole] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const { enqueueSnackbar } = useSnackbar();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    const response = await AxiosAll('delete', `/roles/${id}`, {}, token);
    const { data } = response;
    if (data?.statusCode === 200) {
      enqueueSnackbar(data?.message, { variant: 'success' });
      getRoles();
    } else {
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  };

  const getRoles = async () => {
    const response = await AxiosAll('get', `/roles?query=${filterName}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      // setRowsPerPage(data?.data?.per_page);
      setTableData(data?.data);
    }
  };

  const getSingleRole = async (id) => {
    const response = await AxiosAll('get', `/roles/${id}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setCurrentRole(data?.data);
    }
  };

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterName]);

  const handleEditRow = (id) => {
    getSingleRole(id);
    setIsEdit(true);
    setOpen(true);
  };

  const handleConfirmDelete = (id) => {
    handleDeleteRow(id);
    setDeleteDialog(false);
  };

  const closeDialog = () => {
    setOpen(false);
    if (isEdit) {
      setIsEdit(false);
      setCurrentRole([]);
    }
  };

  // const dataFiltered = applySortFilter({
  //   tableData,
  //   comparator: getComparator(order, orderBy),
  //   filterName,
  //   filterRole,
  //   filterStatus,
  // });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData?.length && !!filterName) ||
    (!tableData?.length && !!filterRole) ||
    (!tableData?.length && !!filterStatus);

  return (
    <Page title="Role: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Role List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Roles List' }]}
          action={
            <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              New roles
            </Button>
          }
        />

        <ConfirmDialogue
          open={deleteDialog}
          loading={loading}
          onClose={() => setDeleteDialog(false)}
          onConfirm={() => handleConfirmDelete(deleteId)}
          title="Are you sure you want to delete this role?"
          content="This action cannot be undone. Please confirm to proceed."
        />

        <Card>
          <CreateRole open={open} onClose={closeDialog} currentRole={currentRole} getRoles={getRoles} isEdit={isEdit} />

          <RoleTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            search="roles"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                      <RoleTableRow
                        key={row.id}
                        row={row}
                        setDeleteDialog={setDeleteDialog}
                        setDeleteId={setDeleteId}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                      />
                    ))}

                    {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            {/* <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            /> */}

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

// function applySortFilter({ tableData, comparator, filterName, filterStatus, filterRole }) {
//   const stabilizedThis = tableData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   tableData = stabilizedThis.map((el) => el[0]);

//   if (filterName) {
//     tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
//   }

//   if (filterStatus !== 'all') {
//     tableData = tableData.filter((item) => item.status === filterStatus);
//   }

//   if (filterRole !== 'all') {
//     tableData = tableData.filter((item) => item.role === filterRole);
//   }

//   return tableData;
// }
