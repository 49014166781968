import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Avatar, Box, Checkbox, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  // onDeleteRow: PropTypes.func,
  setDeleteDialog: PropTypes.func,
  setDeleteId: PropTypes.func,
  setMultipleDeleteMode: PropTypes.bool,
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  // onDeleteRow,
  setDeleteDialog,
  setMultipleDeleteMode,
  setDeleteId,
}) {
  const theme = useTheme();

  const { name, logo, description, status } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
    setMultipleDeleteMode(false);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell style={{ verticalAlign: 'middle' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Avatar alt={name} src={logo} sx={{ mr: 2 }} />
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align="left">{description}</TableCell>
      <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>{row?.start_date}</TableCell>

      {/* <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {role}
      </TableCell>

      <TableCell align="center">
        <Iconify
          icon={isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!isVerified && { color: 'warning.main' }),
          }}
        />
      </TableCell> */}

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'inactive' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell>

      <TableCell align="left">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  // onDeleteRow();
                  setDeleteDialog(true);
                  setDeleteId(row.id);
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
