// src/components/InstallButton.js

import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

const InstallButton = () => {
  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  const handleBeforeInstallPrompt = (event) => {
    // Prevent the default behavior
    event.preventDefault();
    // Store the event for later use
    setInstallPromptEvent(event);
  };

  const handleInstallButtonClick = () => {
    if (installPromptEvent) {
      // Show the install prompt
      installPromptEvent.prompt();
      // Wait for the user's choice
      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          setIsAppInstalled(true);
        } else {
          console.log('User dismissed the install prompt');
        }
        // Clear the install prompt event
        setInstallPromptEvent(null);
      });
    }
  };

  useEffect(() => {
    // Check if the app is already installed
    const mediaQueryList = window.matchMedia('(display-mode: standalone)');
    setIsAppInstalled(mediaQueryList.matches);

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // Hide the button if the app is already installed
  if (isAppInstalled) {
    return null;
  }

  return (
    <Button
      onClick={handleInstallButtonClick}
      fullWidth
      sx={{ all: 'unset', marginLeft: '6px' }}
      className="usefull_btn"
    >
      Install App
    </Button>
  );
};

const installButtonStyle = {
  backgroundColor: '#4caf50',
  color: 'white',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '4px',
};

export default InstallButton;
