import { AnimatePresence, m } from 'framer-motion';
import { useEffect, useState } from 'react';
// @mui
import { Backdrop, Divider, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
// eslint-disable-next-line import/extensions
import { useSelector } from 'react-redux';
// utils
// eslint-disable-next-line import/extensions
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import cssStyles from '../../utils/cssStyles';
// config
// eslint-disable-next-line import/extensions
// import { NAVBAR, defaultSettings } from ';
//
// eslint-disable-next-line import/extensions
import Iconify from '../Iconify';
// eslint-disable-next-line import/extensions
import Scrollbar from '../Scrollbar';
// eslint-disable-next-line import/extensions
//
// eslint-disable-next-line import/extensions
// import ToggleButton from './ToggleButton';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
    ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    width: window.innerWidth <= 450 ? '55vw' : '25vw',
    flexDirection: 'column',
    margin: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 3,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: `-24px 12px 32px -4px ${alpha(
        theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
        0.16
    )}`,
}));

// ----------------------------------------------------------------------

export default function FilterTaskSelf({ setProjectID, setPrioritieId, priorities, project, status, users, setStatusId, StatusId, setDate, setPage }) {
    const [open, setOpen] = useState(false);
    const params = useParams();
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token, user } = userDetails;
    const [uservalue, setUserVlaue] = useState();
    const [projectValue, setProjectValue] = useState(search.get("project_id"));
    const [statusValue, setStatusValue] = useState(params?.status || search.get("status"));
    const [prioritieValue, setPrioritieValue] = useState(search.get("priority"))
    const [date, setDueDate] = useState(search.get("due_date"))
    const isStat = !!params?.status;
    const location = useLocation()

    const handleUsers = (e) => {
        // eslint-disable-next-line no-debugger
        // setUserID(e.target.value);
        setUserVlaue(e.target.value);
        setProjectID('');
        setStatusId('');
        setProjectValue('');
        setStatusValue('');
        setDueDate('')
        setDate("")
    };

    const handleProject = (e) => {
        // eslint-disable-next-line no-debugger
        setPage(0);
        navigate(`${location.pathname}?project_id=${e.target?.value}&user_id=${search.get("user_id") ? search.get("user_id") : ''}&status=${search.get("status") ? search.get("status") : ''}&priority=${search.get("priority") ? search.get("priority") : ''}&due_date=${search.get("due_date") ? search.get("due_date") : ''}`);
        // setProjectID(e.target.value);
        setProjectValue(e.target.value);
    };

    const handlePrioritiyID = (e) => {
        // eslint-disable-next-line no-debugger
        setPage(0);
        navigate(`${location.pathname}?project_id=${search.get("project_id") ? search.get("project_id") : ''}&user_id=${search.get("user_id") ? search.get("user_id") : ''}&status=${search.get("status") ? search.get("status") : ''}&priority=${e.target?.value}&due_date=${search.get("due_date") ? search.get("due_date") : ''}`);
        setPrioritieValue(e.target.value);
    };

    const handleStatusId = (e) => {
        // eslint-disable-next-line no-debugger
        setPage(0);
        navigate(`${location.pathname}?project_id=${search.get("project_id") ? search.get("project_id") : ''}&user_id=${search.get("user_id") ? search.get("user_id") : ''}&status=${e.target?.value}&priority=${search.get("priority") ? search.get("priority") : ''}&due_date=${search.get("due_date") ? search.get("due_date") : ''}`);
        setStatusValue(e.target.value);
    };

    const handleDateChange = (e) => {
        setPage(0);
        navigate(`${location.pathname}?project_id=${search.get("project_id") ? search.get("project_id") : ''}&user_id=${search.get("user_id") ? search.get("user_id") : ''}&status=${search.get("status") ? search.get("status") : ''}&priority=${search.get("priority") ? search.get("priority") : ''}&due_date=${e.target?.value}`);
        setDueDate(e.target.value)
    };

    const handleReset = () => {
        setPage(0);
        navigate(`${location.pathname}`)
        setUserVlaue('');
        setProjectValue('');
        setPrioritieValue('');
        setDueDate('')
        // eslint-disable-next-line no-unused-expressions
        !isStat && setStatusValue('');

        // eslint-disable-next-line no-unused-expressions
        // eslint-disable-next-line no-unused-expressions
        !isStat && setStatusId("");

        // setProjectID("");

        // // setUserID("");

        // setPrioritieId('');
    };


    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [open]);

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Backdrop
                open={open}
                onClick={handleClose}
                sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            />

            {!open && (
                <Iconify
                    onClick={() => setOpen(true)}
                    icon={'material-symbols:filter-alt-sharp'}
                    className="Filter "
                    width={30}
                    height={30}
                    sx={{ mb: 2 }}
                />
            )}

            <AnimatePresence>
                {open && (
                    <>
                        <RootStyle>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    Filter Task
                                </Typography>

                                <IconButton onClick={handleReset}>
                                    <Iconify icon={'ic:round-refresh'} width={20} height={20} />
                                </IconButton>

                                <IconButton onClick={handleClose}>
                                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                                </IconButton>
                            </Stack>

                            <Divider sx={{ borderStyle: 'dashed' }} />

                            <Scrollbar sx={{ flexGrow: 1 }}>
                                <Stack spacing={3} sx={{ p: 3 }}>
                                    <Stack spacing={2}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Project"
                                            className="dropdown_container"
                                            defaultValue={''}
                                            onChange={handleProject}
                                            value={projectValue}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                },
                                            }}
                                            sx={{
                                                maxWidth: { sm: 240 },
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {project &&
                                                project?.map((option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        value={option?.id}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {option?.title}
                                                    </MenuItem>
                                                ))}
                                        </TextField>{' '}

                                        {!isStat && <TextField
                                            fullWidth
                                            select
                                            label="Status"
                                            className="dropdown_container"
                                            onChange={handleStatusId}
                                            value={statusValue}
                                            defaultValue={''}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                },
                                            }}
                                            sx={{
                                                maxWidth: { sm: 240 },
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {status &&
                                                status?.map((option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        value={option?.id}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {option?.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>}

                                        <TextField
                                            fullWidth
                                            select
                                            label="Priroty"
                                            className="dropdown_container"
                                            onChange={handlePrioritiyID}
                                            value={prioritieValue}
                                            defaultValue={''}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                },
                                            }}
                                            sx={{
                                                maxWidth: { sm: 240 },
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {priorities &&
                                                priorities?.map((option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        value={option?.id}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {option?.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                        <div className="dropdown_container">
                                            <TextField id="date" type="date" value={date} onChange={handleDateChange} />
                                        </div>
                                    </Stack>
                                </Stack>
                            </Scrollbar>
                        </RootStyle>
                    </>
                )}
            </AnimatePresence>
        </>
    );
}
