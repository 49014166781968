/* eslint-disable react/jsx-no-comment-textnodes */
import { Draggable } from '@hello-pangea/dnd';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router';
// @mui
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// theme
import { bgBlur } from 'src/theme/css';
// components
//
import { Avatar, AvatarGroup, Button, Tooltip } from '@mui/material';
import moment from 'moment';
import { NameInitialsAvatar } from 'react-name-initials-avatar';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { truncateText } from 'src/core';
import palette from 'src/theme/palette';
import KanbanDetails from './kanban-details';
import KanbanWatchPopup from './kanban-watchDetail';

// ----------------------------------------------------------------------

export default function KanbanTaskItem({ task, index, onDeleteTask, onUpdateTask, sx, column, callBack, ...other }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const [openWatchPopup, setOpenWatchPopup] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const openDetails = useBoolean();

  const urlImage = `https://internal-pms-dev.s3.ap-south-1.amazonaws.com/`;
  const dataUrl = task?.task_users[0]?.user?.profile_picture;
  const testerUrl = task?.task_users[0]?.tester?.profile_picture;

  const mainUrl = `${urlImage}${dataUrl}`;
  const testerImageUrl = `${urlImage}${testerUrl}`;

  const warningMainColor = palette.light.warning.main;
  const grey900Color = palette.light.grey[900];

  console.log('column', column);

  const handleWatchOpen = () => {
    if (user?.role_id === 1 || user?.role_id === 3 || user?.role_id === 4) {
      setOpenWatchPopup(true);
    } else {
      setOpenWatchPopup(false);
    }
  };
  const handleWatchingOpen = () => {
    if (user?.role_id === 1 || user?.role_id === 3 || user?.role_id === 4) {
      setOpenWatchPopup(true);
    } else {
      setOpenWatchPopup(false);
    }
  };

  const handleWatchClose = () => {
    setOpenWatchPopup(false);
  };

  const ColorToPriority = (id) => {
    switch (id) {
      case 1:
        return 'error.light'; // urgent
      case 2:
        return 'red'; // Bug
      case 3:
        return 'info.light'; // High
      case 4:
        return '#7ae30e'; // Medium
      default:
        return '#ffdc00'; // Low
    }
  };

  const renderPriority = (
    <Tooltip title={`Task priority is ${task?.task_priority?.name}`} placement="top">
      <Typography
        sx={{
          // position: '',
          // top: 10,
          // left: 10,
          padding: '2px 8px',
          fontSize: 14,
          borderRadius: '6px',
          ...{
            // color: 'info.main',
            backgroundColor: ColorToPriority(task?.priority),
          },
        }}
      >
        {task?.task_priority?.name}
      </Typography>
    </Tooltip>
  );

  const editTaskDetail = (
    <Iconify
      onClick={() => navigate(`/tasks/edit/${task.id}`)}
      sx={{
        // position: 'absolute', top: 0, right: 10,
        fontSize: 25,
        marginTop: 0,
        cursor: 'pointer',
        // padding: 1,
      }}
      icon="mi:edit"
    />
  );
  const watchTaskDetail = (
    <Iconify
      onClick={(e) => {
        e.stopPropagation();
        handleWatchOpen();
      }}
      sx={{
        // position: 'absolute',
        // top: 0,
        // right: 40,
        fontSize: 25,
        marginTop: 0,
        cursor: 'pointer',
        // padding: 1,
        backgroundColor: 'light',
      }}
      icon="carbon:view-filled"
    />
  );
  // <Icon icon="el:eye-close" />;
  const WatchButton = (
    <Iconify
      onClick={(e) => {
        e.stopPropagation();
        handleWatchingOpen();
      }}
      sx={{
        // position: 'absolute',
        // top: 0,
        // right: 40,
        fontSize: 25,
        marginTop: 0,
        cursor: 'pointer',
        // padding: 1,
        backgroundColor: 'light',
      }}
      icon="el:eye-close"
    />
  );

  const isWatching = (task) => {
    if (user?.roles[0]?.id === 1 || user?.roles[0]?.id === 3 || user?.roles[0]?.id === 4) {
      return <div>{task?.watchers?.some((item) => item.id === user?.id) ? WatchButton : watchTaskDetail}</div>;
    }
  };

  const renderInfo = (
    <Stack direction="row" alignItems="center">
      <Stack flexGrow={1} direction="row" alignItems="center">
        <Tooltip
          title={
            task?.is_due
              ? `The task is due from ${moment(task.due_date).format('Do MMM')} ${moment(
                  task?.due_time,
                  'HH:mm'
                ).format('hh:mm A')}`
              : ''
          }
          placement="top"
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mr: 0.25,
              backgroundColor: task?.is_due ? 'error.light' : 'warning.light',
              p: theme.spacing(1 / 2),
              borderRadius: '6px',
            }}
          >
            <Iconify width={16} icon="mingcute:calendar-2-line" sx={{ mr: 0.25 }} />

            <Typography sx={{ fontSize: 12 }}>{moment(task.due_date).format('Do MMM')}</Typography>
          </Stack>
        </Tooltip>
      </Stack>
      <Tooltip title={`estimated time : ${task.formatted_planned_time}`} placement="top">
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          sx={{
            typography: 'caption',
            color: 'text.disabled',
          }}
        >
          <Iconify width={30} height={20} icon="mingcute:time-line" sx={{ mr: 0.25 }} />
          <Typography sx={{ fontSize: 12 }}> {task.formatted_planned_time}</Typography>
        </Stack>
      </Tooltip>
      {task?.task_comments?.length > 0 && (
        <Tooltip title={`comments `} placement="top">
          <Stack
            flexGrow={1}
            direction="row"
            alignItems="center"
            sx={{
              typography: 'caption',
              color: 'text.disabled',
            }}
          >
            <Iconify width={30} height={20} icon="solar:chat-round-dots-bold" sx={{ mr: 0.25 }} />
            {/* comment task comment */}
            {/* <Box component="span" sx={{ mr: 1 }}>
            {task?.task_comments?.length}
          </Box> */}
          </Stack>
        </Tooltip>
      )}
      {/* <AvatarGroup
        sx={{
          [`& .${avatarGroupClasses.avatar}`]: {
            width: 24,
            height: 24,
          },
        }}
      >
        {task.assignee.map((user) => (
          <Avatar key={user.id} alt={user.name} src={user.avatarUrl} />
        ))}
      </AvatarGroup> */}

      {mainUrl && column?.name !== 'Completed' ? (
        <Tooltip title={task?.task_users[0]?.user?.full_name} placement="top">
          <img alt="" src={mainUrl} style={{ width: 24, height: 24, borderRadius: 50, objectFit: 'cover' }} />
        </Tooltip>
      ) : (
        <Tooltip
          title={`${task?.task_users[0]?.user?.full_name} / ${task?.task_users[0]?.tester?.full_name}`}
          placement="top"
        >
          <AvatarGroup max={4}>
            <Avatar alt="Remy Sharp" src={mainUrl} style={{ width: 24, height: 24 }} />
            <Avatar alt="Travis Howard" src={testerImageUrl} style={{ width: 24, height: 24 }} />
          </AvatarGroup>
        </Tooltip>
      )}

      {/* {mainUrl ? (
        <Tooltip title={task?.task_users[0]?.user?.full_name} placement="top">
          <AvatarGroup max={4}>
            <Avatar alt="Remy Sharp" src={mainUrl} style={{ width: 24, height: 24 }} />
            <Avatar alt="Travis Howard" src={testerImageUrl} style={{ width: 24, height: 24 }} />
          </AvatarGroup>
        </Tooltip>
      ) : (
        <Tooltip title={task?.task_users[0].user?.full_name} placement="top">
          <NameInitialsAvatar
            name={task?.task_users[0].user?.full_name}
            size={24}
            textSize={10}
            bgColor={warningMainColor}
            borderColor={grey900Color}
          />
        </Tooltip>
      )} */}
    </Stack>
  );

  return (
    <>
      <Draggable draggableId={task.id} index={index}>
        {(provided, snapshot) => (
          <Paper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={openDetails.onTrue}
            sx={{
              width: 1,
              borderRadius: 1.5,
              overflow: 'hidden',
              position: 'relative',
              bgcolor: 'background.default',
              display: 'table',
              boxShadow: theme.customShadows.z1,
              '&:hover': {
                boxShadow: theme.customShadows.z20,
              },
              ...(openDetails.value && {
                boxShadow: theme.customShadows.z20,
              }),
              ...(snapshot.isDragging && {
                boxShadow: theme.customShadows.z20,
                ...bgBlur({
                  opacity: 0.48,
                  color: theme.palette.background.default,
                }),
              }),
              ...sx,
            }}
            {...other}
          >
            <Stack spacing={2} sx={{ px: 2, py: 2.5, position: 'relative' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* {renderPriority} {task?.watchers?.length <= 0 ? watchTaskDetail : WatchButton}{' '} */}
                <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', alignItems: 'center' }}>
                  {task?.labels?.map((label) => (
                    <Tooltip title={`Task label is ${label?.name}`} placement="top" key={task.label}>
                      <Typography
                        key={label?.id}
                        sx={{
                          top: 10,
                          left: 10,
                          padding: '2px 8px',
                          fontSize: 14,
                          borderRadius: '6px',
                          ...{
                            // color: label?.color,
                            backgroundColor: label?.color,
                            opacity: 4,
                          },
                        }}
                      >
                        <span style={{ color: 'white', textTransform: 'lowercase' }}> {label?.name}</span>
                      </Typography>
                    </Tooltip>
                  ))}
                  {renderPriority}
                </div>

                {/* {isWatching(task)} */}
                <Stack spacing={1} direction="row" justifyContent="end" alignItems="top">
                  {user?.roles[0]?.id === 2 ? '' : editTaskDetail}
                  {column?.name !== 'Completed' && isWatching(task)}
                </Stack>
              </div>

              {task?.ticket && (
                <Tooltip title={`Task ticket is ${task?.title}`} placement="top" key={task.title}>
                  <Typography
                    variant="paragraph"
                    color="text.secondary"
                    style={{
                      fontSize: '12px',
                      backgroundColor: palette.light.background.neutral,
                      width: 'fit-content',
                      padding: '5px 10px',
                    }}
                  >
                    {task?.ticket}
                  </Typography>
                </Tooltip>
              )}
              {/* {!!task.attachments.length && renderImg} */}

              <Tooltip title={task.title?.length > 40 ? `Task title: ${task.title}` : 'Task title'} placement="top-end">
                <Typography variant="subtitle2" sx={{ width: 220 }}>
                  {truncateText(task.title, 40)}
                </Typography>
              </Tooltip>
              {renderInfo}
            </Stack>
          </Paper>
        )}
      </Draggable>
      <KanbanWatchPopup open={openWatchPopup} onClose={handleWatchClose} task={task} callback={callBack} />

      <KanbanDetails
        task={task}
        column={column}
        openDetails={openDetails.value}
        onCloseDetails={openDetails.onFalse}
        onUpdateTask={onUpdateTask}
        onDeleteTask={onDeleteTask}
      />
    </>
  );
}

KanbanTaskItem.propTypes = {
  index: PropTypes.number,
  column: PropTypes.object,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
  sx: PropTypes.object,
  task: PropTypes.object,
};
