import { AnimatePresence, m } from 'framer-motion';
import { useEffect, useState } from 'react';
// @mui
import { Backdrop, Divider, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
// eslint-disable-next-line import/extensions
import { useSelector } from 'react-redux';
// utils
// eslint-disable-next-line import/extensions
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import cssStyles from '../../utils/cssStyles';
// config
// eslint-disable-next-line import/extensions
// import { NAVBAR, defaultSettings } from ';
//
// eslint-disable-next-line import/extensions
import Iconify from '../Iconify';
// eslint-disable-next-line import/extensions
import Scrollbar from '../Scrollbar';
// eslint-disable-next-line import/extensions
//
// eslint-disable-next-line import/extensions
// import ToggleButton from './ToggleButton';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
    ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    width: window.innerWidth <= 450 ? '55vw' : '25vw',
    flexDirection: 'column',
    margin: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 3,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: `-24px 12px 32px -4px ${alpha(
        theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
        0.16
    )}`,
}));

// ----------------------------------------------------------------------

export default function FilterTaskToday({ setProjectID, setUserID, priorities, project, setPrioritieId, users, StatusId, setDate, setPage }) {
    const [open, setOpen] = useState(false);
    const params = useParams();
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token, user } = userDetails;
    const location = useLocation()
    const [uservalue, setUserVlaue] = useState();
    const [projectValue, setProjectValue] = useState(search.get("project_id"));
    const [prioritieValue, setPrioritieValue] = useState(search.get("priority"))

    const handleUser = (e) => {
        setPage(0);
        setUserID(e.target.value);
        setUserVlaue(e?.target?.value);

        setProjectID('');
        setProjectValue('');
        navigate(`${location.pathname}?user_id=${e.target?.value}&project_id=${''}&priority=${search.get("priority") ? search.get("priority") : ''}`);
    };

    const handleProject = (e) => {
        // eslint-disable-next-line no-debugger

        setPage(0);
        setProjectValue(e.target.value);
        setProjectID(e.target.value);
        navigate(`${location.pathname}?user_id=${search.get("user_id") ? search.get("user_id") : ''}&project_id=${e.target?.value}&priority=${search.get("priority") ? search.get("priority") : ''}`);
        // setProjectID(e.target.value);
    };

    const handlePrioritiyID = (e) => {
        // eslint-disable-next-line no-debugger
        setPage(0);
        setPrioritieValue(e.target.value);
        setPrioritieId(e.target.value);
        navigate(`${location.pathname}?user_id=${search.get("user_id") ? search.get("user_id") : ''}&project_id=${search.get("project_id") ? search.get("project_id") : ''}&priority=${e.target?.value}`);
    };


    const handleReset = () => {
        setPage(0);

        setUserVlaue('');
        setProjectValue('');
        setPrioritieValue('');

        setProjectID('');
        setUserID('');
        setPrioritieId('');

        navigate(`${location.pathname}`)
    };


    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [open]);

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Backdrop
                open={open}
                onClick={handleClose}
                sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            />

            {!open && (
                <Iconify
                    onClick={() => setOpen(true)}
                    icon={'material-symbols:filter-alt-sharp'}
                    className="Filter "
                    width={30}
                    height={30}
                    sx={{ mb: 2 }}
                />
            )}

            <AnimatePresence>
                {open && (
                    <>
                        <RootStyle>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    Filter Task
                                </Typography>

                                <IconButton onClick={handleReset}>
                                    <Iconify icon={'ic:round-refresh'} width={20} height={20} />
                                </IconButton>

                                <IconButton onClick={handleClose}>
                                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                                </IconButton>
                            </Stack>

                            <Divider sx={{ borderStyle: 'dashed' }} />

                            <Scrollbar sx={{ flexGrow: 1 }}>
                                <Stack spacing={3} sx={{ p: 3 }}>
                                    <Stack spacing={2}>

                                        {/* User */}
                                        <TextField
                                            fullWidth
                                            select
                                            label="User"
                                            className="dropdown_container"
                                            defaultValue={''}
                                            onChange={handleUser}
                                            value={uservalue}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                },
                                            }}
                                            sx={{
                                                maxWidth: { sm: 240 },
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {users &&
                                                users?.map((option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        value={option?.id}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {option?.first_name} {option?.last_name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>

                                        {/* Project */}
                                        <TextField
                                            fullWidth
                                            select
                                            label="Project"
                                            className="dropdown_container"
                                            defaultValue={''}
                                            onChange={handleProject}
                                            value={projectValue}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                },
                                            }}
                                            sx={{
                                                maxWidth: { sm: 240 },
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {project &&
                                                project?.map((option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        value={option?.id}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {option?.title}
                                                    </MenuItem>
                                                ))}
                                        </TextField>

                                        {/* priority */}
                                        <TextField
                                            fullWidth
                                            select
                                            label="Priroty"
                                            className="dropdown_container"
                                            onChange={handlePrioritiyID}
                                            value={prioritieValue}
                                            defaultValue={''}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                                },
                                            }}
                                            sx={{
                                                maxWidth: { sm: 240 },
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {priorities &&
                                                priorities?.map((option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        value={option?.id}
                                                        sx={{
                                                            mx: 1,
                                                            my: 0.5,
                                                            borderRadius: 0.75,
                                                            typography: 'body2',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {option?.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </Stack>
                                </Stack>
                            </Scrollbar>
                        </RootStyle>
                    </>
                )}
            </AnimatePresence>
        </>
    );
}
