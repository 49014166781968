import { combineReducers } from 'redux';
import dashBoardReducer from './dashboardReducer';
import LoginReducer from './loginReducer';
import ReviewReducer from './reviewReducer';

const rootReducer = combineReducers({
  loginReducer: LoginReducer,
  // eslint-disable-next-line object-shorthand
  dashBoardReducer: dashBoardReducer,
  reviewReducer: ReviewReducer,
});

export default rootReducer;
