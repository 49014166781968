import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    Tab,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Tooltip
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import FilterTaskSelf from 'src/components/filterModal/FilterTaskSelf';
import FilterTaskModal from 'src/components/filterModal/filterTaskModal';
import ColorInfo from 'src/components/table/ColorInfo';
import { TABLE_HEAD, TABLE_HEAD_ADMIN } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
// sections
import { TaskTableRow, TaskTableToolbar } from '../../sections/@dashboard/task/list';


const ROLE_OPTIONS = [
    'all',
    'ux designer',
    'full stack designer',
    'backend developer',
    'project manager',
    'leader',
    'ui designer',
    'ui/ux designer',
    'front end developer',
    'full stack developer',
];

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function TabTasks({ roleId }) {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { themeStretch } = useSettings();

    const navigate = useNavigate();
    // get redux state
    const { userDetails , userList  , priorityList  } = useSelector((state) => state.loginReducer);
    const { token, user } = userDetails;

    const [tableData, setTableData] = useState([]);

    const [filterTitle, setFilterTitle] = useState('');

    const [filterDeadline, setFilterDeadline] = useState('all');
    const params = useParams();

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
    const [loading, setLoading] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [count, setCount] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
    const [project, setProject] = useState();
    const [users, setUser] = useState();
    const [userID, setUserID] = useState('');
    const [projectId, setProjectId] = useState('');
    const [dateFilter, setDate] = useState();
    const [StatusId, setStatusId] = useState(params?.status ? params?.status : 1);
    const [status, setStatus] = useState([
        {
            "id": "1",
            "name": "Not started",
            "color": "info",
            "is_default": 1,
            "created_at": "2023-01-03T06:47:28.000000Z"
        },
        {
            "id": "2",
            "name": "In Progress",
            "color": "warning",
            "is_default": 1,
            "created_at": "2023-01-03T06:47:29.000000Z"
        },
        {
            "id": "3",
            "name": "On Hold",
            "color": "primary",
            "is_default": 1,
            "created_at": "2023-01-03T06:47:29.000000Z"
        },
        {
            "id": "4",
            "name": "In Review",
            "color": "secondary",
            "is_default": 1,
            "created_at": "2023-01-03T06:47:29.000000Z"
        },
        {
            "id": "5",
            "name": "Completed",
            "color": "success",
            "is_default": 1,
            "created_at": "2023-01-03T06:47:30.000000Z"
        },
    ])
    const [priorities, setPriorities] = useState()
    const [prioritieId, setPrioritieId] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const handleFilterName = (filterTitle) => {
        setFilterTitle(filterTitle);
        setPage(0);
    };

    const handleFilterRole = (event) => {
        setFilterDeadline(event.target.value);
    };

    const getTasks = async () => {
        setLoading(true);
        const response = await AxiosAll('get', `/tasks?page=${page + 1}&query=${filterTitle}&project_id=${searchParams.get("project_id") ? searchParams.get("project_id") : ''}&user_id=${userID || (searchParams.get("user") ? searchParams.get("user") : '')}&status=${StatusId || (searchParams.get("status") ? searchParams.get("status") : '')}&priority=${searchParams.get("priority") ? searchParams.get("priority") : ''}&due_date=${searchParams.get("due_date") ? searchParams.get("due_date") : ''}`, {}, token);

        const { data } = response;

        if (data?.statusCode === 200) {
            setLoading(false);
            setTableData(data?.data?.data);
            setCount(response?.data?.data?.total);
        }
    };

    // const getUser = async () => {
    //     setUser([]);
    //     const response = await AxiosAll('get', '/common/users', {}, token);
    //     if (response?.status === 200) {
    //         setUser(response?.data);
    //     }
    // };

    const getProject = async () => {
        setProject([]);
        const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
        if (response?.status === 200) {
            setProject(response?.data);
        }
    };

    const getStatus = async () => {
        setStatus([]);
        const response = await AxiosAll('get', `/task-statuses`, {}, token);
        if (response?.status === 200) {
            setStatus(response?.data?.data?.data);
        }
    };

    // const getPrioritie = async () => {
    //     setPriorities([]);
    //     const response = await AxiosAll('get', `/task-priorities`, {}, token);
    //     if (response?.status === 200) {
    //         setPriorities(response?.data?.data?.data);
    //     }
    // };

    const addToFavourite = async (id) => {
        const response = await AxiosAll('post', `/mark-as-favorite/${id}`, {}, token);
        if (response?.status === 200) {
            enqueueSnackbar(response?.data?.message, { variant: 'success' });
            getTasks();
        }
    };

    useEffect(() => {
        getTasks();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterTitle, projectId, userID, StatusId, prioritieId, dateFilter, searchParams]);

    const handleConfirmDelete = () => {
        if (selected.length > 0 && multipleDeleteMode) {
            handleDeleteRows(selected);
        } else {
            handleDeleteRow(deleteId);
        }
        setDeleteDialog(false);
    };
    const handleDeleteRow = async (id) => {
        const res = await AxiosAll('delete', `/tasks/${id}`, {}, token)
        const { data } = res;
        if (data?.statusCode === 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            getTasks();
        }
        else if (data?.statusCode === 419) {
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    };

    // bulk delete is yet to complete.
    const handleDeleteRows = async (selected) => {
        const response = await AxiosAll('delete', `/tasks`, { ids: selected }, token);
        const { data } = response;
        if (data?.statusCode === 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            setSelected([]);
            getTasks();
        } else {
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    };

    const handleEditRow = (id) => {
        navigate(`/tasks/edit/${id}`);
    };
    const handleAddSub = (id) => {
        navigate(`/tasks/add-sub/${id}`);
    };

    // useEffect(() => {
    //     getUser();
    // }, []);

    useEffect(() => {
        getProject();
    }, [userID]);

    useEffect(() => {
        // getStatus();
    }, []);

    // useEffect(() => {
    //     getPrioritie()
    // }, [])

    const isNotFound =
        (!tableData?.length && !!filterTitle) ||
        (!tableData?.length && !!filterDeadline) ||
        (!tableData?.length && !!filterStatus);

    const [value, setValue] = useState(params?.status || "1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setStatusId(newValue)
    };

    // In this component status list is hardcoded bcz we want orderwise data
    return (
        <Page title="TabTasks : List">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Task List"
                    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Task List' }]}
                    action={roleId === 1 ?
                        <>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to={'/tasks/add-task'}
                                startIcon={<Iconify icon={'eva:plus-fill'} />}
                            >
                                Add Task
                            </Button>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to={'/bulk-upload'}
                                sx={{ ml: '0.5rem' }}
                            >
                                Bulk Upload
                            </Button>
                        </>
                        : null
                    }
                />

                {user?.roles[0]?.id === 1 ? <FilterTaskModal
                    setProjectID={setProjectId}
                    setDate={setDate}
                    dateFilter={dateFilter}
                    setUserID={setUserID}
                    projectId={projectId}
                    userID={userID}
                    project={project}
                    status={status}
                    StatusId={StatusId}
                    users={userList}
                    priorities={priorityList}
                    setPrioritieId={setPrioritieId}
                    setPage={setPage}
                /> : <FilterTaskSelf
                    setStatusId={setStatusId}
                    setProjectID={setProjectId}
                    setDate={setDate}
                    dateFilter={dateFilter}
                    projectId={projectId}
                    userID={userDetails?.user?.id}
                    project={project}
                    status={status}
                    StatusId={StatusId}
                    users={userList}
                    priorities={priorityList}
                    setPrioritieId={setPrioritieId}
                    setPage={setPage}
                />}

                <Card>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ px: 2, bgcolor: 'background.neutral' }}>
                                    {status?.map((item, index) => (<Tab label={item?.name} value={item?.id} key={index} />))}
                                </TabList>
                            </Box>
                            {/* tab 1 */}
                            <TabPanel value="1"><>
                                <Divider />

                                <ConfirmDialogue
                                    open={deleteDialog}
                                    loading={loading}
                                    onClose={() => setDeleteDialog(false)}
                                    onConfirm={() => handleConfirmDelete(deleteId)}
                                    title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                                        }?`}
                                    content="This action cannot be undone. Please confirm to proceed."
                                />
                                <TaskTableToolbar
                                    filterTitle={filterTitle}
                                    filterDeadline={filterDeadline}
                                    onFilterName={handleFilterName}
                                    onFilterRole={handleFilterRole}
                                    optionsRole={ROLE_OPTIONS}
                                    search="task"
                                />

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                        {selected.length > 0 && (
                                            <TableSelectedActions
                                                dense={dense}
                                                numSelected={selected.length}
                                                rowCount={tableData?.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                                actions={
                                                    <Tooltip title="Delete">
                                                        <IconButton color="primary" onClick={() => {
                                                            setDeleteDialog(true);
                                                            setMultipleDeleteMode(true);
                                                        }}
                                                        >
                                                            <Iconify icon={'eva:trash-2-outline'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            />
                                        )}

                                        <Table size={dense ? 'small' : 'medium'}>
                                            <TableHeadCustom
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={user?.roles[0]?.id === 1 ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                                                rowCount={tableData?.length}
                                                numSelected={selected.length}
                                                onSort={onSort}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            {loading ? (
                                                <>
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {tableData?.map((row) => (
                                                        <TaskTableRow
                                                            key={row.id}
                                                            row={row}
                                                            setDeleteDialog={setDeleteDialog}
                                                            setMultipleDeleteMode={setMultipleDeleteMode}
                                                            setDeleteId={setDeleteId}
                                                            selected={selected.includes(row.id)}
                                                            onSelectRow={() => onSelectRow(row.id)}
                                                            addToFavourite={() => addToFavourite(row.id)}
                                                            // onDeleteRow={() => handleDeleteRow(row.id)}
                                                            onEditRow={() => handleEditRow(row.id)}
                                                            onAddSub={() => handleAddSub(row?.id)}
                                                            getTasks={() => getTasks()}
                                                        />
                                                    ))}

                                                    <TableNoData isNotFound={isNotFound} />
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <Box sx={{ position: 'relative' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />
                                    <ColorInfo />
                                    <FormControlLabel
                                        control={<Switch checked={dense} onChange={onChangeDense} />}
                                        label="Dense"
                                        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                    />
                                </Box>
                            </></TabPanel>

                            {/* Tab 2 */}
                            <TabPanel value="2"><>
                                <Divider />

                                <ConfirmDialogue
                                    open={deleteDialog}
                                    loading={loading}
                                    onClose={() => setDeleteDialog(false)}
                                    onConfirm={() => handleConfirmDelete(deleteId)}
                                    title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                                        }?`}
                                    content="This action cannot be undone. Please confirm to proceed."
                                />
                                <TaskTableToolbar
                                    filterTitle={filterTitle}
                                    filterDeadline={filterDeadline}
                                    onFilterName={handleFilterName}
                                    onFilterRole={handleFilterRole}
                                    optionsRole={ROLE_OPTIONS}
                                    search="task"
                                />

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                        {selected.length > 0 && (
                                            <TableSelectedActions
                                                dense={dense}
                                                numSelected={selected.length}
                                                rowCount={tableData?.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                                actions={
                                                    <Tooltip title="Delete">
                                                        <IconButton color="primary" onClick={() => {
                                                            setDeleteDialog(true);
                                                            setMultipleDeleteMode(true);
                                                        }}
                                                        >
                                                            <Iconify icon={'eva:trash-2-outline'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            />
                                        )}

                                        <Table size={dense ? 'small' : 'medium'}>
                                            <TableHeadCustom
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={user?.roles[0]?.id === 1 ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                                                rowCount={tableData?.length}
                                                numSelected={selected.length}
                                                onSort={onSort}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            {loading ? (
                                                <>
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {tableData?.map((row) => (
                                                        <TaskTableRow
                                                            key={row.id}
                                                            row={row}
                                                            setDeleteDialog={setDeleteDialog}
                                                            setMultipleDeleteMode={setMultipleDeleteMode}
                                                            setDeleteId={setDeleteId}
                                                            selected={selected.includes(row.id)}
                                                            onSelectRow={() => onSelectRow(row.id)}
                                                            addToFavourite={() => addToFavourite(row.id)}
                                                            // onDeleteRow={() => handleDeleteRow(row.id)}
                                                            onEditRow={() => handleEditRow(row.id)}
                                                            onAddSub={() => handleAddSub(row?.id)}
                                                            getTasks={() => getTasks()}
                                                        />
                                                    ))}

                                                    <TableNoData isNotFound={isNotFound} />
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <Box sx={{ position: 'relative' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />
                                    <ColorInfo />

                                    <FormControlLabel
                                        control={<Switch checked={dense} onChange={onChangeDense} />}
                                        label="Dense"
                                        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                    />
                                </Box>
                            </></TabPanel>

                            {/* Tab 3 */}
                            <TabPanel value="3"><>
                                <Divider />

                                <ConfirmDialogue
                                    open={deleteDialog}
                                    loading={loading}
                                    onClose={() => setDeleteDialog(false)}
                                    onConfirm={() => handleConfirmDelete(deleteId)}
                                    title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                                        }?`}
                                    content="This action cannot be undone. Please confirm to proceed."
                                />
                                <TaskTableToolbar
                                    filterTitle={filterTitle}
                                    filterDeadline={filterDeadline}
                                    onFilterName={handleFilterName}
                                    onFilterRole={handleFilterRole}
                                    optionsRole={ROLE_OPTIONS}
                                    search="task"
                                />

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                        {selected.length > 0 && (
                                            <TableSelectedActions
                                                dense={dense}
                                                numSelected={selected.length}
                                                rowCount={tableData?.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                                actions={
                                                    <Tooltip title="Delete">
                                                        <IconButton color="primary" onClick={() => {
                                                            setDeleteDialog(true);
                                                            setMultipleDeleteMode(true);
                                                        }}
                                                        >
                                                            <Iconify icon={'eva:trash-2-outline'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            />
                                        )}

                                        <Table size={dense ? 'small' : 'medium'}>
                                            <TableHeadCustom
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={user?.roles[0]?.id === 1 ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                                                rowCount={tableData?.length}
                                                numSelected={selected.length}
                                                onSort={onSort}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            {loading ? (
                                                <>
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {tableData?.map((row) => (
                                                        <TaskTableRow
                                                            key={row.id}
                                                            row={row}
                                                            setDeleteDialog={setDeleteDialog}
                                                            setMultipleDeleteMode={setMultipleDeleteMode}
                                                            setDeleteId={setDeleteId}
                                                            selected={selected.includes(row.id)}
                                                            onSelectRow={() => onSelectRow(row.id)}
                                                            addToFavourite={() => addToFavourite(row.id)}
                                                            onEditRow={() => handleEditRow(row.id)}
                                                            onAddSub={() => handleAddSub(row?.id)}
                                                            getTasks={() => getTasks()}
                                                        />
                                                    ))}

                                                    <TableNoData isNotFound={isNotFound} />
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <Box sx={{ position: 'relative' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />
                                    <ColorInfo />

                                    <FormControlLabel
                                        control={<Switch checked={dense} onChange={onChangeDense} />}
                                        label="Dense"
                                        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                    />
                                </Box>
                            </></TabPanel>

                            {/* Tab 4 */}
                            <TabPanel value="4"><>
                                <Divider />

                                <ConfirmDialogue
                                    open={deleteDialog}
                                    loading={loading}
                                    onClose={() => setDeleteDialog(false)}
                                    onConfirm={() => handleConfirmDelete(deleteId)}
                                    title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                                        }?`}
                                    content="This action cannot be undone. Please confirm to proceed."
                                />
                                <TaskTableToolbar
                                    filterTitle={filterTitle}
                                    filterDeadline={filterDeadline}
                                    onFilterName={handleFilterName}
                                    onFilterRole={handleFilterRole}
                                    optionsRole={ROLE_OPTIONS}
                                    search="task"
                                />

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                        {selected.length > 0 && (
                                            <TableSelectedActions
                                                dense={dense}
                                                numSelected={selected.length}
                                                rowCount={tableData?.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                                actions={
                                                    <Tooltip title="Delete">
                                                        <IconButton color="primary" onClick={() => {
                                                            setDeleteDialog(true);
                                                            setMultipleDeleteMode(true);
                                                        }}
                                                        >
                                                            <Iconify icon={'eva:trash-2-outline'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            />
                                        )}

                                        <Table size={dense ? 'small' : 'medium'}>
                                            <TableHeadCustom
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={user?.roles[0]?.id === 1 ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                                                rowCount={tableData?.length}
                                                numSelected={selected.length}
                                                onSort={onSort}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            {loading ? (
                                                <>
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {tableData?.map((row) => (
                                                        <TaskTableRow
                                                            key={row.id}
                                                            row={row}
                                                            setDeleteDialog={setDeleteDialog}
                                                            setMultipleDeleteMode={setMultipleDeleteMode}
                                                            setDeleteId={setDeleteId}
                                                            selected={selected.includes(row.id)}
                                                            onSelectRow={() => onSelectRow(row.id)}
                                                            addToFavourite={() => addToFavourite(row.id)}
                                                            onEditRow={() => handleEditRow(row.id)}
                                                            onAddSub={() => handleAddSub(row?.id)}
                                                            getTasks={() => getTasks()}
                                                        />
                                                    ))}

                                                    <TableNoData isNotFound={isNotFound} />
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <Box sx={{ position: 'relative' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />
                                    <ColorInfo />

                                    <FormControlLabel
                                        control={<Switch checked={dense} onChange={onChangeDense} />}
                                        label="Dense"
                                        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                    />
                                </Box>
                            </></TabPanel>

                            {/* Tab 5 */}
                            <TabPanel value="5"><>
                                <Divider />

                                <ConfirmDialogue
                                    open={deleteDialog}
                                    loading={loading}
                                    onClose={() => setDeleteDialog(false)}
                                    onConfirm={() => handleConfirmDelete(deleteId)}
                                    title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                                        }?`}
                                    content="This action cannot be undone. Please confirm to proceed."
                                />
                                <TaskTableToolbar
                                    filterTitle={filterTitle}
                                    filterDeadline={filterDeadline}
                                    onFilterName={handleFilterName}
                                    onFilterRole={handleFilterRole}
                                    optionsRole={ROLE_OPTIONS}
                                    search="task"
                                />

                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                        {selected.length > 0 && (
                                            <TableSelectedActions
                                                dense={dense}
                                                numSelected={selected.length}
                                                rowCount={tableData?.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                                actions={
                                                    <Tooltip title="Delete">
                                                        <IconButton color="primary" onClick={() => {
                                                            setDeleteDialog(true);
                                                            setMultipleDeleteMode(true);
                                                        }}
                                                        >
                                                            <Iconify icon={'eva:trash-2-outline'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            />
                                        )}

                                        <Table size={dense ? 'small' : 'medium'}>
                                            <TableHeadCustom
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={user?.roles[0]?.id === 1 ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                                                rowCount={tableData?.length}
                                                numSelected={selected.length}
                                                onSort={onSort}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        tableData?.map((row) => row.id)
                                                    )
                                                }
                                            />
                                            {loading ? (
                                                <>
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                    <TableSkeleton />
                                                </>
                                            ) : (
                                                <TableBody>
                                                    {tableData?.map((row) => (
                                                        <TaskTableRow
                                                            key={row.id}
                                                            row={row}
                                                            setDeleteDialog={setDeleteDialog}
                                                            setMultipleDeleteMode={setMultipleDeleteMode}
                                                            setDeleteId={setDeleteId}
                                                            selected={selected.includes(row.id)}
                                                            onSelectRow={() => onSelectRow(row.id)}
                                                            addToFavourite={() => addToFavourite(row.id)}
                                                            onEditRow={() => handleEditRow(row.id)}
                                                            onAddSub={() => handleAddSub(row?.id)}
                                                            getTasks={() => getTasks()}
                                                        />
                                                    ))}

                                                    <TableNoData isNotFound={isNotFound} />
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <Box sx={{ position: 'relative' }}>
                                    <TablePagination
                                        rowsPerPageOptions={[20]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />
                                    <ColorInfo />

                                    <FormControlLabel
                                        control={<Switch checked={dense} onChange={onChangeDense} />}
                                        label="Dense"
                                        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                    />
                                </Box>
                            </></TabPanel>

                        </TabContext >
                    </Box >
                </Card >
            </Container >
        </Page >
    );
}
