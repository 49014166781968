import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
// _mock
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { FormProvider, RHFSelect } from '../../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AssignUsers.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectData: PropTypes.object,
  userDropdownData: PropTypes.array,
};

export default function AssignUsers({ open, onClose, projectData, userDropdownData }) {
  const [userDropdown, setUserDropdown] = useState([]);
  const [users, setUsers] = useState([]);

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const { enqueueSnackbar } = useSnackbar();

  const AddUserSchema = Yup.object().shape({
    user: Yup.array().required('User is required'),
  });


  const defaultValues = {
    project: projectData?.title,
    users: [],
  };

  const methods = useForm({
    resolver: yupResolver(AddUserSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (projectData) {
      const newUserData = projectData?.users?.map((item) => {
        const { first_name, last_name, id } = item;
        return { id, label: `${first_name} ${last_name}` };
      });
      setValue('users', newUserData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  useEffect(() => {
    // remove users from dropdown if already selected and add if unselected
    const newData = userDropdownData.filter((item) => {
      const found = values?.users?.find((user) => user.id === item.id);
      if (found) {
        return false;
      }
      return true;
    });
    setUserDropdown(newData);
    setUsers(values?.users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.users, userDropdownData]);

  const onSubmit = async (data) => {
    reset();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <DialogTitle>Update Users</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>Project</LabelStyle>
              <RHFSelect name="project" label="Project" disabled>
                <option>{projectData?.title}</option>
              </RHFSelect>
            </div>
            <div>
              <LabelStyle>Users</LabelStyle>
              <Controller
                name="users"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    freeSolo
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionDisabled={(option) => option}
                    options={userDropdown}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip key={option.id} label={option.label} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} />}
                  />
                )}
              />
            </div>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              onClose();
              reset();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
