import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Stack,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import MobilePicker from 'src/components/Timepicker';
import { findTimeDifference } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

EditLogsForm.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onNextStep: PropTypes.func,
    onCreateBilling: PropTypes.func,
    editTask: PropTypes.object,
};

export default function EditLogsForm({ open, onClose, editTask }) {
    const NewAddressSchema = Yup.object().shape({
        // time: Yup.string().min(5, 'Must be in the form of HH:MM').required('Time is required'),
        comments: Yup.string().required('Description  is required'),
        date: Yup.string().required('date is required'),
        // task: Yup.string().required('task is required'),
    });
    const typeData = [
        { id: 'task_log', title: 'Task Log' },
        { id: 'discussion', title: 'Discussion' },
        { id: 'internal_meeting', title: 'Internal Meeting' },
        { id: 'external_meeting', title: 'External Meeting' },
        { id: 'holiday', title: 'Holiday' },
        { id: 'break', title: 'Break' },
        { id: 'celebration', title: 'Celebration' },
        { id: 'down_time', title: 'Down Time' },
    ]
    const defaultValues = {
        // time: '',
        comments: '',
        date: '',
        task: '',
        project: '',
        type: ''
    };

    const defaultEditValues = useMemo(
        () => ({
            // time: editTask?.time_format,
            comments: editTask?.comment,
            date: editTask?.date,
            task: editTask?.task?.id || '',
            project: editTask?.task?.project_id || editTask?.project_id,
            type: editTask?.type
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editTask]
    );
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const [project, setProject] = useState([]);
    const [projectId, setProjectId] = useState();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("00:00");
    const methods = useForm({
        resolver: yupResolver(NewAddressSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        handleSubmit,
        control,
        formState: { isSubmitting },
    } = methods;

    const values = watch()

    const handleOnClose = () => {
        reset(defaultValues)
        onClose()
        setStartTime("00:00");
        setEndTime("00:00")
    }

    useEffect(() => {
        if (editTask) {
            reset(defaultEditValues);
        }
    }, [editTask]);

    useEffect(() => {
        if (editTask && project?.length) {
            reset(defaultEditValues);
            setProjectId(editTask?.project_id)

        }
    }, [editTask && project]);

    const afterCheck = async (value) => {
        if (editTask?.id) {
            const data = {
                task_id: value?.task || '',
                comment: value?.comments,
                date: value?.date,
                // time_format: value?.time,
                start_time: startTime,
                end_time: endTime,
            };
            try {
                const response = await AxiosAll('post', `/task-time-logs/${editTask?.id}`, data, token);
                if (response?.status === 200) {
                    handleOnClose()
                    navigate('/logs');
                    onClose()
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error' });
                }
            } catch (error) {
                enqueueSnackbar(error?.data?.message, { variant: 'error' });
            }
            setLoading(false);
        }
        reset()
    }

    const onSubmit = async (value) => {
        setLoading(true);
        if (startTime === "00:00" || endTime === "00:00" || startTime === endTime) {
            enqueueSnackbar("Start time or End time needs to change.", { variant: 'error' });
        } else if (startTime > endTime) {
            enqueueSnackbar("Start time is greater than End time.", { variant: 'error' });
        } else {
            afterCheck(value);
        }
        setLoading(false);
    };

    const getProject = async () => {
        const response = await AxiosAll('get', '/common/projects', {}, token);
        if (response?.status === 200) {
            setProject(response?.data);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        open && getProject();
    }, [open]);

    useEffect(() => {
        if (open) {
            setEndTime(editTask?.end_time);
            setStartTime(editTask?.start_time);
        }
    }, [open])

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
            <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    {editTask ? "Edit Log" : "Add Log"}
                </div>
                <div> Time Spend :  &nbsp;{findTimeDifference({ start: startTime, end: endTime })}  </div></DialogTitle>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={3}>
                        <div>
                            <LabelStyle>Log Type</LabelStyle>
                            <RHFSelect disabled={!!editTask} name="type" >
                                {/* <option value="">Select Project</option> */}
                                {typeData?.map((data, index) => (
                                    <option key={index} value={data?.id}>
                                        {data?.title}
                                    </option>
                                ))}
                            </RHFSelect>
                        </div>

                        {(values?.type === "discussion" || values?.type === "external_meeting" || values?.type === "task_log") && <div>
                            <LabelStyle>Project*</LabelStyle>
                            <RHFSelect disabled={!!editTask} name="project" >
                                {/* <option value="">Select Project</option> */}
                                {project?.map((data, index) => (
                                    <option key={index} value={data?.id}>
                                        {data?.title}
                                    </option>
                                ))}
                            </RHFSelect>
                        </div>}

                        <div>
                            <LabelStyle>Date</LabelStyle>
                            <RHFTextField name="date" type="date" />
                        </div>

                        <div>
                            <LabelStyle>Description</LabelStyle>
                            {/* <RHFTextField name="description" label="Write Something Awesome..." multiline rows={4} /> */}
                            <RHFEditor simple name="comments" />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ flexDirection: 'row' }}>
                                Start Time :   &nbsp;
                                <MobilePicker time={startTime} handleTime={setStartTime} />
                            </div>
                            <div style={{ flexDirection: 'row' }}>
                                End Time :   &nbsp;
                                <MobilePicker time={endTime} handleTime={setEndTime} />
                            </div>
                        </div>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <LoadingButton type="submit" variant="contained" loading={loading}>
                        Submit
                    </LoadingButton>
                    <Button color="inherit" variant="outlined" onClick={handleOnClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}
