import { capitalCase } from 'change-case';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
// eslint-disable-next-line import/no-unresolved, import/extensions
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
// eslint-disable-next-line import/no-unresolved, import/extensions
// import useSettings from '../../hooks/useSettings';
// _mock_
// eslint-disable-next-line import/no-unresolved, import/extensions
import StartupNewEditForm from 'src/sections/@dashboard/startup/StartupNewEditForm';
// components
// eslint-disable-next-line import/no-unresolved, import/extensions
// eslint-disable-next-line import/no-unresolved, import/extensions
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
// sections

// ----------------------------------------------------------------------

export default function StartupCreate() {
  // const { themeStretch } = useSettings();

  const params = useParams();
  const { pathname } = useLocation();
  const { name = '' } = useParams();
  const isEdit = pathname.includes('edit');
  const [currentStartup, setCurrentStartup] = useState();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const getStartup = async () => {
    if (isEdit) {
      const response = await AxiosAll('get', `/startups/${params.id}`, null, userDetails.token);
      const { data } = response;
      setCurrentStartup(data?.data);
    }
    return {};
  };

  useEffect(() => {
    getStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Startup: Create a new Startup">
      <Container maxWidth={true ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new startup' : 'Edit startup'}
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Startups', href: '/startup/startup-list' },
            { name: !isEdit ? 'New Startup' : capitalCase(name) },
          ]}
        />

        <StartupNewEditForm isEdit={isEdit} currentStartup={isEdit && currentStartup} />
      </Container>
    </Page>
  );
}
