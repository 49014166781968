import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Tooltip
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import FilterTaskModal from 'src/components/filterModal/filterTaskModal';
import ColorInfo from 'src/components/table/ColorInfo';
import { TABLE_HEAD } from 'src/core';
import { setCount } from 'src/redux/actions/reviewCount';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
// sections
import { TaskTableRow, TaskTableToolbar } from '../../sections/@dashboard/task/list';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = [
    'all',
    'ux designer',
    'full stack designer',
    'backend developer',
    'project manager',
    'leader',
    'ui designer',
    'ui/ux designer',
    'front end developer',
    'full stack developer',
];
// ----------------------------------------------------------------------

export default function TeamTasks() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { themeStretch } = useSettings();

    const navigate = useNavigate();
    // get redux state
    const { userDetails ,userList  , priorityList  } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const { user } = userDetails;

    const [tableData, setTableData] = useState([]);

    const [filterTitle, setFilterTitle] = useState('');

    const [filterDeadline, setFilterDeadline] = useState('all');
    const params = useParams();

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
    const [loading, setLoading] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [total, setTotal] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
    const [project, setProject] = useState();
    const [users, setUser] = useState();
    const [userID, setUserID] = useState('');
    const [projectId, setProjectId] = useState('');
    const [dateFilter, setDate] = useState();
    const [StatusId, setStatusId] = useState(params?.status ? params?.status : '');
    const [status, setStatus] = useState()
    const [priorities, setPriorities] = useState()
    const [prioritieId, setPrioritieId] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const handleFilterName = (filterTitle) => {
        setFilterTitle(filterTitle);
        setPage(0);
    };

    const handleFilterRole = (event) => {
        setFilterDeadline(event.target.value);
    };

    const getTasks = async () => {
        setLoading(true);
        const response = await AxiosAll('get', `/tasks?page=${page + 1}&type=team_task&query=${filterTitle}&project_id=${searchParams.get("project_id") ? searchParams.get("project_id") : ''}&user_id=${userID || (searchParams.get("user") ? searchParams.get("user") : '')}&status=${StatusId || (searchParams.get("status") ? searchParams.get("status") : '')}&priority=${searchParams.get("priority") ? searchParams.get("priority") : ''}&due_date=${searchParams.get("due_date") ? searchParams.get("due_date") : ''}`, {}, token);

        const { data } = response;

        if (data?.statusCode === 200) {
            setLoading(false);
            setTableData(data?.data?.data);
            setTotal(response?.data?.data?.total);
            getCounts();
        }
    };
    const getCounts = async () => {
        const response = await AxiosAll('get', '/counts', {}, token);
        const { data } = response;

        dispatch(setCount(data))
    };
    // const getUser = async () => {
    //     setUser([]);
    //     const response = await AxiosAll('get', '/common/users', {}, token);
    //     if (response?.status === 200) {
    //         setUser(response?.data);
    //     }
    // };

    const getProject = async () => {
        setProject([]);
        const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
        if (response?.status === 200) {
            setProject(response?.data);
        }
    };

    const getStatus = async () => {
        setStatus([]);
        const response = await AxiosAll('get', `/task-statuses`, {}, token);
        if (response?.status === 200) {
            setStatus(response?.data?.data?.data);
        }
    };

    // const getPrioritie = async () => {
    //     setPriorities([]);
    //     const response = await AxiosAll('get', `/task-priorities`, {}, token);
    //     if (response?.status === 200) {
    //         setPriorities(response?.data?.data?.data);
    //     }
    // };

    useEffect(() => {
        getTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterTitle, projectId, userID, StatusId, prioritieId, projectId, searchParams]);


    const handleConfirmDelete = () => {
        if (selected.length > 0 && multipleDeleteMode) {
            handleDeleteRows(selected);
        } else {
            handleDeleteRow(deleteId);
        }
        setDeleteDialog(false);
    };
    const handleDeleteRow = async (id) => {
        const res = await AxiosAll('delete', `/tasks/${id}`, {}, token)
        const { data } = res;
        if (data?.statusCode === 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            getTasks();
        }
        else if (data?.statusCode === 419) {
            enqueueSnackbar(data?.message, { variant: 'error' });
        } else {
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    };

    // bulk delete is yet to complete.
    const handleDeleteRows = async (selected) => {
        const response = await AxiosAll('delete', `/tasks`, { ids: selected }, token);
        const { data } = response;
        if (data?.statusCode === 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            setSelected([]);
            getTasks();
        } else {
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    };

    const handleEditRow = (id) => {
        navigate(`/team-tasks/edit/${id}`);
    };
    const handleAddSub = (id) => {
        navigate(`/tasks/add-sub/${id}`);
    };


    // useEffect(() => {
    //     getUser();
    // }, []);

    useEffect(() => {
        getProject();
    }, [userID]);

    useEffect(() => {
        getStatus();
    }, []);

    // useEffect(() => {
    //     getPrioritie()
    // }, [])


    const denseHeight = dense ? 52 : 72;

    const isNotFound =
        (!tableData.length && !!filterTitle) ||
        (!tableData.length && !!filterDeadline) ||
        (!tableData.length && !!filterStatus);

    return (
        <Page title="Tasks : List">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Team Task List"
                    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Task List' }]}
                    action={
                        <>

                            <Button
                                variant="contained"
                                component={RouterLink}
                                to={'/team-tasks/add-task'}
                                startIcon={<Iconify icon={'eva:plus-fill'} />}
                            >
                                Add Task
                            </Button>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to={'/bulk-upload'}
                                sx={{ ml: '0.5rem' }}
                            // startIcon={<Iconify icon={'eva:plus-fill'} />}
                            >
                                Bulk Upload
                            </Button>
                        </>
                    }
                />
                <FilterTaskModal
                    setStatusId={setStatusId}
                    setProjectID={setProjectId}
                    setDate={setDate}
                    dateFilter={dateFilter}
                    setUserID={setUserID}
                    projectId={projectId}
                    userID={userID}
                    project={project}
                    status={status}
                    StatusId={StatusId}
                    users={userList}
                    priorities={priorityList}
                    setPrioritieId={setPrioritieId}
                    setPage={setPage}
                />
                <Card>
                    <Divider />

                    <ConfirmDialogue
                        open={deleteDialog}
                        loading={loading}
                        onClose={() => setDeleteDialog(false)}
                        onConfirm={() => handleConfirmDelete(deleteId)}
                        title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                            }?`}
                        content="This action cannot be undone. Please confirm to proceed."
                    />
                    <TaskTableToolbar
                        filterTitle={filterTitle}
                        filterDeadline={filterDeadline}
                        onFilterName={handleFilterName}
                        onFilterRole={handleFilterRole}
                        optionsRole={ROLE_OPTIONS}
                        search="task"
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                            {selected.length > 0 && (
                                <TableSelectedActions
                                    dense={dense}
                                    numSelected={selected.length}
                                    rowCount={tableData.length}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                    actions={
                                        <Tooltip title="Delete">
                                            <IconButton color="primary" onClick={() => {
                                                setDeleteDialog(true);
                                                setMultipleDeleteMode(true);
                                            }}
                                            >
                                                <Iconify icon={'eva:trash-2-outline'} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            )}

                            <Table size={dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />
                                {loading ? (
                                    <>
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                    </>
                                ) : (
                                    <TableBody>
                                        {tableData.map((row) => (
                                            <TaskTableRow
                                                teamTask
                                                key={row.id}
                                                row={row}
                                                setDeleteDialog={setDeleteDialog}
                                                setMultipleDeleteMode={setMultipleDeleteMode}
                                                setDeleteId={setDeleteId}
                                                selected={selected.includes(row.id)}
                                                onSelectRow={() => onSelectRow(row.id)}
                                                // onDeleteRow={() => handleDeleteRow(row.id)}
                                                onEditRow={() => handleEditRow(row.id)}
                                                onAddSub={() => handleAddSub(row?.id)}
                                            />
                                        ))}

                                        {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                                        <TableNoData isNotFound={isNotFound} />
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[20]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />
                        <ColorInfo />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label="Dense"
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page >
    );
}
