
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
 const firebaseConfig = {
  apiKey: "AIzaSyCUWRnGKOTSM93KP1kv_1ZaG4nCsHka0zs",
  authDomain: "pms-rsvr-64b20.firebaseapp.com",
  projectId: "pms-rsvr-64b20",
  storageBucket: "pms-rsvr-64b20.appspot.com",
  messagingSenderId: "654063102061",
  appId: "1:654063102061:web:848f2a0d235c8154ccf764",
  measurementId: "G-FFVFQDJW94"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

