import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import moment from 'moment';
import { useNavigate } from 'react-router';
import Label from '../../../components/Label';

// ----------------------------------------------------------------------

TaskTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function TaskTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { title, due_date, task_users, following_task, predecessor_task, task_status, is_due } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const overDue = () => {
    const today = new Date();
    const dueDate = new Date(due_date);
    if (dueDate < today && task_status?.name !== 'Completed') {
      return true;
    }
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      {is_due ? <TableCell
        align="left"
        variant="subtitle2"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/tasks/details/${row?.id}`)}
        noWrap>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={'error'}
          sx={{ textTransform: 'capitalize', height: 'auto', whiteSpace: 'normal', lineHeight: '1.5' }}
        >
          {title}
        </Label>
      </TableCell> : <TableCell
        align="left"
        variant="subtitle2"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/tasks/details/${row?.id}`)}
        noWrap>
        {title}
      </TableCell>}

      <TableCell align="center">
        {predecessor_task ? (
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={predecessor_task?.task_status?.color}
            // color={'primary'}
            sx={{ textTransform: 'capitalize' }}
          >
            <Tooltip title={predecessor_task?.title}>
              <Typography
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/tasks/details/${predecessor_task?.id}`);
                }}
                variant="body2"
                noWrap
              >
                {predecessor_task?.title?.length > 20
                  ? `${predecessor_task?.title?.substring(0, 20)}...`
                  : predecessor_task?.title || ''}
              </Typography>
            </Tooltip>
          </Label>
        ) : (
          <Tooltip title={predecessor_task?.title}>
            <Typography onClick={() => { }} variant="body2" noWrap>
              {predecessor_task?.title?.length > 20
                ? `${predecessor_task?.title?.substring(0, 20)}...`
                : predecessor_task?.title || ''}
            </Typography>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        {following_task ? (
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={following_task?.task_status?.color}
            // color="info"
            sx={{ textTransform: 'capitalize' }}
          >
            <Tooltip title={following_task?.title}>
              <Typography
                variant="body2"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/tasks/details/${following_task?.id}`);
                }}
                noWrap
              >
                {following_task?.title?.length > 20
                  ? `${following_task?.title?.substring(0, 20)}...`
                  : following_task?.title ?? ''}
              </Typography>
            </Tooltip>
          </Label>
        ) : (
          <Tooltip title={following_task?.title}>
            <Typography variant="body2" noWrap>
              {following_task?.title?.length > 20
                ? `${following_task?.title?.substring(0, 20)}...`
                : following_task?.title ?? ''}
            </Typography>
          </Tooltip>
        )}
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={task_status?.color}
          sx={{ textTransform: 'capitalize' }}
        >
          {task_status?.name}
        </Label>
      </TableCell>

      <TableCell align="center">{moment(due_date).format("D MMM")}</TableCell>

      {task_status?.id === 1 ? (
        <TableCell align="center">
          {task_users?.map((userObj) => (
            <Tooltip key={userObj?.id} title={'Task not started'}>
              <Typography
                sx={{
                  cursor: 'default',
                }}
                key={userObj?.id}
                variant="subtitle2"
                noWrap
              >
                {userObj?.user?.first_name} {userObj?.user?.last_name}
              </Typography>
            </Tooltip>
          ))}
        </TableCell>
      ) : (
        <TableCell align="center">
          {task_users?.map((userObj) => (
            <Typography
              sx={{
                cursor: 'pointer',
                px: 1,
                '&:hover': {
                  // boxShadow: (theme) => theme.customShadows.z8,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
                  borderRadius: 1,
                },
              }}
              onClick={() =>
                navigate('/time-sheet', {
                  state: {
                    start_date: row?.start_date,
                    due_date: row?.due_date,
                    userId: userObj?.user?.id,
                  },
                })
              }
              key={userObj?.id}
              variant="subtitle2"
              noWrap
            >
              {userObj?.user?.first_name} {userObj?.user?.last_name}
            </Typography>
          ))}
        </TableCell>
      )}
      {/* <TableCell align="center">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell> */}
    </TableRow>
  );
}
