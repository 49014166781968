
import { AnimatePresence, m } from 'framer-motion';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Autocomplete, Backdrop, Button, Chip, DialogActions, DialogContent, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/services/axiosAll';
import cssStyles from 'src/utils/cssStyles';
import { FormProvider, RHFEditor, RHFTextField } from '../../components/hook-form';


// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
    ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    overflowY: 'áuto',
    maxHeight: '200',
    width: window.innerWidth <= 450 ? '55vw' : '28vw',
    flexDirection: 'column',
    margin: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    zIndex: theme.zIndex.drawer + 3,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: `-24px 12px 32px -4px ${alpha(
        theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
        0.16
    )}`,
}));

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

Minutes.propTypes = {
    projectData: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    userDropdownData: PropTypes.object,
    getProjects: PropTypes.func,
};

export default function Minutes({ open, setOpen, projectData, userDropdownData, getProjects }) {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const { enqueueSnackbar } = useSnackbar();
    const [userDropdown, setUserDropdown] = useState([]);

    const NewTeamSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('description is required'),
        time: Yup.string().min(5, 'Must be in the form of HH:MM').required('Time is required'),
        date: Yup.string().required('Status is required'),
        users: Yup.array().min(1, 'Users is required'),
    });


    const defaultValues = useMemo(
        // eslint-disable-next-line arrow-body-style
        () => {
            return {
                title: '',
                description: '',
                time: '01:00',
                date: moment(new Date()).format('YYYY-MM-DD'),
                users: [],
            }
        }, []
    );

    const methods = useForm({
        resolver: yupResolver(NewTeamSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        watch,
        control,
        reset,
        setValue,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    // User handle code
    useEffect(() => {
        // remove users from dropdown if already selected and add if unselected
        const newData = userDropdownData?.filter((item) => {
            const found = values?.users?.find((user) => user.id === item.id);
            if (found) {
                return false;
            }
            return true;
        });
        setUserDropdown(newData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.users, userDropdownData]);

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData]);

    useEffect(() => {
        const newUserData1 = projectData?.project_assigned_users?.map((item) => ({ id: item?.id, label: `${item?.full_name}` }));
        setValue('users', newUserData1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData, open]);

    // Time handlle function
    const handleTimeChange = (e, onChange) => {
        const value = e.target.value;
        // increase hours by 1 if minutes are greater than 60
        if (value.split(':')[1] > 59) {
            // if hours is less than 10, add 0 before it
            const hours =
                parseInt(value.split(':')[0], 10) + 1 < 10
                    ? `0${parseInt(value.split(':')[0], 10) + 1}`
                    : parseInt(value.split(':')[0], 10) + 1;
            // if minutes is less than 10, add 0 before it
            const minutes =
                parseInt(value.split(':')[1], 10) - 60 < 10
                    ? `0${parseInt(value.split(':')[1], 10) - 60}`
                    : parseInt(value.split(':')[1], 10) - 60;
            onChange(`${hours}:${minutes}`);
        } else {
            onChange(value);
        }
    };

    const onSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('title', values?.title);
            formData.append('time', values?.time);
            formData.append('description', values?.description);
            formData.append('date', moment(values?.date).format('YYYY-MM-DD'));
            formData.append('project_id', projectData?.id);

            values?.users.map((item, index) => formData.append(`user_ids[${index}]`, item?.id));

            const response = await AxiosAll('post', `/minutes-of-meeting`, formData, token, 'multipart/form-data');

            const { data } = response;

            if (data.statusCode === 200) {
                enqueueSnackbar(data?.message, { variant: 'success' });
                getProjects();
                handleClose();
            } else {
                enqueueSnackbar(data?.message, { variant: 'error' });
            }
        } catch (error) {
            console.log(error);
            const { data } = error?.response;
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    };

    const handleClose = () => {
        reset(defaultValues);
        setOpen(false);
    };

    return (
        <div>
            <Backdrop
                open={open}
                onClick={handleClose}
                sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            />

            <AnimatePresence>
                {open && (
                    <>
                        <RootStyle className="sidebar-inputfield">
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 0.5, pr: 1, pl: 2.5 }}>
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    M.O.M. of {projectData?.title}
                                </Typography>

                                <IconButton onClick={handleClose}>
                                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                                </IconButton>
                            </Stack>

                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                                <DialogContent style={{ overflowY: 'scroll', height: ' 85vh' }}>
                                    <Stack spacing={3}>

                                        {/* Title */}
                                        <div>
                                            <LabelStyle>Title</LabelStyle>
                                            <RHFTextField name="title" type="text" />
                                        </div>

                                        <div >
                                            <LabelStyle>Description</LabelStyle>
                                            <RHFEditor simple name="description" />
                                        </div>
                                        {/* time */}
                                        <div>
                                            <LabelStyle>Time Taken</LabelStyle>
                                            <Controller
                                                name="time"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleTimeChange(e, field.onChange)}>
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                error={!!error}
                                                                fullWidth
                                                                helperText={error?.message}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Typography variant="body2">HH:MM</Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    </InputMask>
                                                )}
                                            />
                                        </div>
                                        {/* Date */}
                                        <div>
                                            <LabelStyle>Date</LabelStyle>
                                            <RHFTextField name="date" type="date" />
                                        </div>
                                        {/* Users */}
                                        <div>
                                            <LabelStyle>Users</LabelStyle>
                                            <Controller
                                                name="users"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        multiple
                                                        freeSolo
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, newValue) => {
                                                            field.onChange(newValue);
                                                        }}
                                                        getOptionDisabled={(option) => option}
                                                        options={userDropdown}
                                                        renderTags={(tagValue, getTagProps) =>
                                                            tagValue.map((option, index) => (
                                                                <Chip key={option.id} label={option.label} {...getTagProps({ index })} />
                                                            ))
                                                        }
                                                        renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} />}
                                                    />
                                                )}
                                            />
                                        </div>

                                    </Stack>
                                </DialogContent>

                                <DialogActions>
                                    <Button type="submit" variant="contained" >
                                        Create
                                    </Button>

                                </DialogActions>
                            </FormProvider>
                        </RootStyle>
                    </>
                )}
            </AnimatePresence>


        </div>

    );
}
