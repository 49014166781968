import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { Container, MenuItem, TextField } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
//
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/sass/styles.scss';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Stack } from '@mui/system';
import { AppWidget } from 'src/sections/@dashboard/general/app';
import useSettings from '../../hooks/useSettings';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import BurndownChart from '../today/burndown';

export default function Performance() {
  const { themeStretch } = useSettings();

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;
  const navigate = useNavigate();
  // const localizer = momentLocalizer(moment);
  const [loading, setLoading] = useState(false);
  const [allTask, setAllTask] = useState({});
  const location = useLocation();
  const [profilePic, setProfilePic] = useState(user?.profile_picture);
  const [totalTask, setTotalTask] = useState('');
  const [plannnedTime, setPlannnedTime] = useState([]);
  const [spendTime, setSpendTime] = useState([]);
  const [tasktitle, setTaskTitle] = useState([]);

  const [search, setSearch] = useSearchParams();

  const [userID, setUserID] = useState(user?.id);
  const [projectId, setProjectId] = useState(search.get('project_id') || '');
  const [startDate, setStartDate] = useState(
    moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [project, setProject] = useState([]);

  const getProject = async () => {
    const response = await AxiosAll('get', `/common/projects?user_id=${userID || ''}`, {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  const limit = 1000;

  const getTasks = async () => {
    setLoading(true);
    // eslint-disable-next-line no-template-curly-in-string
    const response = await AxiosAll(
      'get',
      `/userReport?project_id=${projectId}&user_id=${userID}&start_date=${startDate}&due_date=${endDate}&limit=${limit}`,
      {},
      token
    );
    const { data } = response;

    if (data?.statusCode === 200) {
      setLoading(false);
      setTotalTask(data?.data?.total);
      setAllTask(data?.data);
      setPlannnedTime(
        data?.data?.data?.filter((person) => person.planned_time)?.map((person) => Number(person?.planned_time))
      );
      setSpendTime(
        data?.data?.data?.filter((person) => person.spend_time)?.map((person) => Number(person?.spend_time))
      );
      const title1 = data?.data?.data
        ?.filter((person) => person.title)
        ?.map((person) => `${person?.id} - ${person?.title}`);
      const id1 = data?.data?.data?.filter((person) => person.id)?.map((person) => person?.id);
      setTaskTitle(title1);
    }
  };

  useEffect(() => {
    getProject();
  }, [userID, user]);

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, startDate, endDate, projectId]);

  // Handle changes
  const handleProject = (e) => {
    navigate(`${location.pathname}?project_id=${e?.target?.value}&start_date=${startDate}&end_date=${endDate}`);
    setProjectId(e.target.value);
  };

  const handleStartDateChange = (e) => {
    navigate(`${location.pathname}?project_id=${projectId}&start_date=${e.target.value}&end_date=${endDate}`);
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    navigate(`${location.pathname}?project_id=${projectId}&start_date=${startDate}&end_date=${e.target.value}`);
    setEndDate(e.target.value);
  };

  return (
    <Page title="User Report">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="User Report" links={[{}]} />
        <Stack spacing={2} className="stack-center" direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
          <TextField
            fullWidth
            select
            label="Project"
            value={projectId}
            onChange={handleProject}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key={''}
              value={''}
            >
              Select
            </MenuItem>
            {project?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.title}
              </MenuItem>
            ))}
          </TextField>
          <div>
            <TextField id="date1" type="date" label="Start Date" value={startDate} onChange={handleStartDateChange} />
          </div>
          <div>
            <TextField id="date2" type="date" label="End Date" value={endDate} onChange={handleEndDateChange} />
          </div>
          <div>
            <AppWidget
              title="Tasks"
              total={totalTask}
              icon={profilePic}
              chartData={
                allTask?.total_planned_time
                  ? Math.trunc((allTask?.total_planned_time * 100) / allTask?.total_spend_time)
                  : 0
              }
            />
          </div>
        </Stack>
        <div style={{ height: 700, marginTop: 15 }}>
          <BurndownChart plannnedTime={plannnedTime} spendTime={spendTime} tasktitle={tasktitle} />
        </div>
      </Container>
    </Page>
  );
}
