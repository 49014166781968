import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from '@mui/material';
// components
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from 'src/components/hook-form';
import AxiosAll from 'src/services/axiosAll';
import moment from 'moment';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));
// ----------------------------------------------------------------------

ApplyDialogue.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getHoliday: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default function ApplyDialogue({ open, onClose, getHoliday, isAdmin }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const { enqueueSnackbar } = useSnackbar();

  const [leaveData, setLeaveData] = useState();
  const [leaveBgColor, setLeaveBgColor] = useState('green');
  const NewFormSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().required('Description is required'),
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('end Date is required'),
  });

  const defaultValues = useMemo(
    // eslint-disable-next-line arrow-body-style
    () => {
      return {
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        leave_type: 'full_day',
        leave_category: '',
        festival_name: '',
        readyToCompensat: '',
      };
    },
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewFormSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const handleOnClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (data1) => {
    try {
      const newEvent = {
        name: data1.title,
        start_date: data1.startDate,
        end_date: data1.endDate,
        description: data1.description,
        type: 'leave',
        leave_type: data1?.leave_type,
        leave_category: data1?.leave_category,
        festival_name: data1.festival_name,
        is_compensating: data1.readyToCompensate,
      };

      const response = await AxiosAll('post', '/leave', newEvent, token);
      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        handleOnClose();
        reset();
        getHoliday();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const typeData = [
    { id: 'full_day', name: 'Leave Application' },
    { id: 'first_half', name: 'First Half (10 am - 02 pm)' },
    { id: 'second_half', name: 'Second Half (02 pm - 07 pm)' },
  ];
  const LeaveTypeData = [
    { id: 'leave application', name: 'Select Leave Category' },
    { id: 'festival', name: 'Festival' },
    { id: 'sick', name: 'Sick' },
    // { id: 'casual', name: 'Casual' },
    { id: 'other', name: 'Other' },
  ];
  useEffect(() => {
    if (values?.leave_type !== 'full_day') {
      setValue('endDate', values?.startDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.startDate]);

  // GET THE LEAVE COUNT FOR THE CURRENT USER

  const fromDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
  const toDate = moment().format('YYYY-MM-DD');

  const getLeaveData = async () => {
    try {
      const response = await AxiosAll(
        'get',
        `/leave/${userDetails?.user?.id}?startDate=${fromDate}&endDate=${toDate}`,
        {},
        token
      );
      if (response?.data?.statusCode === 200) {
        setLeaveData(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching leave data', error);
    }
  };

  useEffect(() => {
    getLeaveData();
  }, []);

  const festivalLeaves = leaveData?.overview?.festivalHalfDay / 2 + leaveData?.overview?.festivalFullDay;

  const sickLeaves = leaveData?.overview?.sickHalfDay / 2 + leaveData?.overview?.sickFullDay;

  const otherLeaves = leaveData?.overview?.fullDay + leaveData?.overview?.halfDay / 2;

  const totalLeaves = otherLeaves - leaveData?.overview?.compensated;

  const getBackgroundColor = () => {
    if (totalLeaves < 10) {
      return 'green';
    }

    if (totalLeaves >= 10 && totalLeaves <= 12) {
      return '#ff9999';
    }

    return '#ff4d4d';
  };

  useEffect(() => {
    const color = getBackgroundColor();
    setLeaveBgColor(color);
  }, [totalLeaves]);

  console.log('leave BG color', leaveBgColor);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
      <DialogTitle>Apply for leave</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            {/* Add leave count table with dynamic background color */}
            <div style={{ padding: '16px', borderRadius: '8px', border: '1px solid #ddd' }}>
              <LabelStyle>{`Leave Summary from : ${fromDate} to ${toDate}`}</LabelStyle>

              <table style={{ width: '100%', marginTop: '16px', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ backgroundColor: '#f5f5f5', color: '#333', textAlign: 'left' }}>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>Sick Leaves</th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>Festival Leaves</th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>Other Leaves</th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>Compensation Days</th>
                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>Total Leaves</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: '#fff' }}>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{sickLeaves}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{festivalLeaves}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{otherLeaves}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{leaveData?.overview?.compensated}</td>

                    <td
                      style={{
                        padding: '8px',
                        border: `1px solid #ddd`,
                        backgroundColor: leaveBgColor || 'green',
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      {totalLeaves}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <LabelStyle>Write subject of your mail</LabelStyle>
              <RHFTextField name="title" />
            </div>
            <div>
              <LabelStyle>Description</LabelStyle>
              <RHFEditor name="description" />
            </div>

            <div>
              <LabelStyle>Are you ready to compensate?</LabelStyle>
              <Controller
                name="readyToCompensate"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                )}
              />
            </div>
            <div>
              <LabelStyle>Leave Type</LabelStyle>
              <RHFSelect name="leave_type">
                {typeData?.map((item, index) => (
                  <option value={item?.id} key={index}>
                    {item?.name}
                  </option>
                ))}
              </RHFSelect>
            </div>
            <div>
              <LabelStyle>Leave Category</LabelStyle>
              <RHFSelect name="leave_category">
                {LeaveTypeData?.map((item, index) => (
                  <option value={item?.id} key={index}>
                    {item?.name}
                  </option>
                ))}
              </RHFSelect>
              {values.leave_category === 'festival' && (
                <div style={{ marginTop: '16px' }}>
                  <LabelStyle>Festival Name</LabelStyle>
                  <RHFTextField name="festival_name" />
                </div>
              )}
            </div>

            {/* Start Date */}
            <div>
              <LabelStyle>Start Date</LabelStyle>
              <RHFTextField name="startDate" type="date" />
            </div>

            {/* End Date */}
            <div>
              <LabelStyle>End Date</LabelStyle>
              <RHFTextField
                name="endDate"
                type="date"
                min={values?.startDate}
                disabled={values?.leave_type !== 'full_day'}
              />
            </div>
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={handleOnClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
