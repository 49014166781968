import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

CreateTeams.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentTeam: PropTypes.object,
  isEdit: PropTypes.bool,
  getTeams: PropTypes.func,
};

export default function CreateTeams({ open, onClose, isEdit, currentTeam, getTeams }) {
  const { userDetails } = useSelector((state) => state.loginReducer);

  const { enqueueSnackbar } = useSnackbar();

  const NewTeamSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    status: Yup.string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentTeam?.name || '',
      description: currentTeam?.description || '',
      status: currentTeam?.status || 'active',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTeam]
  );

  const methods = useForm({
    resolver: yupResolver(NewTeamSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentTeam) {
      reset(defaultValues);
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentTeam]);

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('name', values?.name);
      formData.append('status', values?.status);
      formData.append('description', values?.description);

      const response = await AxiosAll(
        'post',
        isEdit ? `/teams/${currentTeam?.id}` : '/teams',
        formData,
        userDetails.token
      );

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        onClose();
        getTeams();
        reset();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
        reset(defaultValues);
      }}
    >
      <DialogTitle>Add new team</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>New Team</LabelStyle>
              <RHFTextField name="name" placeholder="Team Name" />
            </div>
            <div>
              <LabelStyle>Description</LabelStyle>
              <RHFTextField name="description" placeholder="Team Description..." multiline rows={4} />
            </div>
            <div>
              <LabelStyle>Project Status</LabelStyle>
              <RHFSelect name="status" placeholder="Status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </RHFSelect>
            </div>
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {isEdit ? 'Update' : 'Create'}
          </LoadingButton>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              onClose();
              reset(defaultValues);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
