// scroll bar
import 'simplebar/src/simplebar.css';
// editor
import 'react-quill/dist/quill.snow.css';
// highlight
import './utils/highlight';
// lazy image
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
//
// import 'react-big-calendar/lib/css/react-big-calendar.css';

import App from './App';
import { store } from './redux/store';
// import './scss/main.scss';
import './scss/main.scss';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <SettingsProvider>
    <Provider store={store}>
      <HelmetProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </HelmetProvider>
    </Provider>
  </SettingsProvider>
);
