/* eslint-disable default-param-last */
/* eslint-disable no-debugger */
const { SETCOUNT } = require("../constant");

const initialState = {
    rcount: 0,
}

const ReviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETCOUNT:
            return { ...state, rcount: action.data };
        default: return state;
    }
};
export default ReviewReducer;