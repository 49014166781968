import AxiosAll from './axiosAll';

export const FetchProjects = async (token) => {
  try {
    const currentDate = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
    const storedDate = localStorage.getItem('loginDate');
    console.log(storedDate, currentDate, 'storedDate');
    const storedProjectList = localStorage.getItem('projectList');
    if (storedDate === currentDate) {
      return JSON.parse(storedProjectList) || [];
    }
    const response = await AxiosAll('get', `/common/projects`, {}, token);
    const { data } = response;
    if (response?.status === 200) {
      localStorage.setItem('projectList', JSON.stringify(data));
      localStorage.setItem('loginDate', currentDate);
      return data || [];
    }
  } catch (error) {
    return [];
  }
};
