/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import { NavSectionVertical } from '../../../components/nav-section';
import Scrollbar from '../../../components/Scrollbar';
//
import { navConfig, navConfigAdmin, navConfigLead, navConfigTester } from './NavConfig';
// import NavbarDocs from './NavbarDocs';
import CollapseButton from './CollapseButton';
import NavbarAccount from './NavbarAccount';

import packageJson from '../../../../package.json';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [isTester, setIsTester] = useState(false);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const isAdmin = userDetails?.user?.roles?.some((role) => role.id === 1);
    // const isTester = userDetails?.user?.is_tester;
    const isTester = userDetails?.user?.roles?.some((role) => role.id === 4);
    const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);

    setIsAdmin(isAdmin);
    setIsTester(isTester);
    setIsLead(isLead);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.user?.roles]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical
        navConfig={isAdmin ? navConfigAdmin : isTester ? navConfigTester : isLead ? navConfigLead : navConfig}
        isCollapse={isCollapse}
      />
      {/* Previou was 4.9.3
      Version 5.0.0
            Now admin can create task from user-wise tab with very less flow. 

            Version 5.1.0
            feilds hide and default feilds added on create task.

            Version 5.1.1
            Color meanings added at bottom of task tab.

            Version 5.1.2
            Counts added for side bar.
            Added all bugs and feature given by Jayesh (14 Sep 2013).

            Version 5.2.0
            half day added on leave apply.

            Version 5.3.0
            Stagging , production and local server added.

            Version 5.4.0
            Kanban added.
            User can only see -> kanban, logs and sheet on same page and calendar.
            Other tabs are removed.
            activities shifted to notifications.
            Performance added on dashboard.
            For team lead -> dashboard, kanban, logs and sheet , review, tracker, MOM and calendar.

            Version 5.4.1
            Side bar order update and few removed for admin.
            In project wise and user wise tab, delayed tasks showed in red background.
            Breadcrumb navigation update.
            Kanban task view update.
            Dafault weekid is "" in project wise task tab.
            */}
      <Box sx={{ flexGrow: 1 }} />
      <Typography
        variant="caption"
        sx={{ pr: 3, flexShrink: 0, color: 'text.secondary', margin: '0.5rem 0 1.5rem 2.0rem' }}
        className="version-text"
      >
        Version {packageJson?.version}
      </Typography>
      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
          className="mobile_drawer_width"
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          className="mobile_drawer_width"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
