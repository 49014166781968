/* eslint-disable react/button-has-type */
import { Box, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import AxiosAll from "src/services/axiosAll";
import IdTable from "./IdTable";

export default function BulkUpload() {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const [fileUpload, setFileUpload] = useState();
    const [selectId, setSelectId] = useState('users');
    const { enqueueSnackbar } = useSnackbar();
    const [tableData, setTableData] = useState([]);
    const [filterName, setFilterName] = useState('');
    const nav = useNavigate();
    const options = [
        {
            id: 'users',
            title: 'User list'
        }, {
            id: 'projects',
            title: 'Project List'
        }, {
            id: 'task-priorities',
            title: 'Priority List'
        },
    ]

    const handleChange = (event) => {
        if (event.target.files[0]) {
            setFileUpload(event.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('csvFile', fileUpload);
            const response = await AxiosAll('post', '/bulk-task', formData, token, 'multipart/form-data');
            const { data } = response;

            if (data.statusCode === 200) {
                enqueueSnackbar(data?.message, { variant: 'success' });
                if (userDetails?.user?.role_id === 1) {
                    nav('/tasks/status-wise')
                } else {
                    nav('/team-tasks')
                }
            } else {
                enqueueSnackbar(data?.message, { variant: 'error' })
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleProject = (e) => {
        setSelectId(e.target.value);
    };
    const getDropdownData = async () => {
        setTableData([]);
        const response = await AxiosAll('get', `/common/${selectId}?query=${filterName}&page_count=${100}`, {}, token);
        if (response?.status === 200) {
            setTableData(response?.data);
        }
    };
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        selectId && getDropdownData();
    }, [selectId, filterName])
    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        // setPage(0);
    };
    // console.log("file", fileUpload?.name)
    return (
        <>
            <Page title="Bulk Upload">
                <HeaderBreadcrumbs
                    heading="Bulk upload"
                    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Bulk Upload' }]}
                />
                <Box sx={{ display: 'flex' }}>
                    <div>
                        <form className="form-container">
                            <div className="upload-files-container">
                                <div className="drag-file-area">
                                    <h3 className="dynamic-message"> Upload .CSV file </h3>
                                    <label className="label">
                                        <span className="browse-files">
                                            <input type="file"
                                                id="myFile"
                                                name="filename"
                                                accept=".csv"
                                                onChange={handleChange} className="default-file-input" />
                                            <span className="browse-files-text">browse file</span>
                                            <span>from device</span>
                                            <Typography className="material-icons-outlined">
                                                {fileUpload?.name}
                                            </Typography>
                                        </span>
                                    </label>
                                </div>

                                <button type="button" className="upload-button" onClick={() => handleSubmit()}> Upload </button>
                                <button className="upload-button" onClick={() => window.open('https://docs.google.com/spreadsheets/d/1R6jim-CRBvv6EZqXJwN7RgohPXDTZSp5QiLyNxMGwjg/edit?usp=sharing', "_blank")}>
                                    Download format
                                </button>
                            </div>
                        </form>
                    </div>
                    <div style={{ width: '100%', display: 'block' }}>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ pb: 2.5, px: 3 }}>
                            <TextField
                                fullWidth
                                select
                                // label="Select to know id"
                                value={selectId}
                                onChange={handleProject}
                                SelectProps={{
                                    MenuProps: {
                                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                    },
                                }}
                                sx={{
                                    maxWidth: { sm: 240 },
                                    textTransform: 'capitalize',
                                }}
                            >
                                <MenuItem
                                    sx={{
                                        mx: 1,
                                        my: 0.5,
                                        borderRadius: 0.75,
                                        typography: 'body2',
                                        textTransform: 'capitalize',
                                    }}
                                    key={''}
                                    value={''}
                                >
                                    Select
                                </MenuItem>
                                {options?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        value={option?.id}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {option?.title}
                                    </MenuItem>
                                ))}
                            </TextField>{' '}

                            <TextField
                                fullWidth
                                value={filterName}
                                onChange={(event) => handleFilterName(event.target.value)}
                                placeholder={`Search`}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <IdTable tableData={tableData} selectId={selectId} />
                    </div>



                </Box>
            </Page>
        </>
    )
}
