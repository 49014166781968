// routes
import { useEffect } from 'react';
// theme
import { useSelector , useDispatch } from 'react-redux';
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Routes from './routes';
import NotistackProvider from './components/NotistackProvider';

import { messaging } from './firebase';
import { getProject, getUser, getpriority, updateLastUpdateTime } from './redux/actions/login';
import AxiosAll from './services/axiosAll';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const { lastUpdateTime  ,userDetails} = useSelector((state) => state?.loginReducer);
  const { token } = userDetails;
  // console.log("lastUpdateTime" ,lastUpdateTime)
  useEffect(() => {
    const registerServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered with scope:', registration.scope);

        // Check if the service worker is active before subscribing
        if (registration.active) {
          subscribeToPushNotifications(registration);
        }
      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    };

    const subscribeToPushNotifications = async (registration) => {
      try {
  
        await messaging.requestPermission();
        const token = await messaging.getToken();
        // console.log('Token:', token);
        // Send the token to your server for handling

        messaging.onMessage((payload) => {
          console.log('Foreground Message:', payload);
        });
      } catch (error) {
        console.error('Error:', error);
      }
    };

    registerServiceWorker();
  }, []);



  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await AxiosAll('get', `/common/users`, {}, token);
        if (res?.status === 200) {
          const newData = res?.data;
          dispatch(getUser(newData));
          dispatch(updateLastUpdateTime(new Date()));
        // console.log(" done")

        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchProject = async () => {
      try {
        const res = await AxiosAll('get', `/common/projects`, {}, token);
        if (res?.status === 200) {
          const newData = res?.data;
          dispatch(getProject(newData));
          dispatch(updateLastUpdateTime(new Date()));
        // console.log(" done")

        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchPriority = async () => {
      try {
        const res = await AxiosAll('get', `/common/task-priorities`, {}, token);
        if (res?.status === 200) {
          const newData = res?.data;
          dispatch(getpriority(newData));
          dispatch(updateLastUpdateTime(new Date()));
        // console.log(" done")

        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const scheduleTask = () => {
      const now = new Date();
  
      // Check if the current time is after 11:00 AM and lastUpdateTime is not today
      if (!lastUpdateTime ||  (now && now.getDate && lastUpdateTime && lastUpdateTime.getDate &&
        now.getDate() !== lastUpdateTime.getDate())) {
        fetchUser();
        fetchProject();
        fetchPriority()

      }
    };
  
  
  
    // Run the task immediately on mount
    scheduleTask();

  }, [ lastUpdateTime, userDetails]);



  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <Routes />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
