import PropTypes from 'prop-types';
// form

// @mui
import { Avatar, Button, CardContent, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import { useSelector } from 'react-redux';
import Markdown from 'src/components/Markdown';
import AxiosAll from 'src/services/axiosAll';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

ReportDetail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNextStep: PropTypes.func,
  onCreateBilling: PropTypes.func,
  editTask: PropTypes.object,
};

export default function ReportDetail({ open, setOpen, idToOpen }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [task, setTask] = useState([]);

  const navigate = useNavigate();

  const handleOnClose = () => {
    setOpen(false);
  };

  const getTaskDetail = async () => {
    const response = await AxiosAll('get', `/tasks/${idToOpen}`, null, userDetails.token);
    const { data } = response;
    setTask(data?.data);
    return {};
  };

  useEffect(() => {
    getTaskDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToOpen, open]);
  function handleLinkClick(link) {
    window.open(link, '_blank');
  }
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
      <DialogContent>
        <CardContent>
          <Iconify
            onClick={() => navigate(`/tasks/details/${task.id}`)}
            sx={{
              position: 'absolute',
              top: 0,
              right: 10,
              fontSize: 40,
              marginTop: 0,
              cursor: 'pointer',
              padding: 1,
            }}
            icon="carbon:view-filled"
          />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Project
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.project?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Priority
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.task_priority?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Task
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Status
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.task_status?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Estimated Time
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.planned_time_format}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Spend Time
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.spend_time_format}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Predecessor Tasks
                                </Typography>
                                {task?.predecessor_task_id &&
                                    <Typography variant="body2" color="text.secondary" sx={{ cursor: 'pointer' }} onClick={() => navigate(`/tasks/details/${task?.predecessor_task_id}`)}>
                                        {predecessorTask}
                                    </Typography>}
                            </Grid> */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Start Date
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {moment(task?.start_date).format('D MMM')}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Successor Tasks
                                </Typography>
                                {task?.following_task_id && <Typography variant="body2" color="text.secondary" sx={{ cursor: 'pointer' }} onClick={() => navigate(`/tasks/details/${task?.following_task_id}`)}>
                                    {successorTask}
                                </Typography>}
                            </Grid> */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Due Date
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {moment(task?.due_date).format('D MMM')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Description
              </Typography>
              <Markdown children={task?.description} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Ticket
              </Typography>
              <Markdown children={task?.ticket} />
            </Grid>
            {task?.reference_url && (
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Refrence URL
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  onClick={() => handleLinkClick(task?.reference_url)}
                  style={{ cursor: 'pointer' }}
                >
                  {task?.reference_url}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Assigned User
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.task_users?.map((user) => (
                  <div key={user.id}>
                    {user?.user?.first_name} {user?.user?.last_name}
                  </div>
                ))}
              </Typography>
            </Grid>
            {task?.reference_title && (
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Refrence Title
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {task?.reference_title}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Creator
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {task?.created_by_user?.full_name}
              </Typography>
            </Grid>
            {task?.reviewer_comment && (
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Task Comments
                </Typography>
                {task?.task_comments?.map((item, index) => (
                  <>
                    {item?.comment && (
                      <Typography key={item?.id} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar alt={item?.user?.full_name} src={item?.user?.profile_picture} sx={{ mr: 2 }} />
                        <Typography variant="body2" color="text.secondary">
                          {item?.comment}
                        </Typography>
                      </Typography>
                    )}
                  </>
                ))}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
}
