// @mui
import { Box, Paper, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonProjectTab() {
    return (
        <>
            <Paper
                variant="outlined"
                className="task-card"
                sx={{ p: 2, bgcolor: 'grey.5008', }}
            >
                <Skeleton variant="rectangular" width='100%' height={50} sx={{ mb: 1 }} />
                <Box sx={{ border: '1px dashed #ccc', borderRadius: '10px', mb: 2 }}>

                    <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </Box>
                    <Box sx={{ mb: 0.5, overflowY: 'auto' }} className="box-height-overflow">

                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='100%' height={60} sx={{ mt: 1 }} />
                        <Skeleton variant="rounded" width='100%' height={60} sx={{ mt: 1 }} />
                    </Box>
                </Box>


                <Box sx={{ border: '1px dashed #ccc', borderRadius: '10px', mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </Box>
                    <Box sx={{ mb: 0.5, overflowY: 'auto' }} className="box-height-overflow">

                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='100%' height={60} sx={{ mt: 1 }} />
                        <Skeleton variant="rounded" width='100%' height={60} sx={{ mt: 1 }} />
                    </Box>
                </Box>


            </Paper>
        </>
    );
}
