import { Card, Table, TableBody, TableContainer } from '@mui/material';
import { useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableSkeleton } from 'src/components/table';
import DropdownRow from 'src/sections/@dashboard/task/list/DropdownRow';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Id', align: 'left' },
    { id: 'title', label: 'Title', align: 'left' },
];
const TABLE_HEAD_STARTUP = [
    { id: 'id', label: 'Id', align: 'left' },
    { id: 'startup', label: 'Start-up Id', align: 'center' },
    { id: 'title', label: 'Title', align: 'left' },
];

// ----------------------------------------------------------------------

export default function IdTable({ tableData, selectId }) {
    const [loading, setLoading] = useState(false);

    return (
        <Card>

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>

                    <Table>
                        <TableHeadCustom
                            hideCheckbox
                            headLabel={selectId === 'projects' ? TABLE_HEAD_STARTUP : TABLE_HEAD}
                        />
                        {loading ? (
                            <>
                                <TableSkeleton />
                                <TableSkeleton />
                                <TableSkeleton />
                                <TableSkeleton />
                                <TableSkeleton />
                            </>
                        ) : (
                            <TableBody>
                                {tableData?.map((row) => (
                                    <DropdownRow
                                        selectId={selectId}
                                        key={row.id}
                                        row={row}
                                    />
                                ))}

                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    )
}
