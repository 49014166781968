/* eslint-disable import/no-unresolved */
import { Route, Routes } from 'react-router-dom';
import Dashboard from 'src/pages/dashboard/dashboard';
import DeveloperFavouriteTasks from 'src/pages/favourite_tasks/DeveloperFavouriteTasks';
import FavouriteTasks from 'src/pages/favourite_tasks/FavouriteTasks';
import Kanban from 'src/pages/kanban';
import Leaves from 'src/pages/leaves';
import Logs from 'src/pages/logs/list';
import UserMoM from 'src/pages/m.o.m';
import Page403 from 'src/pages/Page403';
import Page404 from 'src/pages/Page404';
import EditProfile from 'src/pages/profile/EditProfile';
import EmployeeCard from 'src/pages/profile/EmployeeCard';
import Profile from 'src/pages/profile/Profile';
import ProjectDetails from 'src/pages/project/Details';
import ReviewTask from 'src/pages/review';
// import CreateProject from 'src/pages/project/Create';
// import ProjectDetails from 'src/pages/project/Details';
// import ProjectList from 'src/pages/project/List';
// import Reports from 'src/pages/reports';
// import ReportsProjectDetail from 'src/pages/reports/Details';
// import RoleList from 'src/pages/roles/List';
// import StartupCreate from 'src/pages/Startup/Create';
// import StartupList from 'src/pages/Startup/List';
// import TagList from 'src/pages/tags/List';
import CreateTask from 'src/pages/tasks/addTasks';
import DeveloperTasks from 'src/pages/tasks/DeveloperTasks';
import Tasks from 'src/pages/tasks/tasks';
// import TeamList from 'src/pages/teams/List';
import TimeSheet from 'src/pages/timeSheet';
import Performance from 'src/pages/user_report/performance';
import LeaveListDetail from 'src/sections/@dashboard/calendar/LeaveListDetail';
// import UserAccount from 'src/pages/user/UserAccount';
// import UserCreate from 'src/pages/user/UserCreate';
// import UserList from 'src/pages/user/UserList';
import SingleTaskDetail from 'src/sections/@dashboard/task/SingleTaskDetail';

const PrivateRoutes = ({ role }) => (
  <Routes>
    <Route path={'tasks'} element={<DeveloperTasks />} />
    {/* <Route path={'dashboard'} element={<Dashboard />} /> */}
    <Route path={'/'} element={<Kanban />} />
    {role === 2 ? ( // developer
      <>
        <Route path={'kanban'} element={<Kanban />} />
        <Route path={'kanban/add-task'} element={<CreateTask roleId={role} />} />
        <Route path={'dashboard'} element={<Dashboard />} />
        <Route path={'tasks'} element={<DeveloperTasks />} />
        <Route path={'tasks/:status'} element={<DeveloperTasks />} />
        <Route path={'tasks/add-task'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/edit/:id'} element={<CreateTask roleId={role} />} />
        {/* <Route path={'tasks/add-task'} element={<DeveloperEdit />} />
        <Route path={'tasks/add-sub/:id'} element={<DeveloperEdit />} />
        <Route path={'tasks/edit/:id'} element={<DeveloperEdit />} /> */}
        <Route path={'tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'favourite-tasks'} element={<DeveloperFavouriteTasks />} />
        <Route path={'leaveslist/:id'} element={<LeaveListDetail />} />
      </>
    ) : (
      <>
        <Route path={'tasks'} element={<Tasks />} />
        <Route path={'kanban'} element={<Kanban />} />
        <Route path={'profile'} element={<Profile />} />
        <Route path={'employee-card'} element={<EmployeeCard />} />
        <Route path={'tasks/add-task'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/add-sub/:id'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/edit/:id'} element={<CreateTask roleId={role} />} />
        <Route path={'tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'favourite-tasks'} element={<FavouriteTasks />} />
      </>
    )}
    <Route path={'logs'} element={<Logs />} />
    <Route path={'dashboard'} element={<Kanban />} />
    <Route path={'review'} element={<ReviewTask />} />
    <Route path={'project/details/:id'} element={<ProjectDetails />} />
    <Route path={'performance'} element={<Performance />} />
    <Route path={'profile'} element={<Profile />} />
    <Route path={'profile/edit'} element={<EditProfile />} />
    <Route path={'employee-card'} element={<EmployeeCard />} />
    <Route path={'time-sheet'} element={<TimeSheet />} />
    <Route path={'minutes-of-meeting'} element={<UserMoM />} />
    <Route path={'404'} element={<Page404 />} />
    <Route path={'403'} element={<Page403 />} />
    <Route path={'*'} element={<Page404 />} />
    <Route path={'leaves'} element={<Leaves />} />
    <Route path={'leaveslist/:id'} element={<LeaveListDetail />} />
  </Routes>
);

export default PrivateRoutes;
