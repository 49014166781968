import Highcharts from 'highcharts';
import { useEffect, useRef, useState } from 'react';
import ReportDetail from './reportDetail';

// eslint-disable-next-line react/prop-types
const BurndownChart = ({ plannnedTime, spendTime, tasktitle, isDarkMode }) => {
  const chartContainer = useRef(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  useEffect(() => {
    if (chartContainer.current) {
      Highcharts.chart(chartContainer.current, {
        title: {
          text: 'Report chart',
          x: -20,
        },
        chart: {
          backgroundColor: isDarkMode ? '#333' : '#fff', // Set background color based on theme
        },
        colors: ['blue', 'red'],
        plotOptions: {
          line: {
            lineWidth: 3,
          },
          tooltip: {
            hideDelay: 200,
          },
          series: {
            point: {
              events: {
                click(e) {
                  setId(e?.point?.category.substring(0, e?.point?.category.indexOf('-')));
                  setOpen(true);
                },
              },
            },
          },
        },
        // subtitle: {
        //     text: 'User A',
        //     x: -20,
        // },
        xAxis: {
          categories: [...tasktitle],
        },
        yAxis: {
          title: {
            text: 'Time in minutes',
          },
          plotLines: [
            {
              value: 0,
              width: 1,
            },
          ],
        },
        tooltip: {
          valueSuffix: ' min',
          crosshairs: true,
          shared: true,
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          borderWidth: 0,
        },
        series: [
          {
            name: 'Ideal Burn',
            color: 'rgba(255,0,0,0.25)',
            lineWidth: 2,
            data: [...plannnedTime],
          },
          {
            name: 'Actual Burn',
            color: 'rgba(0,120,200,0.75)',
            marker: {
              radius: 6,
            },
            data: [...spendTime],
          },
        ],
      });
    }
  }, [chartContainer, plannnedTime, spendTime, tasktitle, isDarkMode]);
  return (
    <>
      <div ref={chartContainer} style={{background: 'green'}}/>
      <ReportDetail idToOpen={id} setOpen={setOpen} open={open} />
    </>
  );
};

export default BurndownChart;
