import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
// eslint-disable-next-line import/no-unresolved
import { FormProvider, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddStartupContacts.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setStartupContacts: PropTypes.func,
};

export default function AddStartupContacts({ open, onClose, setStartupContacts }) {
  const NewAddressSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    designation: Yup.string().required('Designation is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Phone number is required')
      .min(10, 'Phone number can not be smaller than 10 digits')
      .max(10, 'Phone number can not be smaller than 10 digits')
      .required('Phone number is required'),
    dob: Yup.string().required('Date of birth is required'),
    address: Yup.string().required('Address is required'),
  });

  const defaultValues = {
    name: '',
    designation: '',
    email: '',
    phone: '',
    dob: '',
    address: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setStartupContacts((prev) => [...prev, { ...data, id: prev.length }]);
    reset();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      // onClose={() => {
      //   onClose();
      //   reset();
      // }}
    >
      <DialogTitle>Add Startup Contacts</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>Name</LabelStyle>
              <RHFTextField name="name" type="text" />
            </div>
            <div>
              <LabelStyle>Designation</LabelStyle>
              <RHFTextField name="designation" type="text" />
            </div>
            <div>
              <LabelStyle>Email</LabelStyle>
              <RHFTextField name="email" type="email" />
            </div>
            <div>
              <LabelStyle>Phone Number</LabelStyle>
              <RHFTextField name="phone" type="number" />
            </div>
            <div>
              <LabelStyle>Date of Birth</LabelStyle>
              <RHFTextField
                name="dob"
                type="date"
                inputProps={{
                  max: new Date().toISOString().split('T')[0],
                }}
              />
            </div>
            <div>
              <LabelStyle>Address</LabelStyle>
              <RHFTextField name="address" type="text" />
            </div>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Add
          </LoadingButton>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              onClose();
              reset();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
