/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import moment from 'moment';
import { newServer } from 'src/core';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../../components/hook-form';
import MobilePicker from 'src/components/Timepicker';
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

TaskNewAddForm.propTypes = {
  task: PropTypes.object,
};

export default function TaskNewAddForm({ task }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [priorityData, setPriorityData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [userData, setUserData] = useState([]);
  const [teamWithUser, setTeamWithUser] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selArray, setSelArray] = useState([]);
  const [selLevel, setSelLevel] = useState([]);
  const [selTime, setSelTime] = useState([]);
  const [label, setLabels] = useState([]);
  const { userDetails, userList, projectList, priorityList } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionIds, setSelectedOptionIds] = useState([]);
  const [taskDueTime, setTaskDueTime] = useState('00:00');
  const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);
  const isTester = userDetails?.user?.roles?.some((role) => role.id === 4);
  // get redux state
  const { token } = userDetails;
  const createTask = location?.state?.data;
  // console.log('createTask', createTask);
  const labelslist = location?.state?.label;
  const labelsIdList = location?.state?.labelsId;
  const duetime = location?.state?.dueTimeData;

  const NewProductSchema = Yup.object().shape({
    project: Yup.string().required('Project is required'),
    task: Yup.string().required('Task title is required'),
    visiblity: Yup.string().required('Startup visibility is required'),
    // status: Yup.string().required('Status is required'),
    // predecessorTask: Yup.string().required('Predecessor task is required'),
    // successorTask: Yup.string().required('Successor task is required'),
    // description: Yup.string().required('Description is required'),
    // assignUser: Yup.string().required('Assign user is required'),
    assignUser: Yup.string().when('siblings', {
      is: '0',
      then: Yup.string().required('Assign user is required'),
    }),
    priority: Yup.string().required('Priority is required'),
    plannedHour: Yup.string()
      .min(5, 'Must be in the form of HH:MM and should be at least 5 minutes')
      .required('Planned hour is required')
      .test('is-at-least-5-minutes', 'The minimum time should be 5 minutes.', (value) => {
        if (!value) return true;

        const hour = parseInt(value.split(':')[0], 10);
        const minute = parseInt(value.split(':')[1], 10);

        // Exclude '00:00'
        return !(hour === 0 && minute === 0) && (hour > 0 || minute >= 5);
      }),
    startDate: Yup.string().required('Start date is required'),
    // dueDate: Yup.string().required('Due date is required'),
    // teams: Yup.string().required('Teams is required'),
    // refUrl: Yup.string().required('URL is required'),
    // refTitle: Yup.string().required('Refrence title is required'),
  });

  const defaultValues = useMemo(
    () => ({
      task: createTask?.task || '',
      description: '',
      plannedHour: createTask?.plannedHour || '01:00',
      refUrl: '',
      refTitle: '',
      startDate: moment(createTask?.startDate).format('YYYY-MM-DD') || '',
      dueDate: moment(createTask?.dueDate).format('YYYY-MM-DD') || '',

      project: createTask?.project || '',
      status: createTask?.status || 1,
      predecessorTask: '',
      successorTask: '',
      assignUser: createTask?.assignUser,
      priority: createTask?.priority || '',
      visiblity: 'visible',
      siblings: '0',
      parentTask: '',
      // selectUser: selectedUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectList, taskStatus, allTask, userList, priorityList]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // Common dropdowns
  // const getPriority = async () => {
  //   const res = await AxiosAll('get', '/common/task-priorities', {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setPriorityData(newData);
  //   }
  // };
  // const getProjects = async () => {
  //   const res = await AxiosAll('get', '/common/projects', {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setProjectData(newData);
  //   }
  // };
  const getTask = async () => {
    const res = await AxiosAll('get', `/common/tasks?project_id=${values?.project}`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setAllTask(newData);
    }
  };
  const getStatus = async () => {
    const res = await AxiosAll('get', '/common/task-statuses', {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setTaskStatus(newData);
    }
  };
  // const getUser = async () => {
  //   const res = await AxiosAll('get', `/common/users`, {}, token);
  //   if (res?.status === 200) {
  //     const newData = res?.data;
  //     setUserData(newData);
  //   }
  // };

  const getlabels = async () => {
    const res = await AxiosAll('get', `/labels`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data?.data;
      setLabels(newData);
    }
  };
  const getTeamWithUser = async () => {
    const res = await AxiosAll('get', `/common/teamsWithUsers`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setTeamWithUser(newData);
    }
  };

  const handleChange = (event, value) => {
    setSelectedOptions(value);

    // Store selected option IDs in another state
    const selectedIds = value.map((option) => option.id);
    setSelectedOptionIds(selectedIds);
  };
  useEffect(() => {
    // getProjects();
    getTask();
    // getPriority();
    getStatus();
    // getUser();
    getlabels();
    getTeamWithUser();
  }, []);

  // All user finalized on 1/12/22
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    values?.project && getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.project]);

  const handleTimeChange = (e, onChange) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
    } else {
      onChange(value);
    }
  };

  const handleSelectTeam = (id) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const handleSelectUser = (e, item) => {
    const userId = e.target.value;
    const teamId = item?.id;
    const teamIndex = selected.indexOf(teamId);

    setSelArray((prev) => {
      const newSelArray = [...prev];
      // make object of teamId and userId only if teamId is selected
      if (teamIndex !== -1) {
        newSelArray[teamIndex] = { team_id: teamId, user_id: userId };
      }
      return newSelArray;
    });
  };
  const handleSelectLevel = (e, item) => {
    const Level = e.target.value;
    const teamId = item?.id;
    const teamIndex = selected.indexOf(teamId);

    setSelLevel((prev) => {
      const newSelArray = [...prev];
      // make object of teamId and userId only if teamId is selected
      if (teamIndex !== -1) {
        newSelArray[teamIndex] = { team_id: teamId, level: Level };
      }
      return newSelArray;
    });
  };

  const handleSubTimeChange = (e, onChange, item) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
      const timeSub = `${hours}:${minutes}`;
      // eslint-disable-next-line no-unused-expressions
      handleSelectTime(timeSub, item);
    } else {
      onChange(value);
      handleSelectTime(value, item);
    }
  };
  const handleSelectTime = (timeSub, item) => {
    const sel_time = timeSub;
    const teamId = item?.id;
    const teamIndex = selected.indexOf(teamId);

    setSelTime((prev) => {
      const newSelArray = [...prev];
      // make object of teamId and userId only if teamId is selected
      if (teamIndex !== -1) {
        newSelArray[teamIndex] = { team_id: teamId, time: sel_time };
      }
      return newSelArray;
    });
  };

  const afterOrderCheck = async () => {
    try {
      const formData = new FormData();
      formData.append('project_id', values?.project);
      formData.append('title', values?.task);
      formData.append('status', values?.status);
      formData.append('description', values?.description);
      formData.append('startup_visibility', values?.visiblity);
      formData.append('start_date', moment(values.startDate).format('YYYY-MM-DD'));
      // eslint-disable-next-line no-unused-expressions
      values?.siblings === '0' && formData.append('due_date', moment(values?.dueDate).format('YYYY-MM-DD'));
      formData.append('predecessor_task_id', values?.predecessorTask);
      formData.append('following_task_id', values?.successorTask);
      // eslint-disable-next-line no-unused-expressions
      values?.siblings === '0' && formData.append('assigned_user_id', values?.assignUser);
      formData.append('priority', values?.priority ? values?.priority : 4);
      // eslint-disable-next-line no-unused-expressions
      values?.siblings === '0' && formData.append('planned_time_format', values?.plannedHour);
      formData.append('reference_url', values?.refUrl);
      formData.append('reference_title', values?.refTitle);
      formData.append('parent_task_id', values?.parentTask || 0);
      formData.append('has_siblings', values?.siblings);
      formData.append(`labels`, selectedOptionIds);
      formData.append('due_time', taskDueTime);
      // remove object if team id is not present in selected array
      const filteredPeople = selArray.filter((item) => selected.includes(item?.team_id));
      // pass object to formData
      const subTaskObj = {};
      filteredPeople.forEach((item, index) => {
        subTaskObj[`subTasks[${index}][team_id]`] = item.team_id;
        subTaskObj[`subTasks[${index}][user_id]`] = item.user_id;
      });
      Object.keys(subTaskObj).forEach((key) => {
        // eslint-disable-next-line no-unused-expressions
        values?.siblings === '1' && formData.append(key, subTaskObj[key]);
      });

      // Level selected
      const filteredLevel = selLevel.filter((item) => selected.includes(item?.team_id));
      // pass object to formData
      const subTaskLevelObj = {};
      filteredLevel?.forEach((item, index) => {
        subTaskLevelObj[`subTasks[${index}][level]`] = item.level;
      });
      Object.keys(subTaskLevelObj).forEach((key) => {
        // eslint-disable-next-line no-unused-expressions
        values?.siblings === '1' && formData.append(key, subTaskLevelObj[key]);
      });

      // Selected Time
      const filteredTime = selTime.filter((item) => selected.includes(item?.team_id));
      // pass object to formData
      const subTaskTimeObj = {};
      filteredTime.forEach((item, index) => {
        subTaskTimeObj[`subTasks[${index}][time]`] = item.time;
      });
      Object.keys(subTaskTimeObj).forEach((key) => {
        // eslint-disable-next-line no-unused-expressions
        values?.siblings === '1' && formData.append(key, subTaskTimeObj[key]);
      });

      const createStartupURL = `${newServer}/tasks`;

      const response = await axios.post(createStartupURL, formData, {
        headers: {
          contentType: 'multipart/form-data',
          Authorization: `Bearer ${userDetails.token}`,
        },
      });
      const { data } = response;
      if (data.statusCode === 201) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        if (isLead || isTester) {
          navigate('/kanban', { replace: true });
        } else {
          navigate('/tasks/status-wise', { replace: true });
        }
        setSelected([]);
        reset();
      }
      reset();
    } catch (error) {
      console.error(error);
      const { data } = error?.response;
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
    setLoading(false);
  };
  const onSubmit = async () => {
    setLoading(true);
    if (values?.siblings === '1') {
      if (!(selLevel.filter((value) => value?.level == 1).length > 0)) {
        enqueueSnackbar('Please choose sub task of level 1.', { variant: 'error' });
        setLoading(false);
      } else if (
        !(selLevel.filter((value) => value?.level == 2).length > 0) &&
        selLevel.filter((value) => value?.level == 3).length > 0
      ) {
        enqueueSnackbar('Please choose sub task of level 2.', { variant: 'error' });
        setLoading(false);
      } else if (
        !(selLevel.filter((value) => value?.level == 3).length > 0) &&
        selLevel.filter((value) => value?.level == 4).length > 0
      ) {
        enqueueSnackbar('Please choose sub task of level 3.', { variant: 'error' });
        setLoading(false);
      } else {
        afterOrderCheck();
        setLoading(false);
      }
    } else if (values?.siblings === '0' && values?.assignUser === '') {
      setLoading(false);
      enqueueSnackbar('Please assign user', { variant: 'error' });
    } else {
      afterOrderCheck();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    reset(defaultValues);
    setSelectedOptionIds(labelsIdList);
    setSelectedOptions(labelslist);
    setTaskDueTime(duetime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, taskStatus, createTask, priorityData, projectList]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Project</LabelStyle>

                <RHFSelect name="project">
                  <option value={''}>Select</option>
                  {projectList?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.title}
                    </option>
                  ))}
                </RHFSelect>
              </div>
              <div>
                <LabelStyle>Task Title</LabelStyle>
                <RHFTextField name="task" placeholder="Please describe task..." />
              </div>
              <div>
                <LabelStyle>Status</LabelStyle>
                <RHFSelect name="status">
                  <option value={''}>Select</option>
                  {taskStatus?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.name}
                    </option>
                  ))}
                </RHFSelect>
              </div>

              {/* commented as per discussion with jayesh on 11/09/23 */}
              {/* <div>
                <LabelStyle>Predecessor Tasks</LabelStyle>
                <RHFSelect name="predecessorTask">
                  <option value={''}>Select</option>
                  {allTask?.map(
                    (item, index) =>
                      task?.id !== item?.id &&
                      item?.id != values?.successorTask && (
                        <option value={item?.id} key={index}>
                          {item?.title}
                        </option>
                      )
                  )}
                </RHFSelect>
              </div>
              <div>
                <LabelStyle>Successor Tasks</LabelStyle>
                <RHFSelect name="successorTask">
                  <option value={''}>Select</option>
                  {allTask?.map(
                    (item, index) =>
                      task?.id !== item?.id &&
                      item?.id != values?.predecessorTask && (
                        <option value={item?.id} key={index}>
                          {item?.title}
                        </option>
                      )
                  )}
                </RHFSelect>
              </div> */}

              <div>
                <LabelStyle>Description</LabelStyle>
                {/* <RHFTextField name="description" label="Write Something Awesome..." multiline rows={4} /> */}
                <RHFEditor simple name="description" />
              </div>
              {userDetails?.user?.roles[0]?.id === 2 ? (
                <>
                  <div>
                    <LabelStyle>Assign User</LabelStyle>
                    <RHFSelect name="assignUser">
                      <option value={''}>Select</option>
                      <option
                        value={userDetails?.user?.id}
                      >{`${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`}</option>
                    </RHFSelect>
                  </div>{' '}
                </>
              ) : (
                <>
                  <div>
                    <LabelStyle>Has child task?</LabelStyle>
                    <RHFSelect name="siblings">
                      <option value="0">{'No'}</option>
                      <option value="1">{'Yes'}</option>
                    </RHFSelect>
                  </div>
                  {values?.siblings === '0' ? null : (
                    <Box>
                      Teams
                      {teamWithUser?.map((item, index) => (
                        <Stack key={index} flexWrap={'wrap'} sx={{ mt: 1 }}>
                          <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Checkbox
                              checked={selected?.includes(item?.id)}
                              onClick={() => handleSelectTeam(item?.id)}
                            />
                            <Typography variant="body2" sx={{ width: 200, ml: 1 }}>
                              {item?.name}
                            </Typography>
                          </Stack>
                          <Stack direction={{ xs: 'column', lg: 'row' }} gap={1}>
                            <Box width={{ xs: '100%', lg: '33.3%' }}>
                              <LabelStyle>Assign User</LabelStyle>
                              <RHFSelect name={`selectedUser${item?.id}`} onChange={(e) => handleSelectUser(e, item)}>
                                <option value={''}>{}</option>
                                <option value={''}>Unassigned</option>
                                {item?.team_users?.map((userObj, index) => (
                                  <option value={userObj?.user?.id} key={index}>
                                    {userObj?.user?.first_name} {userObj?.user?.last_name}
                                  </option>
                                ))}
                              </RHFSelect>
                            </Box>

                            <Box width={{ xs: '100%', lg: '33.3%' }}>
                              <LabelStyle>Level</LabelStyle>
                              <RHFSelect name={`selectedLevel${item?.id}`} onChange={(e) => handleSelectLevel(e, item)}>
                                <option value={''}>{}</option>
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                <option value={'4'}>4</option>
                              </RHFSelect>
                            </Box>

                            <Box width={{ xs: '100%', lg: '33.3%' }}>
                              <LabelStyle>Time</LabelStyle>
                              <Controller
                                name={`selectedTime${item?.id}`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <InputMask
                                    maskChar={null}
                                    mask="99:99"
                                    value={field.value}
                                    onChange={(e) => handleSubTimeChange(e, field.onChange, item)}
                                  >
                                    {(inputProps) => (
                                      <TextField
                                        {...inputProps}
                                        error={!!error}
                                        fullWidth
                                        helperText={error?.message}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Typography variant="body2">HH:MM</Typography>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                  </InputMask>
                                )}
                              />
                            </Box>
                          </Stack>
                          <br />
                        </Stack>
                      ))}
                    </Box>
                  )}

                  {/* We can choose 1 user at creating task. and no need to send team by gajendre sir 11/11 */}
                  {values?.siblings === '0' ? (
                    <div>
                      <LabelStyle>Assign User</LabelStyle>
                      <RHFSelect name="assignUser">
                        <option value={''}>Select</option>
                        {userList?.map((item, index) => (
                          <option value={item?.id} key={index}>{`${item?.first_name} ${item?.last_name}`}</option>
                        ))}
                      </RHFSelect>
                    </div>
                  ) : null}
                </>
              )}
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mt={2}>
                <div>
                  <LabelStyle>Priority</LabelStyle>
                  <RHFSelect name="priority">
                    <option value={''}>Select</option>
                    {priorityList?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.name}
                      </option>
                    ))}
                  </RHFSelect>
                </div>

                <div>
                  <LabelStyle>Task Label</LabelStyle>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={label}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    onChange={handleChange}
                    value={selectedOptions}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <FormControlLabel
                          control={<Checkbox checked={selected} />}
                          label={option.name}
                          style={{ width: '100%', color: option?.color }}
                        />
                      </li>
                    )}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select Options" />}
                  />
                </div>

                {values?.siblings === '0' && (
                  <div>
                    <LabelStyle>Estimate time (in hours and minutes)</LabelStyle>
                    <Controller
                      name="plannedHour"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <InputMask
                          maskChar={null}
                          mask="99:99"
                          value={field.value}
                          onChange={(e) => handleTimeChange(e, field.onChange)}
                        >
                          {(inputProps) => (
                            <TextField
                              {...inputProps}
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography variant="body2">HH:MM</Typography>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                  </div>
                )}
                <div>
                  <div>
                    <LabelStyle>Startup Visibility</LabelStyle>
                    <RHFSelect name="visiblity">
                      <option value="visible">Visible</option>
                      <option value="hidden">Hidden</option>
                    </RHFSelect>
                  </div>

                  <div>
                    <LabelStyle>Start Date</LabelStyle>
                    <RHFTextField min={new Date().toISOString().split('T')[0]} name="startDate" type="date" />
                  </div>
                  {values?.siblings === '0' && (
                    <div>
                      <LabelStyle>Due Date</LabelStyle>
                      <RHFTextField name="dueDate" type="date" min={values?.startDate} />
                    </div>
                  )}
                  <div style={{ flexDirection: 'row' }}>
                    <LabelStyle>Due Time </LabelStyle>
                    <MobilePicker time={taskDueTime} handleTime={setTaskDueTime} />
                  </div>
                </div>

                <div>
                  <LabelStyle>Reference URL</LabelStyle>
                  <RHFTextField name="refUrl" type="url" />
                </div>

                <div>
                  <LabelStyle>Reference Title</LabelStyle>
                  <RHFTextField name="refTitle" />
                </div>
              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={loading}>
              Create Task
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
