import { noCase } from 'change-case';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { AnimatePresence, m } from 'framer-motion';
// @mui
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import cssStyles from 'src/utils/cssStyles';
// utils
// utils
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Image from 'src/components/Image';
import AxiosAll from 'src/services/axiosAll';
import { fToNow } from '../../../utils/formatTime';
// _mock_
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import Scrollbar from '../../../components/Scrollbar';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------
const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflowY: 'áuto',
  // maxHeight: '200',
  width: window.innerWidth <= 400 ? '55vw' : '28vw',
  flexDirection: 'column',
  // margin: theme.spacing(0.5),
  // paddingBottom: theme.spacing(0.5),
  // zIndex: theme.zIndex.drawer + 3,
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  overflow: 'inherit',
  // height: '90vh',
  // color: theme.palette.text.primary,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));
export default function NotificationsPopover() {
  // const [notifications, setNotifications] = useState(_notifications);
  const [activityList, setActivityList] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const getActivity = async () => {
    try {
      const res = await AxiosAll(
        'get',
        `/activities?activity_view=${user?.roles[0]?.id === 2 ? `my_activities` : null}&limit=7`,
        null,
        userDetails?.token
      );
      const data = res.data.data.data;
      setActivityList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);

  // const handleMarkAllAsRead = () => {
  //   setNotifications(
  //     notifications.map((notification) => ({
  //       ...notification,
  //       isUnRead: false,
  //     }))
  //   );
  // };

  const handleViewAll = () => {
    navigate('/activities');
    setOpen(false);
  };

  return (
    <>
      {/* <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}> */}
      {/* <Badge badgeContent={5} color="error"> */}
      {/* <Iconify icon="eva:bell-fill" width={40} height={40} /> */}
      {/* </Badge> */}
      {/* </IconButtonAnimate> */}
      <Button onClick={handleOpen} fullWidth sx={{ all: 'unset', marginLeft: '6px' }} className="usefull_btn">
        Latest Activities
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 0.5, ml: { xs: 0, sm: 10 } }}
        className="notification_sidebar"
      >
        {/* <AnimatePresence>
          <RootStyle
              className=""
              sx={{
                width: '500px',
                '@media screen and (max-width: 600px)': {
                  width: '80vw',
                  height: '30vh',
                },
              }}
            > */}
        {/* <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 , }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Latest Activities</Typography> */}
        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
        {/* </Box> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, px: 2.5 }}>
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            Latest Activities
          </Typography>

          <IconButton onClick={handleClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>

        {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )} */}
        {/* </Box> */}
        {/* </RootStyle>
        </AnimatePresence> */}
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: '80vh' } }}>
          <List
          // disablePadding
          // subheader={
          //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          //     Latest 5
          //   </ListSubheader>
          // }
          >
            {activityList.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {user?.roles[0]?.id === 1 || user?.roles[0]?.id === 3 || user?.roles[0]?.id === 4 ? (
          <Box sx={{ p: 1 }}>
            <Button fullWidth onClick={handleViewAll} disableRipple>
              View All
            </Button>
          </Box>
        ) : null}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  activity: PropTypes.shape({
    activity_type: PropTypes.string,
    message: PropTypes.string,
    created_at: PropTypes.instanceOf(Date),
    activity_action_type: PropTypes.string,
    creator: PropTypes.object,
  }),
};

function NotificationItem({ notification }) {
  const { activity_type, message, creator, activity_action_type, created_at } = notification;
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        cursor: 'default',
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        {creator ? (
          <Image
            src={creator?.profile_picture}
            sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0, ml: 1 }}
          />
        ) : (
          <Image
            src={`https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg`}
            sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {`${notification?.activity_action_type} > ${notification?.activity_type}`}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification?.message)}
      </Typography>
    </Typography>
  );
  return {
    title,
  };
}
