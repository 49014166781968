// import MenuIcon from "@mui/icons-material/Menu";
import { Icon } from '@iconify/react';
import { IconButton, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function ChartMenu({ chartRef }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const open = Boolean(anchorEl);
  const { userDetails } = useSelector((state) => state.loginReducer);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const printDiv = (divRef) => {
    const printContents = divRef.current.innerHTML;
    const w = window.open();
    w.document.write(printContents);
    // Set the document title
    w.document.title = `${userDetails?.user?.first_name}_${userDetails?.user?.last_name}_Employee_Card`;
    // Wait for a short delay before printing
    setTimeout(() => {
      w.print();
      w.close();
    }, 1000); // Adjust the timeout duration as needed
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon icon="mdi:download" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            printDiv(chartRef);
            handleClose();
            setTimeout(() => {
              setDisabled(false);
            }, 3000);
          }}
        >
          Download PNG
        </MenuItem>
      </Menu>
    </div>
  );
}
